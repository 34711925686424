import { PlusCircleOutlined } from '@ant-design/icons';
import { CreateDraftViewDrawer } from '@platform/platform_components/definitions/CreateBrandHomeworkDefinitionDrawer';
import * as API from '@shared/api';
import { ActionDropdown } from '@shared/components';
import { LayoutEnums, ProductPermissionValues, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { BrandHomeworkDefinition, PaginationQuery } from '@shared/models';
import { convertDate, renderLevelsAsString, sortFields } from '@shared/utils';
import { Button, notification, Table, TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';

//TODO Sorterlar çalışmalı
export const BrandHomeworkDefinitionPage = () => {
  useAccessableSeachParams();
  const [draftDrawerCreateOpen, setDraftDrawerCreateOpen] = useState<boolean>(false);
  const [selectedHomework, setSelectedHomework] = useState<BrandHomeworkDefinition>();
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 10 });
  const [homeworks, isLoading, getHomeworks] = API.HOMEWORK.BRAND.getPaginationDefinition({ init: false });

  useEffect(() => {
    if (paginationQuery) {
      getHomeworks(undefined, paginationQuery);
    }
  }, [paginationQuery]);

  const title = (
    <div className="flex justify-between">
      <div className="font-bold"></div>
      <Button icon={<PlusCircleOutlined />} onClick={() => setDraftDrawerCreateOpen(true)} type="dashed">
        Yeni Ödev Taslağı Oluştur
      </Button>
    </div>
  );
  const columns: TableProps<BrandHomeworkDefinition>['columns'] = [
    {
      title: 'Tür',
      dataIndex: 'level',
      sorter: { multiple: 2 },
      render: (_, { level }) => renderLevelsAsString(level),
    },
    {
      title: 'Ödev Adı',
      dataIndex: 'name',
      sorter: { multiple: 1 },
    },

    {
      title: 'Dosyalar',
      key: 'files',
      render: (_, { files }) => files.length,
    },

    {
      title: 'Oluşturma Tarihi',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sorter: { multiple: 1 },
      defaultSortOrder: 'descend',
      render: (_, { createdAt }) => convertDate(createdAt, 'DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Güncelleme Tarihi',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      sorter: { multiple: 1 },
      defaultSortOrder: 'descend',
      render: (_, { updatedAt }) => convertDate(updatedAt, 'DD.MM.YYYY HH:mm'),
    },

    {
      title: 'Aksiyon',
      key: 'action',
      className: 'text-center',
      render: (_, record) => (
        <div>
          <ActionDropdown
            data={record}
            onEdit={(item) => {
              setSelectedHomework(item);
              setDraftDrawerCreateOpen(true);
            }}
            onDelete={async ({ id }) => {
              const result = await API.HOMEWORK.BRAND.deleteHomeworkDefinition(id);
              if (result.ok) {
                notification.success({ message: 'Ödev Tanımı Başarıyla Silindi' });
              }
              setPaginationQuery((prev) => ({ ...prev, page: 1 }));
              getHomeworks(undefined, paginationQuery);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <h3>Tanımlı Ödev Taslakları</h3>
      <Table
        title={() => title}
        loading={isLoading}
        rowKey="id"
        pagination={{
          showSizeChanger: true,
          showPrevNextJumpers: true,
          current: paginationQuery.page,
          total: homeworks?.count,
          pageSize: paginationQuery.limit,
          position: ['bottomCenter'],
        }}
        columns={columns}
        dataSource={homeworks?.data}
        bordered
        onChange={(pagination, filters, sorter) => {
          const { current, pageSize } = pagination;
          let newQuery = {
            ...paginationQuery,
            limit: pageSize,
            page: current,
            orders: sortFields(paginationQuery, sorter as SorterResult<any>),
            ...filters,
          };
          Object.keys(newQuery).forEach((key) => {
            if (key === 'orders') {
              return;
            }
            if (Array.isArray(newQuery[key]) && newQuery[key].length === 1) {
              newQuery[key] = newQuery[key][0];
            }
          });

          setPaginationQuery(newQuery);
        }}
      />

      <CreateDraftViewDrawer
        isOpen={draftDrawerCreateOpen}
        onClose={() => {
          setDraftDrawerCreateOpen(false);
          setSelectedHomework(undefined);
          getHomeworks(undefined, paginationQuery);
        }}
        homeworkDefinition={selectedHomework!}
        isUpdate={!!(draftDrawerCreateOpen && selectedHomework)}
      />
    </div>
  );
};

BrandHomeworkDefinitionPage.path = '/brand/homework-definition';
BrandHomeworkDefinitionPage.title = 'Ödev Taslakları';
BrandHomeworkDefinitionPage.group = 'definitions';
BrandHomeworkDefinitionPage.roles = [Role.USER];
BrandHomeworkDefinitionPage.layouts = [LayoutEnums.BRANDMANAGER];
BrandHomeworkDefinitionPage.permissions = [ProductPermissionValues.brand.LMS.Definition.VIEW];
