import { InboxOutlined } from '@ant-design/icons';
import { EnrollmentUserCard } from '@platform/platform_components/EnrollmentUserCard';
import { Enrollment, PrePlacement } from '@shared/models';
import { MenuProps } from 'antd';

type EnrollmentStudentListProp = {
  prePlacements?: PrePlacement[];
  onDelete?: (user: PrePlacement) => void;
  onEdit?: (user: PrePlacement) => void;
  onUpdateCalendar?: (user: PrePlacement) => void;
  onUpdateStudentInfo?: (student: PrePlacement) => void;
  isDelete?: boolean;
  isEdit?: boolean;
  enrollment: Enrollment;
};
export const EnrollmentStudentList = ({
  prePlacements,
  onDelete,
  onEdit,
  isDelete = true,
  isEdit = true,
  onUpdateCalendar,
  onUpdateStudentInfo,
  enrollment,
}: EnrollmentStudentListProp) => {
  return (
    <div className="border-dashed border-gray-300 rounded-md p-4">
      {prePlacements && prePlacements?.length > 0 ? (
        <div className="flex flex-row w-full space-x-2 overflow-y-auto">
          {prePlacements?.map((prePlacement) => (
            <EnrollmentUserCard
              key={prePlacement.id}
              enrollment={enrollment!}
              user={prePlacement}
              isDelete={isDelete}
              isEdit={isEdit}
              onUpdateCalendar={onUpdateCalendar}
              onDelete={(user: PrePlacement) => {
                if (onDelete) {
                  onDelete(user);
                }
              }}
              onEdit={(user: PrePlacement) => {
                if (onEdit) {
                  onEdit(user);
                }
              }}
              isSmall={true}
              className="min-w-80 p-1"
              onUpdateStudentInfo={onUpdateStudentInfo}
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-row">
          <InboxOutlined className="text-3xl text-gray-400" />
          <div className="ml-4">
            <span className="text-lg font-normal text-gray-400">Henüz öğrenci eklemediniz.</span>
          </div>
        </div>
      )}
    </div>
  );
};
