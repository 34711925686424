import {
  ArrowUpOutlined,
  CloseCircleOutlined,
  CommentOutlined,
  DeleteOutlined,
  FileUnknownOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  PaperClipOutlined,
  RiseOutlined,
  SendOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import * as API from '@shared/api';
import { AvatarFromName, Spinner } from '@shared/components';
import { Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import i18n from '@shared/localization/i18n';
import { AccountIssueCategory, AccountIssueProps, AccountIssueStatus } from '@shared/models/account_issue';
import { convertDate } from '@shared/utils';
import { Badge, Button, Card, Popconfirm, Tag } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const AccountIssueDetail = () => {
  useAccessableSeachParams();
  const [searchParams] = useSearchParams();
  const [issueId, setIssueId] = useState<string>();
  const [issue, setIssue] = useState<AccountIssueProps>();
  const [loading, setLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (searchParams.size > 0) {
      const id = searchParams.get('issue_id');
      if (id) {
        setIssueId(id);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (issueId) {
      getRetriveIssue();
    }
  }, [issueId]);

  const getRetriveIssue = async () => {
    if (issueId) {
      setLoading(true);
      const response = await API.ACCOUNT_ISSUE.retrive(issueId);
      if (response.ok) {
        setIssue(response.data);
      }
      setLoading(false);
    }
  };

  const createComment = useCallback(async () => {
    if (!comment || !issueId) {
      return;
    }

    await API.ACCOUNT_ISSUE.createComment(issueId, comment);
    setComment(undefined);
    getRetriveIssue();
  }, [comment, issueId]);

  const removeComment = useCallback(
    async (commentId: string) => {
      if (!issueId) {
        return;
      }

      await API.ACCOUNT_ISSUE.removeComment(issueId, commentId);
      getRetriveIssue();
    },
    [issueId]
  );

  const statusMap = {
    [AccountIssueStatus.OPEN]: (
      <div className="">
        <Badge color="orange" className="mr-2" /> {i18n.t('app.accountIssueStatus.' + AccountIssueStatus.OPEN)}
      </div>
    ),
    [AccountIssueStatus.IN_PROGRESS]: (
      <div className="">
        <RiseOutlined color="blue" className="mr-2" /> {i18n.t('app.accountIssueStatus.' + AccountIssueStatus.IN_PROGRESS)}
      </div>
    ),
    [AccountIssueStatus.RESOLVED]: (
      <div className="">
        <ArrowUpOutlined color="green" className="mr-2" /> {i18n.t('app.accountIssueStatus.' + AccountIssueStatus.RESOLVED)}
      </div>
    ),
    [AccountIssueStatus.NOT_RESOLVED]: (
      <div className="">
        <FileUnknownOutlined color="yellow" className="mr-2" /> {i18n.t('app.accountIssueStatus.' + AccountIssueStatus.NOT_RESOLVED)}
      </div>
    ),
    [AccountIssueStatus.REJECTED]: (
      <div className="">
        <LineChartOutlined color="red" className="mr-2" /> {i18n.t('app.accountIssueStatus.' + AccountIssueStatus.REJECTED)}
      </div>
    ),
  };

  const categoryMap = {
    [AccountIssueCategory.BUG]: (
      <Tag color="red">
        <CloseCircleOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.BUG)}
      </Tag>
    ),
    [AccountIssueCategory.FEATURE_REQUEST]: (
      <Tag color="pink">
        <RiseOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.FEATURE_REQUEST)}
      </Tag>
    ),
    [AccountIssueCategory.IMPROVEMENT]: (
      <Tag color="green">
        <ArrowUpOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.IMPROVEMENT)}
      </Tag>
    ),
    [AccountIssueCategory.OTHER]: (
      <Tag>
        <FileUnknownOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.OTHER)}
      </Tag>
    ),
    [AccountIssueCategory.PERFORMANCE]: (
      <Tag color="blue">
        <LineChartOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.PERFORMANCE)}
      </Tag>
    ),
  };

  return (
    <>
      {!loading ? (
        <Card>
          <div className="grid grid-cols-12">
            <div className="col-span-9 space-y-5">
              <div>
                <InfoCircleOutlined className="mr-1" /> Talep bilgileri
              </div>

              <div className=" mt-10">
                <span className="font-bold text-lg">#{issue?.no}</span>
                <div>
                  {issue?.title}
                  <div className="opacity-45 italic mt-2">{issue?.description}</div>
                </div>
              </div>

              <div className="">
                <PaperClipOutlined className="mr-1 mt-3" />
                Eklenen Dosyalar
              </div>
            </div>
            <div className="col-span-3 ml-7">
              <div>
                <InfoCircleOutlined className="mr-1" /> Talep Detayları
              </div>
              <div className="opacity-45 mt-7">Durum</div>
              <div className="mt-2">{statusMap[issue?.status!]}</div>
              <div className="opacity-45 mt-2">Talebi Oluşturan</div>
              <div className="mt-2">{issue?.account.name}</div>
              <div className="opacity-45 mt-2">Oluşturma Tarihi</div>
              <div className="mt-2">{convertDate(issue?.createdAt)}</div>
              <div className="opacity-45 mt-2">Kategori</div>
              <div className="mt-2">{categoryMap[issue?.category!]}</div>
              <div className="opacity-45 mt-2">Tip</div>
              <div className="mt-2">{i18n.t('app.accountIssueType.' + issue?.type)}</div>
            </div>
          </div>

          <div>
            <h3>
              <CommentOutlined /> Yorumlar
            </h3>
            <div className="flex flex-col">
              {issue && ![AccountIssueStatus.RESOLVED, AccountIssueStatus.REJECTED, AccountIssueStatus.NOT_RESOLVED].includes(issue.status) && (
                <div className="flex flex-row  w-full space-x-2">
                  <AvatarFromName title={issue?.account.name!} subtitle={''} />

                  <TextArea showCount maxLength={1000} autoSize={{ minRows: 2, maxRows: 5 }} value={comment} onChange={(e) => setComment(e.target.value)} />
                  <Popconfirm title="Yorum Gönderilecek" description="Emin misiniz?" onConfirm={createComment} onCancel={() => {}} okText="Evet" cancelText="İptal">
                    <Button icon={<SendOutlined />} />
                  </Popconfirm>
                </div>
              )}

              <div className="flex-col flex space-y-2">
                {issue?.comments?.map((comment) => (
                  <div className="justify-start items-center flex flex-row space-x-2" key={comment.id}>
                    <AvatarFromName title={comment.account.name!} subtitle={''} />
                    <div className="flex flex-col w-full">
                      <h4 className="space-x-2 mb-0">
                        <span>
                          {comment.account.name} {comment.account.isPlatformReply ? <Tag color="blue">Platform Admin</Tag> : ''}
                        </span>
                        <small className="opacity-50">
                          <i>{convertDate(comment.at, 'DD.MM.YYYY HH:mm')}</i>
                        </small>
                        <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => removeComment(comment.id)} okText="Evet" cancelText="İptal">
                          <DeleteOutlined className="text-red-500" />
                        </Popconfirm>
                      </h4>

                      <pre className="m-0">{comment.comment}</pre>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Spinner loading={true} />
      )}
    </>
  );
};

AccountIssueDetail.path = '/lms/issue-detail';
AccountIssueDetail.title = 'Ayarlar';
AccountIssueDetail.group = '';
AccountIssueDetail.roles = [Role.ADMIN];
AccountIssueDetail.icon = <SettingOutlined size={20} />;
