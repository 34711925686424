import * as API from '@shared/api';
import { EducationLevelsView } from '@shared/components';
import { LayoutEnums, ProductPermissionValues, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { EducationLevel, PaginationQuery } from '@shared/models';
import { Button, Drawer, Form, Input, InputNumber, Select, Switch, notification } from 'antd';
import { useEffect, useState } from 'react';
import { TbFilterX } from 'react-icons/tb';

export const BrandEducationLevels = () => {
  useAccessableSeachParams();
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 30, orders: ['+theme.id', '+order'] });
  const [result, loading, getLevels] = API.EDUCATION_LEVEL.BRAND.useFetchLevel({ init: false, initParams: { active: true, limit: 1000 } });
  const [theme, themeLoading, getThemes] = API.EDUCATION_LEVEL.BRAND.useFetchTheme({ init: true });
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState<EducationLevel>();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  useEffect(() => {
    if (paginationQuery) {
      getLevels(undefined, paginationQuery);
    }
  }, [paginationQuery]);

  useEffect(() => {
    if (selectedLevel) {
      setIsDrawerOpen(true);
    } else {
      setIsDrawerOpen(false);
    }
  }, [selectedLevel]);

  useEffect(() => {
    if (theme?.data) {
      const filter = theme.data.map((t) => {
        return { label: t.name!, value: t.id! };
      });
      setFilterOptions(filter as never[]);
    }
  }, [theme]);

  const title = (
    <div className="flex justify-between">
      <div className="flex flex-row content-center items-center space-x-2">
        <span className="font-bold">Kurlar</span>
        <Input
          placeholder={'Seviye Adı'}
          value={paginationQuery ? paginationQuery['name'] : undefined}
          onChange={(e) => {
            const name = e.target.value;
            if (name) {
              setPaginationQuery({
                ...paginationQuery,
                page: 1,
                name,
                orders: ['+theme.id', '+order'],
              });
            } else {
              setPaginationQuery({
                ...paginationQuery,
                page: 1,
                name: undefined,
                orders: ['+theme.id', '+order'],
              });
            }
          }}
        />
        <div className="flex flex-row space-x-2">
          <Select
            placeholder={'Türe göre filtrele'}
            value={paginationQuery ? paginationQuery['theme'] : undefined}
            style={{ width: '100%' }}
            options={filterOptions}
            onChange={(e) => {
              setPaginationQuery({
                ...paginationQuery,
                page: 1,
                theme: e,
                orders: ['+theme.id', '+order'],
              });
            }}
          />
          {paginationQuery['name'] || paginationQuery['theme'] ? (
            <div className="flex items-center">
              <Button type="text" size="small" icon={<TbFilterX />} onClick={() => setPaginationQuery((prev) => ({ limit: prev.limit, page: 1, orders: ['+theme.id', '+order'] }))}>
                Filtreyi Kaldır
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );

  const onChangeActiveStatus = async (id: string, active: boolean) => {
    try {
      await API.EDUCATION_LEVEL.BRAND.updateLevel({ id, active });
      notification.success({ message: 'İşlem başarıyla gerçekleşti' });
    } catch (error) {
    } finally {
      setPaginationQuery({
        ...paginationQuery,
        orders: ['+theme.id', '+order'],
      });
    }
  };

  const onUpdateSelectedLevel = async () => {
    try {
      await API.EDUCATION_LEVEL.BRAND.updateLevel({ ...selectedLevel });
      notification.success({ message: 'İşlem başarıyla gerçekleşti' });
    } catch (error) {
    } finally {
      setPaginationQuery({
        ...paginationQuery,
        orders: ['+theme.id', '+order'],
      });
      setSelectedLevel(undefined);
    }
  };

  const onCloseDrawer = () => {
    setSelectedLevel(undefined);
    setIsDrawerOpen(false);
  };

  return (
    <div>
      <Drawer
        width={450}
        title={selectedLevel?.theme?.type?.field?.name + ' / ' + selectedLevel?.theme?.type?.name + ' / ' + selectedLevel?.theme?.name + ' / ' + selectedLevel?.name}
        open={isDrawerOpen}
        onClose={onCloseDrawer}
        closable={true}
      >
        <Form layout="vertical">
          {/* 5-0 disabled */}
          <Form.Item label={'Kur Adı:'}>
            <Input
              disabled={true}
              value={selectedLevel?.name}
              onChange={(e) => {
                setSelectedLevel({
                  ...selectedLevel,
                  name: e.target.value,
                });
              }}
            />
          </Form.Item>
          <Form.Item label={'Toplam Ders Süresi :'}>
            <InputNumber
              min={1}
              className="w-1/3"
              addonAfter="dakika"
              value={selectedLevel?.durationInMinutes}
              onChange={(e) => {
                setSelectedLevel({
                  ...selectedLevel,
                  durationInMinutes: e || ('' as any),
                });
              }}
            />
          </Form.Item>
          <span className="text-[#1777FF]">
            İlgili kurs için hazırlanmış müfredatın toplam süresi, yalnızca ders süreleri dikkate alınarak hesaplanmalıdır. Seanslar sırasında verilen teneffüs süreleri bu hesaba
            dahil edilmemelidir.
          </span>
          <Form.Item className="mt-2" label={'Aktif/Pasif'}>
            <Switch
              value={selectedLevel?.active}
              onChange={(e) => {
                setSelectedLevel({
                  ...selectedLevel,
                  active: e,
                });
              }}
            />
          </Form.Item>
          <Button type="primary" onClick={onUpdateSelectedLevel}>
            Güncelle
          </Button>
        </Form>
      </Drawer>
      <EducationLevelsView
        levels={result ?? {}}
        pageSize={result?.limit}
        isLoading={loading}
        title={title}
        isActiveView={true}
        onChangeActiveStatus={onChangeActiveStatus}
        onEdit={(e) => {
          setSelectedLevel(e);
        }}
        onChangePagination={(page, pageSize) => setPaginationQuery({ ...paginationQuery, page: page, limit: pageSize, orders: ['+theme.id', '+order'] })}
      />
    </div>
  );
};

BrandEducationLevels.path = '/brand/education-levels';
BrandEducationLevels.title = 'Kur Tanımları';
BrandEducationLevels.group = 'definitions';
BrandEducationLevels.roles = [Role.USER];
BrandEducationLevels.layouts = [LayoutEnums.BRANDMANAGER];
BrandEducationLevels.permissions = [ProductPermissionValues.brand.LMS.Definition.VIEW];
