import { CreateBranchProps, CreateBranchUsers } from '@platform/platform_components';
import * as API from '@shared/api';
import { Spinner } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableContext } from '@shared/contexts';
import { useAccessableSeachParams } from '@shared/hooks';
import { BranchUserModel, CreateBranchModel, PaginationQuery } from '@shared/models';
import { isObjectDiff } from '@shared/utils';
import { Button, Steps, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const CreateBranch = () => {
  useAccessableSeachParams();
  const [searchParams] = useSearchParams();
  const [current, setCurrent] = useState(0);
  const { brand } = useAccessableContext();
  const [createBranch, setCreateBranch] = useState<CreateBranchModel>({ active: true });
  const [enableProductTypes, setEnableProductTypes] = useState<string[]>([]);
  const [branchUsers, usersLoading, getBranchUsers] = API.BRANCH.getBranchUsersWithBranchId({ init: false });
  const [brandProductResult, brandProductLoading, getBrandProduct] = API.PRODUCT.getEnableBrandProduct({ init: false });
  const [isEditMod, setIsEditMod] = useState<boolean>(false);
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 4 });
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.size > 0) {
      const id = searchParams.get('branch');
      if (id) {
        switchToUpdateMod(id);
      }
    }
  }, [searchParams]);

  const switchToUpdateMod = (id: string) => {
    setIsEditMod(true);
    getBranch(id);
  };

  const getBranch = async (id: string) => {
    try {
      const branch = await API.BRANCH.getBranchById(id);
      setCreateBranch(branch.data);
      getUsers(id);
    } catch (error) {}
  };

  useEffect(() => {
    if (brandProductResult) {
      const productTypes = brandProductResult.map((p) => {
        return p.type;
      }) as string[];
      setEnableProductTypes(productTypes);
    }
  }, [brandProductResult, branchUsers]);

  const next = async () => {
    /*if(current == 1){
            if(branchUsers?.data && branchUsers.data.length>0){
                setCurrent(current+1);
            }else{
                notification.warning({message:"Lütfen en az 1 şube yöneticisi ekleyiniz"})
            }
        }else{
            setCurrent(current + 1);
        }*/
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const clearAllStateAndMoveFirstStep = () => {
    if (isEditMod) {
      notification.success({ message: 'Şube güncellendi' });
    } else {
      notification.success({ message: 'Şube oluşturuldu' });
    }
    location.replace('/brand_manager/branchs/' + location.search);
  };

  const getUsers = async (id: string) => {
    getBranchUsers(undefined, { branchId: id, ...paginationQuery });
  };

  const onCreateBranchProps = async (branchProps: CreateBranchModel) => {
    if (isEditMod) {
      if (isObjectDiff(branchProps, createBranch)) {
        //TODO backend enpoint kontrol edilmeli
        const result = await API.BRANCH.update({ ...branchProps, brandId: brand?.id, active: true });
        if (result.ok) {
          setCreateBranch({
            ...branchProps,
          });
          getBrandProduct();
          getUsers(branchProps.id!);
          notification.success({ message: 'Şube güncellendi' });
          next();
        }
      } else {
        getBrandProduct();
        getUsers(createBranch.id!);
        next();
      }
    } else {
      const result = await API.BRANCH.create({ ...branchProps, brandId: brand?.id, active: true });
      if (result.ok) {
        setCreateBranch(result.data);
        getBrandProduct();
        getUsers(result.data.id);
        notification.success({ message: 'Şube Oluşturuldu' });
        next();
      }
    }
  };

  const addBranchUser = async (branchUser: BranchUserModel) => {
    if (createBranch) {
      if (branchUser.id) {
        await API.BRANCH.updateBrandBranchUser({ ...branchUser, branchId: createBranch.id });
      } else {
        await API.BRANCH.createBrandBranchUser({ ...branchUser, branchId: createBranch.id });
      }
      getUsers(createBranch.id!);
    }
  };
  const onDeleteUser = async (id: string) => {
    await API.BRANCH.deleteBrandBranchUser(createBranch.id!, id);
    getUsers(createBranch.id!);
  };

  const steps = [
    {
      title: 'Şube Oluştur',
      content: <CreateBranchProps branch={createBranch} onSave={onCreateBranchProps} />,
      description: '',
    },
    {
      title: 'Şube Yöneticisi Ekle',
      content: (
        <>
          <Spinner loading={usersLoading} />
          <CreateBranchUsers
            branchUsers={branchUsers}
            enableProductTypes={enableProductTypes}
            addBranchUser={addBranchUser}
            onDelete={onDeleteUser}
            onPaginationChange={(page) => getBranchUsers(undefined, { ...paginationQuery, page, branchId: createBranch.id })}
          />
        </>
      ),

      description: '',
    },
  ];
  const items = steps.map((item) => ({ key: item.title, title: item.title, description: item.description }));
  return (
    <div>
      <Steps current={current} items={items} />
      <div style={{ marginTop: 16, padding: 20 }}>{steps[current].content}</div>
      {current != 0 ? (
        <div className="flex justify-end" style={{ marginTop: 15 }}>
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Sonraki adım
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              type="primary"
              onClick={async () => {
                clearAllStateAndMoveFirstStep();
              }}
            >
              Tamamla
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Geri Dön
            </Button>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

CreateBranch.path = '/brand_manager/create_branch';
CreateBranch.title = 'Şube Oluştur';
CreateBranch.group = 'brand_manager_branch';
CreateBranch.roles = [Role.ADMIN];
CreateBranch.layouts = [LayoutEnums.BRANDMANAGER];
