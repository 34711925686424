import { BrandUsers } from '@shared/components/BrandUsers';
import { LayoutEnums, ProductPermissionValues, Role } from '@shared/constants';
import * as API from '@shared/api';
import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { PaginationQuery } from '@shared/models';
import { useSearchParams } from 'react-router-dom';
import { FiUsers } from 'react-icons/fi';

export const BrandUser = () => {
  const [result, loading, getAllBrandUsers] = API.BRAND.getBrandUsers({ init: false });
  const [enableProductTypes, setEnableProductTypes] = useState<string[]>([]);
  const [enableBrandProduct, brandProductLoading, getEnableBrandProduct] = API.PRODUCT.getEnableBrandProduct({ init: true });
  const [searchParams] = useSearchParams();
  const brandId = searchParams.get('brandId');
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 10, brandId: brandId! });

  useEffect(() => {
    if (brandId) {
      getAllBrandUsers(undefined, paginationQuery);
    }
  }, [paginationQuery, brandId]);

  useEffect(() => {
    if (enableBrandProduct) {
      const list = enableBrandProduct!.map((product) => {
        return product.type;
      }) as string[];
      setEnableProductTypes(list);
    }
  }, [enableBrandProduct]);

  const onSave = async (data, isUpdateMode) => {
    if (isUpdateMode) {
      const result = await API.BRAND.updateBrandUser(data);
      if (result.ok) {
        notification.success({ message: 'Marka kullanıcısı güncellendi.' });
      }
    } else {
      const result = await API.BRAND.createBrandUser(data);
      if (result.ok) {
        notification.success({ message: 'Marka kullanıcısı güncellendi.' });
      }
    }
    getAllBrandUsers(undefined, paginationQuery);
    getEnableBrandProduct();
  };

  const onDelete = async (id) => {
    await API.BRAND.deleteBrandUser(id);
    notification.success({ message: 'Marka kullanıcısı başarıyla silinmiştir!' });
    await getAllBrandUsers(undefined, { ...paginationQuery, page: 1 });
  };

  const onChangePage = (currentPage: number, pageSize: number) => {
    setPaginationQuery((prev) => ({ ...prev, page: currentPage, limit: pageSize }));
  };

  return (
    <div>
      <BrandUsers
        paginationQuery={paginationQuery}
        onChangePage={onChangePage}
        users={result}
        enableProductTypes={enableProductTypes}
        onSave={onSave}
        onDelete={onDelete}
        loading={loading}
        onSearch={(e) => setPaginationQuery({ ...paginationQuery, page: 1, limit: 10, name: e })}
      />
    </div>
  );
};

BrandUser.path = '/brand_manager/brand_user';
BrandUser.title = 'Marka Kullanıcıları';
BrandUser.group = 'flat';
BrandUser.roles = [Role.USER];
BrandUser.layouts = [LayoutEnums.BRANDMANAGER];
BrandUser.icon = <FiUsers />;
BrandUser.permissions = [ProductPermissionValues.brand.User.VIEW];
