import { SettingOutlined, UserOutlined } from '@ant-design/icons';
import { ProfileSetting } from '@shared/components/ProfileSetting';
import { Role } from '@shared/constants';
import { notification, TabsProps } from 'antd';
import { Card, Tabs } from 'antd';
import * as API from '@shared/api';
import { useUserContext } from '@shared/contexts';

export const StudentSetting = () => {
  const [profile, isProfileLoading, getProfile] = API.ACCOUNT.retriveProfile({ init: true });
  const { user, setUser } = useUserContext();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Profil ayarları',
      children: <ProfileSetting profile={profile!} updateProfile={async (formValue) => updateProfile(formValue)} />,
      icon: <UserOutlined />,
    },
    // {
    //     key: '2',
    //     label: "Bildirim ayarları",
    //     children: <NotificationSetting/>,
    //     icon: <BellOutlined />
    // },
    // {
    //     key: '3',
    //     label: "Parola ayarları",
    //     children: <PasswordSetting/>,
    //     icon: <LockOutlined />
    // }
  ];
  const updateProfile = async (form) => {
    const response = await API.ACCOUNT.updateProfile(form);
    if (response.ok) {
      setUser({ ...user, name: form.name, surname: form.surname });
      notification.success({ message: 'Profil Bilgileriniz Güncellendi' });
      getProfile();
    }
  };

  return (
    <Card>
      <Tabs items={items} />
    </Card>
  );
};

StudentSetting.path = 'student/setting';
StudentSetting.title = 'Ayarlar';
StudentSetting.group = '';
StudentSetting.roles = [Role.ADMIN];
StudentSetting.icon = <SettingOutlined size={20} />;
