import { StudentHeader, StudentProfileTabs } from '@platform/platform_components';
import * as API from '@shared/api';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams, useStudentProfile } from '@shared/hooks';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const ObserverStudentProfile = () => {
  useAccessableSeachParams();
  const [demandList, setDemandList] = useState([]);
  const [demand, setDemand] = useState<Partial<{ name: string; surname: string; email: string; phone: string }>>({});
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const studentProfil = useStudentProfile(LayoutEnums.OBSERVER, activeKey, demandList);
  const [searchParams] = useSearchParams();
  const [studentId, setStudentId] = useState<string>();

  useEffect(() => {
    if (searchParams.size > 0) {
      const id = searchParams.get('student_id');
      if (!id) return;
      setStudentId(id);
    }
  }, [searchParams]);

  useEffect(() => {
    if (studentId) {
      getData(studentId);
    }
  }, [studentId]);

  const getData = async (studentId) => {
    if (!studentId) return;
    const response = await API.ENROLLMENT.getObserverStudentDemand(studentId);
    const responsePreplacement = await API.OBSERVER.getObserverStudentDemandDetail(response.data[0].id);
    setDemand({
      ...responsePreplacement.data.demand.prePlacement,
      phone: `${responsePreplacement.data.demand.prePlacement.phone?.code} ${responsePreplacement.data.demand.prePlacement.phone?.number}`,
    } as any);
    setDemandList(response.data);
  };

  return (
    <div className="space-y-4">
      <StudentHeader
        layout={LayoutEnums.OBSERVER}
        name={demand?.name ?? ''}
        email={demand?.email ?? ''}
        phone={demand?.phone ?? ''}
        surname={demand?.surname ?? ''}
        accountId={studentId}
      />

      <StudentProfileTabs {...studentProfil} requestLoading={demandList.length < 0} onChangeKey={(key) => setActiveKey(key)} activeKey={activeKey} />
    </div>
  );
};

ObserverStudentProfile.path = '/observer/student_profile';
ObserverStudentProfile.roles = [Role.ADMIN];
