import { BookOutlined, LinkOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ExerciseOrPageHandler } from '@shared/components';
import { CdnScope } from '@shared/components/curriculum/FileUpload';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { ContentTopicMeta, ContentUnitMeta, ContentVersionNavigation, CourseTopicPageModel } from '@shared/models';
import { renderLevelsAsString } from '@shared/utils';
import { Button, Card, Divider, Form, notification, Pagination, Select, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';

export const TeacherContents = () => {
  useAccessableSeachParams();
  const [teacherContents, teacherContentsLoading, getTeacherContents] = API.TEACHER.getTeacherContentVersions({ init: true }); //Öğretmenin verdiği kur müfredatlarının listesi
  const [selectedContentId, setSelectedContentId] = useState<string>();
  const [contentData, setContentData] = useState<ContentVersionNavigation>();
  const [contentDataLoading, setContentDataLoading] = useState<boolean>(false);

  //Kur seçildikten sonra dolacak bilgiler
  const [selectedUnit, setSelectedUnit] = useState<ContentUnitMeta>();
  const [selectedTopic, setSelectedTopic] = useState<ContentTopicMeta>();
  const [selectedPage, setSelectedPage] = useState<CourseTopicPageModel>();
  const [isLoadingNextPage, setIsLoadingNextPage] = useState<boolean>(false);
  const [exercisePage, setExercisePage] = useState<number>(0);

  useEffect(() => {
    if (contentData) {
      const {
        page,
        meta: { unit, topic },
      } = contentData;
      setSelectedPage(page);
      setSelectedUnit(unit);
      setSelectedTopic(topic);
    }
  }, [contentData]);

  const onGetContentData = async () => {
    setContentDataLoading(true);
    const result = await API.CLASS.getClassBranchContentVersion(selectedContentId);
    if (result.ok) {
      setContentData(result.data);
    }
    setContentDataLoading(false);
  };

  const onChangeGoToPage = useCallback(
    (page) => {
      if (selectedUnit && selectedTopic) {
        gotoPage(selectedUnit, selectedTopic, page);
      }
    },
    [selectedUnit, selectedTopic]
  );

  const gotoPage = useCallback(
    async (unit: ContentUnitMeta, topic: ContentTopicMeta, pageIndex = -1) => {
      if (selectedContentId) {
        try {
          setIsLoadingNextPage(true);
          setExercisePage(0);
          if (pageIndex == -1 && contentData) {
            const { tree } = contentData;
            const pageId = tree
              .find((t) => {
                return t.id == unit.id;
              })
              ?.topics.find((t) => {
                return t.id == topic.id;
              })?.pages[0].id;
            if (pageId) {
              const { data, ok } = await API.CLASS.goToClassContentVersionPage(selectedContentId, pageId);
              if (ok) {
                setContentData(data);
              }
              setIsLoadingNextPage(false);
            }
          } else {
            const p = contentData?.tree?.flatMap((t) => t.topics.flatMap((p) => p.pages.flatMap((x) => x.id)));

            const { data, ok } = await API.CLASS.goToClassContentVersionPage(selectedContentId, p?.[pageIndex - 1]!);
            if (ok) {
              setContentData(data);
            }
            setIsLoadingNextPage(false);
          }
        } catch (error) {
          notification.error({ message: 'Hata!', description: 'Sayfa bulunamadı!' });
          setIsLoadingNextPage(false);
        }
      } else {
        setIsLoadingNextPage(false);
      }
    },
    [selectedContentId, selectedUnit, selectedTopic, contentData]
  );

  //Henüz Müfredat bilgisi seçilmediyse Select inputu ile seçmesi istediği istediği kurun müfredatını soruyoruz
  return contentData == undefined ? (
    <div className="flex w-full justify-center">
      <Form layout="vertical">
        <Form.Item label="Ön izlemek istediğiniz ders içeriğini seçiniz">
          <Select
            style={{ minWidth: '360px' }}
            size="large"
            value={selectedContentId}
            placeholder={'Lütfen bir seçim yapınız'}
            loading={teacherContentsLoading}
            className="w-full"
            onChange={(value) => {
              setSelectedContentId(value);
            }}
            options={teacherContents?.map((content) => {
              return { value: content.id, label: renderLevelsAsString(content.level) };
            })}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={contentDataLoading} block type="primary" disabled={!selectedContentId} onClick={() => onGetContentData()}>
            Ders İçeriğini Görüntüle
          </Button>
        </Form.Item>
      </Form>
    </div>
  ) : (
    <div className="mt-1 grid grid-cols-12 gap-4 h-[calc(100vh-105px)]">
      <div className="col-span-3 space-y-2 h-full flex flex-col">
        <Button
          size="large"
          block
          type="default"
          className="w-full whitespace-normal h-auto"
          onClick={() => {
            setContentData(undefined);
            setSelectedContentId(undefined);
          }}
        >
          Kur Listesine Geri Dön
        </Button>
        <Card styles={{ body: { padding: '0' } }} className="w-full h-full p-2 flex-grow">
          <div className="flex flex-col gap-2">
            <div className="flex gap-3">
              <LinkOutlined />
              <div className="opacity-45">Konuyla ilişkili ünite ve egzersizler</div>
            </div>
            <div className="overflow-auto scrollbar-hide h-[calc(100vh-200px)]">
              {contentData?.tree?.map((unit, unitIndex) => (
                <Card key={unitIndex} styles={{ body: { padding: '0' } }} className="p-2 mb-2 mt-2">
                  <div>
                    <div style={selectedUnit && selectedUnit.id == unit.id ? { backgroundColor: '#E6F4FF' } : {}} className="flex items-center justify-between rounded-md p-1">
                      <span style={selectedUnit && selectedUnit.id == unit.id ? { color: '#1677ff' } : {}}>
                        Ünite {unitIndex + 1}: {unit.name}{' '}
                      </span>
                    </div>

                    <Divider className="m-1" orientation="left"></Divider>
                    {unit.topics.map((topic, topicIndex) => (
                      <Card
                        onClick={() => gotoPage(unit, topic)}
                        style={selectedTopic && selectedTopic.id == topic.id ? { backgroundColor: '#E6F4FF' } : {}}
                        key={topicIndex}
                        styles={{ body: { padding: '0' } }}
                        className="pl-4 py-2 mt-3 cursor-pointer"
                      >
                        <span style={selectedTopic && selectedTopic.id == topic.id ? { color: '#1677ff' } : {}}>
                          Topic {topicIndex + 1}: {topic.name}
                        </span>
                      </Card>
                    ))}
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </Card>
      </div>
      <div className="col-span-9 h-full bg-white rounded-xl flex-grow overflow-hidden">
        <div className=" w-full h-[90%] p-2">
          {isLoadingNextPage || selectedPage == undefined ? (
            <div className="flex flex-col justify-center items-center h-full w-full">
              <Spin size="large" />
            </div>
          ) : (
            <ExerciseOrPageHandler
              exercisePage={exercisePage}
              onChangeExercisePage={setExercisePage}
              cdnScope={CdnScope.BRANCH}
              page={selectedPage}
              isInClass={true}
              isPreview={false}
              onNext={() => {
                //TODO : bunları doldur
              }}
              onPrev={() => {
                //TODO : bunları doldur
              }}
            />
          )}
        </div>

        <div className="flex items-center justify-center mt-1 h-[10%]">
          <Pagination
            showSizeChanger={false}
            current={contentData?.meta.current}
            pageSize={1}
            total={contentData?.meta.total}
            onChange={(page) => onChangeGoToPage(page)}
            className="mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

TeacherContents.path = '/' + LayoutEnums.TEACHER + '/contents';
TeacherContents.title = 'Ders İçerikleri';
TeacherContents.group = 'flat';
TeacherContents.roles = [Role.ADMIN];
TeacherContents.layouts = [LayoutEnums.TEACHER];
TeacherContents.icon = <BookOutlined size={20} />;
