import { DashboardOutlined } from '@ant-design/icons';
import { ComingSoon } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams, useStudentProfile } from '@shared/hooks';
import * as API from '@shared/api';
import { useUserContext } from '@shared/contexts';
import { StudentHeader, StudentProfileTabs } from '@platform/platform_components';
import { PiStudent } from 'react-icons/pi';
import { useState } from 'react';

export const StudentDashboard = () => {
  useAccessableSeachParams();
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);

  const { user } = useUserContext();
  const [demandList, demandListLoading, getDemandList] = API.STUDENT.getStudentDemands({ init: true });
  const studentProfil = useStudentProfile(LayoutEnums.STUDENT, activeKey, demandList);
  return (
    <div className="py-2 space-y-5">
      <StudentHeader {...studentProfil} layout={LayoutEnums.STUDENT} name={user.name!} surname={user.surname!} email={user.email!} />
      <StudentProfileTabs {...studentProfil} requestLoading={demandListLoading} activeKey={activeKey} onChangeKey={(key) => setActiveKey(key)} />
    </div>
  );
};

StudentDashboard.path = '/' + LayoutEnums.STUDENT + '/dashboard';
StudentDashboard.title = 'Öğrenim Hayatım';
StudentDashboard.group = 'flat';
StudentDashboard.roles = [Role.ADMIN];
StudentDashboard.layouts = [LayoutEnums.STUDENT];
StudentDashboard.icon = <PiStudent size={20} />;
