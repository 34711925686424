import { LayoutEnums, ProductPermissionValues, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { AvailabilityCalendar, Enrollment, EnrollmentStatus, EnrollmentTypes, Observer, PaginationQuery, PrePlacement } from '@shared/models';
import { urlCleaner } from '@shared/utils';
import { Button, Drawer, Modal, notification, Pagination, Popconfirm, Tooltip } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as API from '@shared/api';
import { EnrollmentObserverList } from '@platform/platform_components/crm/create_enrollment_request/EnrollmentObserverList';
import { InboxOutlined, InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { EnrollmentStudentList } from '@platform/platform_components/crm/create_enrollment_request/EnrollmentStudentList';
import { CreateEnrollmentObserverForm } from '@platform/platform_components/crm/create_enrollment_request/CreateEnrollmentObserverForm';
import { CreateEnrollmentStudentForm } from '@platform/platform_components/crm/create_enrollment_request/CreateEnrollmentStudentForm';
import { CreateEnterpriseEnrollmentForm } from '@platform/platform_components/crm/create_enrollment_request/CreateEnterpriseEnrollmentForm';
import i18n from '@shared/localization/i18n';
import { AvailabilityWeekCalendar, EnrollmentWarnings } from '@shared/components';

export const CreateEnterpriseEnrollmentRequest = () => {
  useAccessableSeachParams();
  const navigate = useNavigate();
  const [searchParams, setSeachParams] = useSearchParams();

  const [isEnrollmentDrawerOpen, setIsEnrollmentDrawerOpen] = useState(false);
  const [isOpenStudentDrawer, setIsOpenStudentDrawer] = useState(false);
  const [isOpenObserverDrawer, setIsOpenObserverDrawer] = useState(false);
  const [enrollmentWarningDrawer, setEnrollmentWarningDrawer] = useState<boolean>(false);
  const [isOpenPrePlacementCalendarModal, setIsOpenPrePlacementCalendarModal] = useState(false);
  const [enrollment, setEnrollment] = useState<Enrollment>();

  const [prePlacements, prePlacementsLoading, getPrePlacements] = API.ENROLLMENT.getPrePlacements({ init: false });
  const [observers, observersLoading, getObservers] = API.ENROLLMENT.getObservers({ init: false });
  const [observersQuery, setObserversQuery] = useState<PaginationQuery>({ page: 1, limit: 10 });
  const [prePlacementsQuery, setPrePlacementsQuery] = useState<PaginationQuery>({ page: 1, limit: 10 });

  const [selectedPrePlacement, setSelectedPrePlacement] = useState<PrePlacement>();
  const [selectedObserver, setSelectedObserver] = useState<Observer>();
  const [selectedPrePlacementCalendar, setSelectedPrePlacementCalendar] = useState<Partial<{ id: string; calendar: AvailabilityCalendar }>>();

  const onCloseAllDrawers = useCallback(() => {
    setIsOpenStudentDrawer(false);
    setIsOpenObserverDrawer(false);
    setIsEnrollmentDrawerOpen(false);
    setSelectedObserver(undefined);
    setSelectedPrePlacement(undefined);
  }, [selectedPrePlacement, selectedObserver, isOpenStudentDrawer, isOpenObserverDrawer, isEnrollmentDrawerOpen]);

  useEffect(() => {
    if (searchParams.size > 0) {
      const id = searchParams.get('enrollment');
      if (id) {
        getEnrollmentById(id);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (enrollment?.id) {
      setPrePlacementsQuery({ ...prePlacementsQuery, enrollmentId: enrollment.id });
      setObserversQuery({ ...observersQuery, enrollmentId: enrollment.id });
    }
  }, [enrollment?.id]);

  const getEnrollmentById = async (id: string) => {
    try {
      const { data } = await API.ENROLLMENT.fetchById(id);
      setEnrollment(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (observersQuery.enrollmentId) {
      getObservers(undefined, observersQuery);
      getPrePlacements(undefined, prePlacementsQuery);
    }
  }, [observersQuery]);

  useEffect(() => {
    if (selectedPrePlacement && selectedPrePlacement.id) {
      setIsOpenStudentDrawer(true);
    }
  }, [selectedPrePlacement]);

  useEffect(() => {
    if (selectedObserver && selectedObserver.id) {
      setIsOpenObserverDrawer(true);
    }
  }, [selectedObserver]);

  useEffect(() => {
    if (selectedPrePlacementCalendar && Object.keys(selectedPrePlacementCalendar).length > 0) {
      setIsOpenPrePlacementCalendarModal(true);
    } else {
      setIsOpenPrePlacementCalendarModal(false);
    }
  }, [selectedPrePlacementCalendar]);

  const onUpdatePrePlacementCalendar = useCallback(async () => {
    if (selectedPrePlacementCalendar && selectedPrePlacementCalendar.id) {
      const { ok } = await API.ENROLLMENT.updatePrePlacementCalendar(selectedPrePlacementCalendar.id, selectedPrePlacementCalendar.calendar!);
      if (ok) {
        setSelectedPrePlacementCalendar({});
        getPrePlacements(undefined, prePlacementsQuery);
        notification.success({ message: 'Takvimi güncellendi' });
      } else {
        notification.error({ message: 'Takvimi güncellenemedi' });
      }
    }
  }, [selectedPrePlacementCalendar]);

  const goBack = () => {
    navigate('/crm/enrollments?' + urlCleaner(searchParams));
  };

  const onApproval = async () => {
    if (enrollment?.status != EnrollmentStatus.READY_FOR_PLANNING) {
      const { ok } = await API.ENROLLMENT.setAsReadyForPlanning(enrollment?.id!);
      if (ok) {
        goBack();
      }
    } else {
      notification.error({ message: 'Kayıt zaten onaylandı' });
    }
  };

  return (
    <>
      <Modal
        title="Öğrenci Takvimini Düzenle"
        open={isOpenPrePlacementCalendarModal}
        width={1000}
        okText="Takvimi Güncelle"
        cancelText="İptal"
        onOk={() => {
          onUpdatePrePlacementCalendar();
        }}
        onCancel={() => {
          setSelectedPrePlacementCalendar({});
        }}
        onClose={() => {
          setSelectedPrePlacementCalendar({});
        }}
      >
        <AvailabilityWeekCalendar
          value={selectedPrePlacementCalendar ? selectedPrePlacementCalendar.calendar! : {}}
          disabled={/*isPreview || !isEmailSearch*/ false}
          onChange={(availability) => {
            setSelectedPrePlacementCalendar({ ...selectedPrePlacementCalendar, calendar: availability });
          }}
        />
      </Modal>

      <Drawer
        title={'Kayıt oluştururken dikkat edilmesi gerekenler'}
        open={enrollmentWarningDrawer}
        onClose={(e) => {
          setEnrollmentWarningDrawer(false);
        }}
      >
        <EnrollmentWarnings />
      </Drawer>
      <Drawer
        title={'Kayıt bilgilerini oluştur'}
        open={isEnrollmentDrawerOpen}
        onClose={(e) => {
          onCloseAllDrawers();
        }}
      >
        <CreateEnterpriseEnrollmentForm
          enrollment={enrollment}
          onSave={async (item: Enrollment) => {
            const { ok, data } = await API.ENROLLMENT.create({ ...item, type: EnrollmentTypes.ENTERPRISE });
            if (ok) {
              setEnrollment({ ...data });
              const layout = searchParams.get('layout');
              const brandId = searchParams.get('brandId');
              const branchId = searchParams.get('branchId');
              setSeachParams({ layout: layout!, brandId: brandId!, branchId: branchId!, enrollment: data.id }, { replace: false });
              setIsEnrollmentDrawerOpen(false);
              return true;
            }
            return false;
          }}
          onUpdate={async (item: Enrollment) => {
            if (enrollment && enrollment.id) {
              const _newEnrollment = { ...item, type: EnrollmentTypes.ENTERPRISE };
              const { ok } = await API.ENROLLMENT.update(_newEnrollment);
              if (ok) {
                setIsEnrollmentDrawerOpen(false);
                setEnrollment(_newEnrollment);
                return true;
              }
              return true;
            }
            return false;
          }}
        />
      </Drawer>
      <Drawer
        title={'Öğrenci Ekle'}
        open={isOpenStudentDrawer}
        onClose={(e) => {
          onCloseAllDrawers();
        }}
        width={660}
      >
        <CreateEnrollmentStudentForm
          isDisabled={enrollment && enrollment.status != EnrollmentStatus.INFO}
          prePlacement={selectedPrePlacement}
          onUpdate={async (prePlacement: PrePlacement) => {
            if (enrollment && enrollment.id) {
              const { ok } = await API.ENROLLMENT.updatePrePlacement({ ...prePlacement, enrollmentId: enrollment?.id! });
              if (ok) {
                getPrePlacements(undefined, prePlacementsQuery);
                setIsOpenStudentDrawer(false);
                setSelectedPrePlacement(undefined);
                return true;
              }
              return ok;
            }
            return false;
          }}
          onSave={async (prePlacement: PrePlacement) => {
            if (enrollment && enrollment.id) {
              const { ok } = await API.ENROLLMENT.createPrePlacment({ ...prePlacement, enrollmentId: enrollment?.id });
              if (ok) {
                getPrePlacements(undefined, prePlacementsQuery);
                setIsOpenStudentDrawer(false);
                setSelectedPrePlacement(undefined);
                return true;
              }
              return ok;
            }
            return false;
          }}
        />
      </Drawer>

      <Drawer
        title={'Gözetmen Ekle'}
        open={isOpenObserverDrawer}
        onClose={(e) => {
          onCloseAllDrawers();
        }}
      >
        <CreateEnrollmentObserverForm
          observer={selectedObserver}
          onSave={async (observer: Observer) => {
            const { ok } = await API.ENROLLMENT.createObserver({ ...observer, enrollmentId: enrollment?.id! });
            if (ok) {
              getObservers(undefined, observersQuery);
              setIsOpenObserverDrawer(false);
              setSelectedObserver(undefined);
              return true;
            }
            return false;
          }}
          onUpdate={async (observer: Observer) => {
            const { ok } = await API.ENROLLMENT.updateObserver({ ...observer, enrollmentId: enrollment?.id! });
            if (ok) {
              getObservers(undefined, observersQuery);
              setIsOpenObserverDrawer(false);
              setSelectedObserver(undefined);
              return true;
            }
            return false;
          }}
        />
      </Drawer>
      <div className="flex flex-col space-y-2 mt-8">
        {/* TODO: Kayıt Bilgileri ekrani başlangıç */}

        <div className="flex">
          <Button type="link" icon={<InfoCircleOutlined />} onClick={() => setEnrollmentWarningDrawer(true)}>
            Kayıt oluştururken dikkat edilmesi gerekenler
          </Button>
        </div>

        <div className="w-full flex flex-col space-y-2">
          <div className="flex flex-row justify-between items-center">
            <span className="text-lg font-medium">Kayıt Bilgisi</span>
            <Button
              type="dashed"
              icon={<PlusCircleOutlined />}
              onClick={() => {
                setIsEnrollmentDrawerOpen(true);
              }}
            >
              {enrollment ? 'Şirket Bilgilerini Güncelle' : 'Şirket Bilgilerini Ekle'}
            </Button>
          </div>
          <div className="border-dashed border-gray-300 rounded-md p-4">
            {enrollment ? (
              <div className="flex flex-row w-full space-x-2 overflow-y-auto justify-between">
                <div className="flex flex-row space-x-1 items-center">
                  <span className="text-sm font-medium text-gray-400">Kayıt No:</span>
                  <span className="text-sm font-medium text-gray-400">{enrollment?.no}</span>
                </div>
                <div className="flex flex-row space-x-1 items-center">
                  <span className="text-sm font-medium text-gray-400">Kayıt Durumu:</span>
                  <span className="text-sm font-medium text-gray-400">{i18n.t('app.enrollmentStatus.' + enrollment?.status)}</span>
                </div>

                <div className="flex flex-row space-x-1 items-center">
                  <span className="text-sm font-medium text-gray-400">Şirket Adı:</span>
                  <span className="text-sm font-medium text-gray-400">{enrollment?.name}</span>
                </div>
                <div className="flex flex-row space-x-1 items-center">
                  <span className="text-sm font-medium text-gray-400">Vergi No:</span>
                  <span className="text-sm font-medium text-gray-400">{enrollment?.nationalId}</span>
                </div>
                <div className="flex flex-row space-x-1 items-center">
                  <span className="text-sm font-medium text-gray-400">Şirket Telefonu:</span>
                  <span className="text-sm font-medium text-gray-400">
                    {enrollment?.phone?.code} {enrollment?.phone?.number}
                  </span>
                </div>
                <div className="flex flex-row space-x-1 items-center">
                  <span className="text-sm font-medium text-gray-400">Şirket E-Posta:</span>
                  <span className="text-sm font-normal text-gray-400">{enrollment?.email}</span>
                </div>
              </div>
            ) : (
              <div className="flex flex-row">
                <InboxOutlined className="text-3xl text-gray-400" />
                <div className="ml-4">
                  <span className="text-lg font-normal text-gray-400">Henüz kayıt bilgilerini oluşturmadınız.</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* TODO: Kayıt Bilgileri ekrani bitiş */}
        {/* TODO: Öğrenci ekleme ekrani başlangıç */}
        <div className="w-full flex flex-col space-y-2">
          <div className="flex flex-row justify-between items-center">
            <span className="text-lg font-medium">Öğrenci Listesi</span>
            <Tooltip title={!enrollment ? 'Şirket kayıt bilgilerini oluşturmadınız.' : ''}>
              <Button
                type="dashed"
                disabled={!enrollment || (enrollment && enrollment.status != EnrollmentStatus.INFO)}
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  setIsOpenStudentDrawer(true);
                }}
              >
                Öğrenci Ekle
              </Button>
            </Tooltip>
          </div>
          <EnrollmentStudentList
            enrollment={enrollment!}
            prePlacements={prePlacementsLoading ? undefined : prePlacements?.data}
            onDelete={async (user: PrePlacement) => {
              if (user.id) {
                const { ok } = await API.ENROLLMENT.deletePrePlacement(user.id);
                if (ok) {
                  getPrePlacements(undefined, prePlacementsQuery);
                }
              }
            }}
            onEdit={async (user: PrePlacement) => {
              if (user && user.id) {
                setSelectedPrePlacement(user);
              }
            }}
            isDelete={enrollment?.status == EnrollmentStatus.READY_FOR_PLANNING || enrollment?.status == EnrollmentStatus.INFO}
            isEdit={true}
            onUpdateCalendar={
              enrollment &&
              (enrollment.status == EnrollmentStatus.INFO || enrollment.status == EnrollmentStatus.READY_FOR_PLANNING || enrollment.status == EnrollmentStatus.STARTED)
                ? (user: PrePlacement) => {
                    setSelectedPrePlacementCalendar({
                      id: user.id!,
                      calendar: user.availabilityCalendar!,
                    });
                  }
                : undefined
            }
            onUpdateStudentInfo={async (student: PrePlacement) => {
              const { ok } = await API.ENROLLMENT.updateStudentPerfonalInfo(student);
              if (ok) {
                notification.success({ message: 'Öğrenci bilgileri güncellenmiştir.' });
                getPrePlacements(undefined, prePlacementsQuery);
              }
            }}
          />
          <div className="flex w-full justify-center">
            <Pagination
              total={prePlacements?.count}
              onChange={(page) => {
                setPrePlacementsQuery({ ...prePlacementsQuery, page: page });
              }}
              pageSize={prePlacements?.limit}
              current={prePlacements?.page}
            />
          </div>
        </div>
        {/* TODO: Öğrenci ekleme ekrani bitiş */}
        {/* TODO: Veli ekleme ekrani başlangıç */}
        <div className="w-full flex flex-col space-y-2">
          <div className="flex flex-row justify-between items-center">
            <span className="text-lg font-medium">Gözetmen Listesi</span>
            <Tooltip title={!enrollment ? 'Şirket kayıt bilgilerini oluşturmadınız.' : ''}>
              <Button
                type="dashed"
                disabled={!enrollment}
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  setIsOpenObserverDrawer(true);
                }}
              >
                Gözetmen Ekle
              </Button>
            </Tooltip>
          </div>
          <EnrollmentObserverList
            observers={observers?.data}
            isEdit={true}
            isDelete={
              enrollment &&
              (enrollment.status == EnrollmentStatus.INFO || enrollment.status == EnrollmentStatus.READY_FOR_PLANNING || enrollment.status == EnrollmentStatus.STARTED)
            }
            onDelete={async (observer: Observer) => {
              if (observer.id) {
                const { ok } = await API.ENROLLMENT.deleteObserver(observer.id);
                if (ok) {
                  getObservers(undefined, observersQuery);
                }
              }
            }}
            onEdit={async (observer: Observer) => {
              setSelectedObserver(observer);
            }}
          />
          <div className="flex w-full justify-center">
            <Pagination
              total={observers?.count}
              onChange={(page) => {
                setObserversQuery({ ...observersQuery, page: page });
              }}
              pageSize={observers?.limit}
              current={observers?.page}
            />
          </div>
        </div>
        {/* TODO: Veli ekleme ekrani bitiş */}

        <div className="flex flex-row space-x-2">
          <Button
            type="dashed"
            onClick={() => {
              goBack();
            }}
          >
            Kayıt Listesine Dön
          </Button>
          {enrollment && enrollment.status == EnrollmentStatus.INFO && (
            <Popconfirm
              title={'Kayıt tamamlamak istediğinizden emin misiniz?'}
              description={'Kayıt tamamlandıktan sonra, kayıtla ilgili herhangi bir değişiklik yapılamayacaktır.'}
              onConfirm={() => {
                onApproval();
              }}
              okText="Evet"
              cancelText="İptal"
              onCancel={() => notification.error({ message: 'Eklenmedi' })}
            >
              <Tooltip title={observers?.count != null && observers?.count <= 0 ? 'Kaydı planlamaya gönderebilmek için gözetmen eklenmesi gerekmektedir.' : ''}>
                <Button disabled={observers?.count != null && observers?.count <= 0} type="primary">
                  Kaydı Planlamaya Gönder
                </Button>
              </Tooltip>
            </Popconfirm>
          )}
        </div>
      </div>
    </>
  );
};

CreateEnterpriseEnrollmentRequest.path = '/crm/create-enterprise-enrollment-request';
CreateEnterpriseEnrollmentRequest.title = 'Yeni Kurumsal Kayıt';
CreateEnterpriseEnrollmentRequest.group = 'temp-record';
CreateEnterpriseEnrollmentRequest.roles = [Role.ADMIN];
CreateEnterpriseEnrollmentRequest.layouts = [LayoutEnums.BRANCHMANAGER, LayoutEnums.CRM];
CreateEnterpriseEnrollmentRequest.permissions = [ProductPermissionValues.branch.LMS.Enrollment.CREATE];
