import { DashboardOutlined } from '@ant-design/icons';
import { ComingSoon } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';

export const ObserverDashboard = () => {
  useAccessableSeachParams();
  return <ComingSoon navigationUrl="/" navigationText="Ana ekrana dön" />;
};

ObserverDashboard.path = '/' + LayoutEnums.OBSERVER + '/dashboard';
ObserverDashboard.title = 'Kontrol Paneli';
ObserverDashboard.group = 'flat';
ObserverDashboard.roles = [Role.ADMIN];
ObserverDashboard.layouts = [LayoutEnums.OBSERVER];
ObserverDashboard.icon = <DashboardOutlined size={20} />;
