import { StudentHeader } from '@platform/platform_components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import * as API from '@shared/api';
import { useUserContext } from '@shared/contexts';
import { BuildOutlined, UserOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { useNavigate } from 'react-router-dom';

export const ObserverStudents = () => {
  useAccessableSeachParams();
  const [observerStudents, observerStudentsLoading, getObserverStudents] = API.OBSERVER.getObserverStudents({ init: true });
  const navigate = useNavigate();
  const { user } = useUserContext();

  return (
    <div className="grid grid-cols-3 gap-y-4 gap-x-10 mt-5">
      {observerStudents?.map((student, index) => (
        <Card
          className="cursor-pointer bg-white hover:bg-white hover:border-[#91CAFF] border hover:border-2 "
          onClick={() => navigate('/observer/student_profile' + location.search + '&student_id=' + student.id)}
        >
          <div key={index}>
            <div>
              {student.name} {student.surname}
            </div>
            <div className="flex justify-between">
              <div className="flex">
                {student.phone?.code} {student.phone?.number}
              </div>
              <div>
                <UserOutlined style={{ fontSize: '20px', color: '#1677FF' }} />
              </div>
            </div>
            <div>{student.email}</div>
          </div>
        </Card>
      ))}
    </div>
  );
};

ObserverStudents.path = '/' + LayoutEnums.OBSERVER + '/profile';
ObserverStudents.roles = [Role.ADMIN];
ObserverStudents.layouts = [LayoutEnums.OBSERVER];
ObserverStudents.title = 'Öğrencilerim';
ObserverStudents.group = 'flat';
ObserverStudents.icon = <BuildOutlined size={20} />;
