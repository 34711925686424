import * as API from '@shared/api';
import { TextInput } from '@shared/components';
import { CdnScope, FileUpload } from '@shared/components/curriculum/FileUpload';
import { Role, UploadFileType } from '@shared/constants';
import { useForm } from '@shared/hooks';
import { BrandHomeworkDefinition, CascaderOption, CreateBrandHomeworkDefinitionProps } from '@shared/models';
import { Button, Cascader, Drawer, notification, UploadFile } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import ReactQuill from "react-quill-new";

type CreateBrandHomeworkDefinitionDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  homeworkDefinition: BrandHomeworkDefinition;
  isUpdate: boolean;
};

const CreateDraftViewDrawerForm = z.object({
  name: z.string({ required_error: 'Ödev Başlığı zorunlu alandır' }).min(1, 'Ödev Başlığı 1 karekterden fazla olmalı'),
  description: z.string({ required_error: 'Ödev Açıklaması zorunlu alandır' }).min(1, 'Ödev Açıklaması 1 karekterden fazla olmalı'),
  levelId: z.string({ required_error: 'Kur zorunlu alandır' }),
  files: z.array(z.any()).optional(),
});
export const CreateDraftViewDrawer = ({ isOpen, onClose, homeworkDefinition, isUpdate }: CreateBrandHomeworkDefinitionDrawerProps) => {
  const [abilityLevelOptions, setAbilityLevelOptions] = useState<CascaderOption[]>([]);
  const [abilityLevelCascaderValue, setAbilityLevelCascaderValue] = useState<(string | number)[]>([]);
  const [levelId, setLevelId] = useState<string>();
  const [files, setFiles] = useState<UploadFile[]>();
  const [startUpload_, startUpload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [levels, levelsLoading, getLevel] = API.EDUCATION_LEVEL.BRAND.useFetchLevel({ init: false, initParams: { active: true, limit: 1000 } });

  const form = useForm<Partial<CreateBrandHomeworkDefinitionProps>, CreateBrandHomeworkDefinitionProps>(CreateDraftViewDrawerForm, {});

  useEffect(() => {
    if (isOpen && !isUpdate) {
      getLevel(undefined, { limit: 10000, page: 1 });
      form.setValue({});
      setFiles([]);
      setAbilityLevelCascaderValue([]);
      form.reset();
    }
  }, [isOpen, isUpdate]);

  useEffect(() => {
    if (levels?.data?.length) {
      prepareLevelForCascader();
    }
  }, [levels?.data?.length]);

  useEffect(() => {
    if (homeworkDefinition && isUpdate) {
      form.reset();
      getLevel(undefined, { limit: 10000, page: 1 });
      setFiles(homeworkDefinition.files.map((f) => ({ name: f.name!, uid: f.resourceId!, url: f.resourceUrl })));
      form.setValue({ ...homeworkDefinition,levelId:homeworkDefinition.level?.id! });
      const model: string[] = [homeworkDefinition.level?.theme?.type?.field?.id!];
      model.push(homeworkDefinition.level?.theme?.type?.id!);
      model.push(homeworkDefinition.level?.theme?.id!);
      model.push(homeworkDefinition.level?.id!);
      setAbilityLevelCascaderValue(model);

    }
  }, [homeworkDefinition, isUpdate]);

  const prepareLevelForCascader = () => {
    try {
      const _items: CascaderOption[] = [];

      levels?.data?.forEach((f) => {
        let fieldOption = _items.find((item) => item.value === f.theme?.type?.field?.id);

        if (!fieldOption) {
          fieldOption = {
            value: f.theme?.type?.field?.id!,
            label: f.theme?.type?.field?.name!,
            children: [],
          };
          _items.push(fieldOption);
        }

        let typeOption = fieldOption.children?.find((item) => item.value === f.theme?.type?.id);

        if (!typeOption) {
          typeOption = {
            value: f.theme?.type?.id!,
            label: f.theme?.type?.name!,
            children: [],
          };
          fieldOption.children?.push(typeOption);
        }

        let themeOption = typeOption.children?.find((item) => item.value === f.theme?.id);

        if (!themeOption) {
          themeOption = {
            value: f.theme?.id!,
            label: f.theme?.name!,
            children: [],
          };
          typeOption.children?.push(themeOption);
        }

        themeOption.children?.push({
          value: f.id!,
          label: f.name!,
        });
      });

      setAbilityLevelOptions(_items);
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeAbilityLevel = (value) => {
    setAbilityLevelCascaderValue(value);
    setLevelId(value[3]);
    form.setValue({ ...form.value, levelId: value[3] });
  };

  const onCreateDraftHomework = async (files) => {
    const result = form.parse();
    if (result.success) {
      if (!isUpdate) {
        const response = await API.HOMEWORK.BRAND.createHomeworkDefinition({ ...result.data, files: files, levelId: levelId! });
        if (response.ok) {
          onClose();
          notification.success({ message: 'Taslak oluşturuldu' });
        }
      } else {
        const response = await API.HOMEWORK.BRAND.updateHomeworkDefinition({ ...result.data, files: files, levelId: levelId! }, result.data.id!);
        if (response.ok) {
          onClose();
          notification.success({ message: 'Taslak Güncellendi' });
        }
      }
    } else {
      form.setAllTouched();
    }
    startUpload(false);
  };

  return (
    <Drawer
      forceRender={true}
      open={isOpen}
      width={500}
      onClose={onClose}
      closable={true}
      title={<div> {isUpdate ? <span>Taslağı düzenle</span> : <span>Yeni ödev taslağı oluştur</span>}</div>}
    >
      <div>
        <h4>Kur</h4>
        <div className="mt-1">
          <FormItem {...form.formItem('levelId')}>
            <Cascader expandTrigger="hover" multiple={false} className="w-full" options={abilityLevelOptions} value={abilityLevelCascaderValue}  onChange={onChangeAbilityLevel} />
          </FormItem>
        </div>
      </div>
      <div className="mt-4">
        <h4 className="mt-3">Ödev Başlığı</h4>
        <div className="mt-1">
          <FormItem {...form.formItem('name')}>
            <TextInput formItem={form.formItem('name')} formInput={form.input('name')} maxLength={500} />
          </FormItem>
        </div>
      </div>
      <div className="mt-4 mb-5 relative h-72">
        <h4 className="mt-3">Ödev Açıklama</h4>
        <div className="mt-1" >
          <FormItem {...form.formItem('description')}> 
            <ReactQuill
            {...form.inputTextEditor('description')}
                    
                    className="h-48 p-0 mb-3 "
                    theme="snow"
                    modules={{
                        toolbar: {
                            container: [
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                [{ 'font': [] }],
                                [{ 'align': [] }],
                                ['bold', 'italic', 'underline'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                {
                                    'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466']

                                },
                                { 'background': [] },
                                'link',
                                ],
                            ],
                        },
                    }}
                />

            {/*<TextArea {...form.input('description')} maxLength={500} autoSize={{ minRows: 2, maxRows: 5 }} style={{ resize: 'none' }} showCount />*/}
          </FormItem>
        </div>
      </div>

      <div className="mt-4">
        <div className="mb-3">Yüklemek istediğiniz dosyaları seçiniz</div>
        <div className="mb-3">
          <FileUpload
            watchThisIfTrueStartUpload={startUpload_}
            multiple={true}
            files={files}
            onChange={(files) => {
              setFiles(files);
            }}
            onChangeLoading={(b) => {
              setLoading(b);
            }}
            cdnScope={CdnScope.BRANCH}
            onUploadCompleted={(files) => {
              onCreateDraftHomework(files);
            }}
            allowedMimes={UploadFileType('*')}
          />
        </div>
        <div className="mb-3 text-xs">
          <span className="text-red-500">*</span> Desteklenen türler: Resim, Doküman, Ses, Video, Zip
        </div>
      </div>
      <Button loading={loading} className="w-full mt-5" type="primary" onClick={() => startUpload(true)}>
        {isUpdate ? <span> Ödev taslağı güncelle</span> : <span> Ödev taslağı oluştur</span>}
      </Button>
      <Button className="w-full mt-2" ghost type="primary" onClick={onClose}>
        Vazgeç
      </Button>
    </Drawer>
  );
};

CreateDraftViewDrawer.roles = [Role.ADMIN];
