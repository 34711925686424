import { ClassInfo, ClassSuccessSummary, ClassTimeline } from '@platform/platform_components';
import * as API from '@shared/api';
import { Calendar, Spinner } from '@shared/components';
import { Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { convertDate, getMonthRange, renderLevelsAsString } from '@shared/utils';
import { Card, notification } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ClassCalendarRenderer } from '../../../platform_components/calendar/CalenderRenderers';

export const ClassDetail = () => {
  useAccessableSeachParams();
  const [searchParams] = useSearchParams();
  const [classId, setClassId] = useState<string>(new URL(window.location.href).searchParams.get('class_id')!);
  const [classDetail, classDetailLoading, getClassDetail] = API.CLASS.getClassDetail(classId, { init: false });
  const [calendarData, calendarLoading, getCalendarData] = API.CLASS.getClassCalendar(classId, { init: false });



  useEffect(() => {
    if (searchParams.size > 0) {
      const id = searchParams.get('class_id');
      if(id){
        setClassId(id);
      }
    }
  }, [searchParams,searchParams.size]);

  useEffect(() => {
    if (classId) {
      getClassDetail();
    }
  }, [classId]);

  useEffect(() => {
    if (classId) {
      const { startOfMonth, endOfMonth } = getMonthRange(dayjs());
      getCalendarData(undefined, { begin: startOfMonth, end: endOfMonth });
    }
  }, [classId]);

  const classClandarRefreshForDate = (date: Dayjs) => {
    const { startOfMonth, endOfMonth } = getMonthRange(date);
    getCalendarData(undefined, { begin: startOfMonth, end: endOfMonth });
  };

  const renderClassDetail = () => {
    getClassDetail();
  };

  const classCalendar = <Calendar events={calendarData} onDateChange={classClandarRefreshForDate} cellRenderer={ClassCalendarRenderer(classId, classClandarRefreshForDate)} />;

  return (
    <>
      <Spinner loading={classDetailLoading} />
      <div className="grid grid-cols-3">
        <Card type="inner">
          <div className="flex text-[#00000073]">
            <div>
              Sınıf Adı
              <div className="text-black font-bold">{classDetail?.name}</div>
            </div>
          </div>
        </Card>
        <Card type="inner">
          <div className="flex text-[#00000073]">
            <div>
              Kur Bilgisi
              <div className="text-black font-bold">{renderLevelsAsString(classDetail?.level)}</div>
            </div>
          </div>
        </Card>
        <Card type="inner">
          <div className="flex text-[#00000073]">
            <div>
              Kur Tarihleri
              <div className="text-black font-bold">
                {convertDate(classDetail?.calculatedStartDate)} - {convertDate(classDetail?.calculatedEndDate)}
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="grid grid-cols-12 gap-2 mt-2">
        <div className="col-span-9">
          <ClassSuccessSummary classDetail={classDetail} visibleButton={false} />
          <div className="mt-3">
            <ClassTimeline notes={classDetail?.notes} isTeacher={false} classDetail={classDetail} />
          </div>
        </div>
        <div className="col-span-3 justify-center ml-3">
          <ClassInfo calendar={classCalendar} classDetail={classDetail} getClassDetail={renderClassDetail} />
        </div>
      </div>
    </>
  );
};

ClassDetail.path = '/lms/class_detail';
ClassDetail.roles = [Role.ADMIN];
