import { ArrowUpOutlined, CloseCircleOutlined, FileUnknownOutlined, LineChartOutlined, PlusCircleOutlined, RiseOutlined, SettingOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ActionDropdown, QuickReportIssue } from '@shared/components';
import { Role } from '@shared/constants';
import i18n from '@shared/localization/i18n';
import { PaginationQuery } from '@shared/models';
import { AccountIssueCategory, AccountIssueProps, AccountIssueStatus } from '@shared/models/account_issue';
import { convertDate } from '@shared/utils';
import { Badge, Button, Card, notification, Table, TableProps, Tag } from 'antd';
import { useEffect, useState } from 'react';

export const AccountIssue = () => {
  const [accountIssue, loading, getAccountIssue] = API.ACCOUNT_ISSUE.paginate({ init: true });
  const [open, setOpen] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 10, orders: ['-createdAt'] });
  const [selectedData, setSelectedData] = useState<AccountIssueProps>();

  useEffect(() => {
    getAccountIssue(undefined, paginationQuery);
  }, [paginationQuery]);

  const showDrawer = () => {
    setOpen(true);
    setIsUpdateMode(false);
  };

  const categoryMap = {
    [AccountIssueCategory.BUG]: (
      <Tag color="red">
        <CloseCircleOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.BUG)}
      </Tag>
    ),
    [AccountIssueCategory.FEATURE_REQUEST]: (
      <Tag color="pink">
        <RiseOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.FEATURE_REQUEST)}
      </Tag>
    ),
    [AccountIssueCategory.IMPROVEMENT]: (
      <Tag color="green">
        <ArrowUpOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.IMPROVEMENT)}
      </Tag>
    ),
    [AccountIssueCategory.OTHER]: (
      <Tag>
        <FileUnknownOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.OTHER)}
      </Tag>
    ),
    [AccountIssueCategory.PERFORMANCE]: (
      <Tag color="blue">
        <LineChartOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.PERFORMANCE)}
      </Tag>
    ),
  };
  const statusMap = {
    [AccountIssueStatus.OPEN]: (
      <div className="flex items-center justify-center">
        <Badge color="orange" className="mr-2" /> {i18n.t('app.accountIssueStatus.' + AccountIssueStatus.OPEN)}
      </div>
    ),
    [AccountIssueStatus.IN_PROGRESS]: (
      <div className="flex items-center justify-center">
        <RiseOutlined color="blue" className="mr-2" /> {i18n.t('app.accountIssueStatus.' + AccountIssueStatus.IN_PROGRESS)}
      </div>
    ),
    [AccountIssueStatus.RESOLVED]: (
      <div className="flex items-center justify-center">
        <ArrowUpOutlined color="green" className="mr-2" /> {i18n.t('app.accountIssueStatus.' + AccountIssueStatus.RESOLVED)}
      </div>
    ),
    [AccountIssueStatus.NOT_RESOLVED]: (
      <div className="flex items-center justify-center">
        <FileUnknownOutlined color="yellow" className="mr-2" /> {i18n.t('app.accountIssueStatus.' + AccountIssueStatus.NOT_RESOLVED)}
      </div>
    ),
    [AccountIssueStatus.REJECTED]: (
      <div className="flex items-center justify-center">
        <LineChartOutlined color="red" className="mr-2" /> {i18n.t('app.accountIssueStatus.' + AccountIssueStatus.REJECTED)}
      </div>
    ),
  };

  const columns: TableProps<AccountIssueProps>['columns'] = [
    {
      title: 'Talep No',
      dataIndex: 'no',
      align: 'center',
      sorter: { multiple: 2 },
      render: (_, { no }) => <span># {no}</span>,
    },
    {
      title: 'Kategori',
      dataIndex: 'category',
      align: 'center',
      render: (_, { category }) => categoryMap[category],
    },
    {
      title: 'Kategori',
      dataIndex: 'category',
      align: 'center',
      render: (_, { type }) => <span>{i18n.t('app.accountIssueType.' + type)}</span>,
    },
    {
      title: 'Başlık',
      dataIndex: 'title',
      align: 'center',
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      align: 'center',
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      align: 'center',
    },
    {
      title: 'Oluşturma Tarihi',
      dataIndex: 'createdAt',
      align: 'center',
      render: (_, { createdAt }) => <span>{convertDate(createdAt)}</span>,
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      align: 'center',
      render: (_, { status }) => statusMap[status],
    },
    {
      title: 'Aksiyon',
      key: 'action',
      className: 'text-center',
      render: (_, record) => (
        <div>
          <ActionDropdown
            data={record}
            onDelete={async ({ id }) => {
              const result = await API.ACCOUNT_ISSUE.deleteIssue(id);
              if (result.ok) {
                notification.success({ message: 'Kredi Talebiniz Başarıyla Kaldırıldı' });
              }
              setPaginationQuery((prev) => ({ ...prev, page: 1 }));
            }}
            onEdit={() => {
              setOpen(true);
              setIsUpdateMode(true);
              setSelectedData(record);
            }}
            isView={true}
            onView={() => {
              //TODO view
            }}
          />
        </div>
      ),
    },
  ];

  const title = (
    <div className="flex justify-between">
      <div className="font-bold"></div>
      <Button icon={<PlusCircleOutlined />} onClick={() => showDrawer()} type="dashed">
        Yeni Destek Talebi Oluştur
      </Button>
    </div>
  );

  return (
    <>
      <Card>
        <Table
          loading={loading}
          pagination={{
            showSizeChanger: true,
            showPrevNextJumpers: true,
            current: paginationQuery.page,
            total: accountIssue?.count,
            pageSize: paginationQuery.limit,
            position: ['bottomCenter'],
          }}
          onChange={(pagination, filters, sorter) => {
            const { current, pageSize } = pagination;
            let newQuery = {
              ...paginationQuery,
              page: current,
              limit: pageSize,
              ...filters,
            };
            Object.keys(newQuery).forEach((key) => {
              if (key === 'orders') {
                return;
              }
              if (Array.isArray(newQuery[key]) && newQuery[key].length === 1) {
                newQuery[key] = newQuery[key][0];
              }
            });

            setPaginationQuery(newQuery);
          }}
          rowKey="id"
          columns={columns}
          dataSource={accountIssue?.data}
          bordered
          title={() => title}
        />
      </Card>
      <QuickReportIssue
        open={open}
        isUpdateMode={isUpdateMode}
        closeDrawer={() => {
          setOpen(false);
        }}
        onReflesh={() => getAccountIssue(undefined, paginationQuery)}
        selectedData={selectedData}
      />
    </>
  );
};

AccountIssue.path = '/lms/issue';
AccountIssue.title = 'Ayarlar';
AccountIssue.group = '';
AccountIssue.roles = [Role.ADMIN];
AccountIssue.icon = <SettingOutlined size={20} />;
