import { BirthdayCalendar, EmailSearch, NationalIdInput, PhoneInput } from "@shared/components";
import { Gender } from "@shared/constants";
import { useForm } from "@shared/hooks";
import { Enrollment, Observer } from "@shared/models";
import { Button, Form, Input, Radio } from "antd";
import { useCallback, useEffect, useState } from "react";
import { z } from "zod";


const CreateObserverStepForm = z.object({
    name: z.string({ required_error: 'Adı zorunlu alandır' }).min(1, 'Adı 1 karekterden fazla olmalı'),
    surname: z.string({ required_error: 'Soyadı alanı zorunlu alandır' }),
    email: z.string({ required_error: 'E-posta zorunlu alandır' }).min(1, 'TODO Bu değişecek ama şimdilik en az 1 karakter').email('Geçersiz e-mail adresi'),
    'phone.number': z
        .string({ required_error: 'Telefon numarası zorunlu alandır' })
        .min(10, 'Lütfen telefon numarası giriniz')
        .refine((p) => {
            //TODO phone validasyon için kullanabilir
            return true;
        }),
    gender: z.string({ required_error: 'Lütfen cinsiyet seçiniz' }),
    nationalId: z.string({ required_error: 'Lütfen TC/Vergi No seçiniz' }),
    birthDate: z.string({ required_error: 'Lüften doğum günü seçiniz' }),
});

type CreateEnrollmentObserverFormProps = {
    observer?: Observer;
    onSave: (observer: Observer) => Promise<boolean>;
    onUpdate: (observer: Observer) => Promise<boolean>;
    firsObserver?: Enrollment;

}

export const CreateEnrollmentObserverForm = ({ observer,firsObserver, onSave, onUpdate }: CreateEnrollmentObserverFormProps) => {

    const [isEmailSearch, setIsEmailSearch] = useState(false);
    const [isEmailSearchFound, setIsEmailSearchFound] = useState(false);
    const [isEditMod, setIsEditMod] = useState<boolean>(false);

    useEffect(() => {
        if (observer?.id) {
            setIsEditMod(true);
            form.setInitialValue({...observer});
            setIsEmailSearchFound(true);
            setIsEmailSearch(true);
        }else{
            form.setInitialValue({});
            setIsEmailSearchFound(false);
            setIsEditMod(false);
            setIsEmailSearch(false);
        }
    }, [observer?.id]);

    useEffect(() => {
        if(firsObserver){
            form.setInitialValue({ ...firsObserver });
            setIsEmailSearch(true);
        }
    },[firsObserver]);

    const form = useForm<
        Partial<{
            id: string;
            name: string;
            surname: string;
            email: string;
            'phone.number': string;
            'phone.code': string;
            gender: string;
            nationalId: string;
            birthDate: string;
        }>,
        Observer
    >(CreateObserverStepForm, {});


    const onSearchResult = (data?: Observer) => {
        if (data) {
            form.setInitialValue({
                email: data.email,
                name: data.name,
                surname: data.surname,
                phone: data.phone,
                gender: data.gender,
                nationalId: data.nationalId,
                birthDate: data.birthDate,
            });
            setIsEmailSearchFound(true);
        } else {
            setIsEmailSearchFound(false);
            form.setInitialValue({
                ...form.value,
                name: undefined,
                surname: undefined,
                phone: {
                    code: undefined,
                    number: undefined,
                },
                gender: undefined,
                nationalId: undefined,
                birthDate: undefined,
            });
        }
        setIsEmailSearch(true);
    };

    const sendData = useCallback(async () => {
        const result = form.parse();

        if (result.success) {
            const data = result.data as Observer;
            if (data.id) {
                await onUpdate(data);
                form.reset();
                setIsEmailSearchFound(false);
                setIsEmailSearch(false);
            } else {
                await onSave(data);
                form.reset();
                setIsEmailSearchFound(false);
                setIsEmailSearch(false);
            }
        } else {
            form.setAllTouched();
        }
    }, [form]);


    return (
        <div className='flex flex-col w-full' >
            <Form layout="vertical">
                <div className=" grid grid-cols-2 gap-x-2 gap-y-1">
                    <div className="col-span-2">
                        <EmailSearch
                            form={form}
                            isSearchOtherUser={!isEditMod}
                            onSearchResult={onSearchResult}
                            onClear={() => {
                                form.setInitialValue({
                                    ...form.value,
                                    name: undefined,
                                    email: undefined,
                                    surname: undefined,
                                    phone: {
                                        code: undefined,
                                        number: undefined,
                                    },
                                    gender: undefined,
                                    nationalId: undefined,
                                    birthDate: undefined,
                                });
                                setIsEmailSearchFound(false);
                                setIsEmailSearch(false);
                            }}
                            label={<span className="truncate text-black/45 font-normal">Gözetmen E-posta</span>}
                            //disabled={isPreview || isEmailSearchFound}
                            disabled={isEmailSearchFound}
                        />
                    </div>
                    <Form.Item {...form.formItem('name')} label={<span className="truncate text-black/45 font-normal">Gözetmen Adı</span>}>
                        <Input {...form.input('name')} disabled={/*isPreview ||*/ !isEmailSearch || isEmailSearchFound} />
                    </Form.Item>
                    <Form.Item {...form.formItem('surname')} label={<span className="truncate text-black/45 font-normal">Gözetmen Soyadı</span>}>
                        <Input {...form.input('surname')} disabled={/*isPreview ||*/ !isEmailSearch || isEmailSearchFound} />
                    </Form.Item>
                    <div className="col-span-2">
                        <PhoneInput form={form} disabled={/*isPreview ||*/ !isEmailSearch || isEmailSearchFound} label={<span className="truncate text-black/45 font-normal">Gözetmen Telefon</span>}/>
                    </div>

                    <Form.Item {...form.formItem('birthDate')} label={<span className="truncate text-black/45 font-normal">Gözetmen Doğum Tarihi</span>}>
                        <BirthdayCalendar form={form} disabled={/*isPreview ||*/ !isEmailSearch || isEmailSearchFound} />
                    </Form.Item>
                    <NationalIdInput form={form} disabled={/*isPreview ||*/ !isEmailSearch || isEmailSearchFound} label={<span className="truncate text-black/45 font-normal">Gözetmen Tc Kimlik No</span>} />

                    <Form.Item {...form.formItem('gender')} label={<span className="truncate text-black/45 font-normal">Gözetmen Cinsiyet</span>}>
                        <Radio.Group {...form.input('gender')} disabled={/*isPreview ||*/ !isEmailSearch || isEmailSearchFound}>
                            <Radio value={Gender.FEMALE}>Kadın</Radio>
                            <Radio value={Gender.MALE}>Erkek</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
            </Form>

            <div className='flex flex-col space-y-2'>
                <Button type='primary' onClick={sendData}>{isEditMod ? "Kaydı Güncelle": "Kayıt Oluştur"}</Button>
                {
                    !isEditMod && <Button type='default' onClick={() => form.reset()}>Temizle</Button>
                }
                
            </div>
        </div>
    );
}