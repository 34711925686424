import * as API from '@shared/api';
import { Card, Image, Spin } from 'antd';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export const Mail = () => {
  const { emailId } = useParams();
  const location = useLocation();

  const contentStyle: React.CSSProperties = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.00)',
    borderRadius: 50,
  };
  const content = <div style={contentStyle} />;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const redirectUrl = searchParams.get('redirect');

    if (emailId) {
      markAsRead(emailId);
    }

    if (redirectUrl) {
      setTimeout(() => window.location.replace(redirectUrl), 2000);
    } else {
      setTimeout(() => window.location.replace('/'), 2000);
    }
  }, [emailId, location]);

  const markAsRead = async (emailId: string) => {
    await API.MAIL.mailMarkAsRead(emailId);
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-slate-300">
      <Card className="flex justify-center items-center h-screen" style={{ height: '650px', width: '600px' }}>
        <div className="flex justify-center items-center">
          <Image preview={false} src={require('../../../statics/americanlife.png')} />
        </div>
        <div className="flex flex-col gap-0.5 mt-8 mb-8">
          <div>
            <div className="h-64">
              <Spin tip="Bekleyiniz. Yönlendiriliyorsunuz.." size="large">
                {content}
              </Spin>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

Mail.path = 'email/:emailId';
Mail.title = 'Mail';
