import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams, useStudentProfile } from '@shared/hooks';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as API from '@shared/api';
import { StudentHeader, StudentProfileTabs } from '@platform/platform_components';

export const BranchStudentProfile = () => {
  useAccessableSeachParams();
  const [demandList, demandListLoading, getDemandList] = API.ENROLLMENT.getBranchStudentDemands({ init: false }); //Öğrencinin aldığı kursların listesi
  const [searchParams] = useSearchParams();
  const [studentId, setStudentId] = useState<string>();
  const [profileInfo, setProfileInfo] = useState<Partial<{ name: string; surname: string; email: string; phone: string }>>({});
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const studentProfil = useStudentProfile(LayoutEnums.BRANCHMANAGER, activeKey, demandList);

  useEffect(() => {
    if (demandList) {
      setProfileInfo({ ...demandList[0]?.prePlacement, phone: `${demandList[0]?.prePlacement.phone.code} ${demandList[0]?.prePlacement.phone.number}` } as any);
    }
  }, [demandList]);

  useEffect(() => {
    if (searchParams.size > 0) {
      const studentId = searchParams.get('student_id');
      const observerStudentId = searchParams.get('observer_student_id');
      if (studentId != null) {
        setStudentId(studentId);
      } else if (observerStudentId != null) {
        setStudentId(observerStudentId);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (studentId) {
      getDemandList(undefined, { accountId: studentId });
    }
  }, [studentId]);
  return (
    <div className="space-y-4">
      <StudentHeader
        layout={LayoutEnums.BRANCHMANAGER}
        name={profileInfo.name ?? ''}
        email={profileInfo.email ?? ''}
        phone={profileInfo.phone ?? ''}
        surname={profileInfo.surname ?? ''}
        accountId={studentId}
      />
      <StudentProfileTabs {...studentProfil} requestLoading={demandListLoading} onChangeKey={(key) => setActiveKey(key)} activeKey={activeKey} />
    </div>
  );
};

BranchStudentProfile.path = '/lms/student_profile';
BranchStudentProfile.roles = [Role.ADMIN];
