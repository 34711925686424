import { useNavigate } from 'react-router-dom';
import { PhoneInput, CountrySelector, TextInput } from '@shared/components';
import { Button, Form, Input, Select, notification, Card } from 'antd';
import { z } from 'zod';
import { useForm } from '@shared/hooks';
import TextArea from 'antd/lib/input/TextArea';
import i18n from '@shared/localization/i18n';
import { useEffect } from 'react';

const stylesLinks = ['./css/theme.min.css', '/css/bootstrap-icons.css', '/css/hs-mega-menu.min.css', './css/aos.css', './css/swiper-bundle.min.css'];

const InfoForm = z.object({
  fullName: z.string({ required_error: 'Marka adı zorunlu alandır' }).min(1, 'Marka adı 1 karekterden fazla olmalı'),
  email: z.string({ required_error: 'Email zorunlu alandır' }).min(1, 'TODO Bu değişecek ama şimdilik en az 1 karakter').email('Geçersiz e-mail adresi'),
  message: z.string({ required_error: 'Marka adı zorunlu alandır' }).min(1, 'Marka adı 1 karekterden fazla olmalı'),
});
export const FirstPage = () => {
  const navigate = useNavigate();

  const form = useForm<
    Partial<{
      fullName: string;
      email: string;
      message: string;
    }>
  >(InfoForm, {});

  const sendData = () => {
    const result = form.parse();

    if (result.success) {
      const data = result.data;
      return fetch('https://formkeep.com/f/b38e5472692a', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(data).toString(),
      })
        .then((response) => {
          if (!response.ok) {
            notification.error({ message: 'Mesajınız gönderilemedi' });
          }
        })
        .then((data) => {
          notification.success({ message: 'Mesajınız gönderildi' });
        });
    } else {
      form.setAllTouched();
    }
  };

  const handleScroll = (e) => {
    e.preventDefault(); // Butonun varsayılan davranışını engellemek
    const targetElement = document.getElementById('contact-section');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    const head = document.head;
    const links: HTMLElement[] = [];

    stylesLinks.forEach((url) => {
      const link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = url;

      head.appendChild(link);
      links.push(link);
    });

    return () => {
      links.forEach((link) => {
        head.removeChild(link);
      });
    };
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <img className="img-fluid rounded-2 mt-3 ml-5" style={{ width: '10%' }} src={require('../../platform/image/v151_414.png')} alt="Image Description" />
        <Button type="primary" size="large" className="mr-4 mt-3" onClick={(e) => handleScroll(e)}>
          {i18n.t('firstPage.5')}
        </Button>
      </div>

      <div className="overflow-hidden">
        <div className="container content-space-t-4 content-space-b-3">
          <div className="row justify-content-lg-between align-items-md-center">
            <div className="col-md-6 col-lg-5 mb-7 mb-md-0">
              <div className="mb-5">
                <h4>{i18n.t('firstPage.1')}</h4>
                <h1 className="mb-3 text-blue-500">{i18n.t('firstPage.2')}</h1>
                <div className="text-lg">{i18n.t('firstPage.3')}</div>
              </div>

              <div className="d-grid d-sm-flex gap-3">
                <a className="btn btn-primary btn-transition" onClick={() => navigate('/login')}>
                  {i18n.t('firstPage.4')}
                </a>
              </div>
            </div>

            <div className="col-md-6">
              <div className="position-relative">
                <img className="img-fluid rounded-2" src={require('../../platform/image/v149_142.png')} alt="Image Description" />
                <div className="position-absolute top-0 end-0 w-100 h-100 bg-soft-primary rounded-2 zi-n1 mt-5 me-n5"></div>
              </div>
            </div>
          </div>
          <div>
            <a className="card card-transition bg-black shadow-none mt-5">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-5 order-lg-2 mb-5 mb-lg-0">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-7">
                        <h2 className="card-title text-white h1 mt-10">JoedTech</h2>
                        <p className="card-text opacity-70 text-white text-justify">{i18n.t('firstPage.6')}</p>
                      </div>
                      <div className="row">
                        <div className="mt-2 mb-3 mb-sm-0 ">
                          <h4 className="card-title text-white"> {i18n.t('firstPage.7')}</h4>
                          <p className="card-text  text-white opacity-70 text-justify">{i18n.t('firstPage.8')}</p>
                        </div>

                        <div className="mt-6">
                          <h4 className="card-title text-white">{i18n.t('firstPage.9')}</h4>
                          <p className="card-text text-white opacity-70 text-justify">{i18n.t('firstPage.10')}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-7">
                    <div className="mb-5 item-center flex justify-center mt-8">
                      <img className="img-fluid rounded-2" src={require('../../platform/image/image2.png')} alt="Image Description" />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div>
            <a className="card card-transition bg-white shadow-none mt-5">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 order-lg-3 mb-5 mb-lg-0">
                    <div className="mb-5">
                      <img className="img-fluid rounded-2" src={require('../../platform/image/v151_375.png')} alt="Image Description" />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-7 mt-7">
                        <h2 className="card-title h2 mt-10">{i18n.t('firstPage.11')}</h2>
                        <p className="card-text text-black mt-5 font-bold opacity-70 text-justify">{i18n.t('firstPage.12')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div>
            <a className="card card-transition bg-white shadow-none mt-5">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 order-lg-3 mb-5 ">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-7 mt-10">
                        <h2 className="card-title h2 mt-10"> {i18n.t('firstPage.13')}</h2>
                        <p className="card-text text-black font-bold opacity-70 mt-5 text-justify">{i18n.t('firstPage.14')}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-5">
                      <img className="img-fluid rounded-2" src={require('../../platform/image/v149_291.png')} alt="Image Description" />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div>
            <a className="card card-transition bg-white shadow-none mt-5">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 order-lg-3 mb-5 mb-lg-0">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-7 mt-28">
                        <h2 className="card-title h2"> {i18n.t('firstPage.15')}</h2>
                        <p className="card-text text-black mt-5 font-bold opacity-70 text-justify">{i18n.t('firstPage.16')}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-5">
                      <img className="img-fluid rounded-2" src={require('../../platform/image/v151_383.png')} alt="Image Description" />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div>
            <a className="card card-transition bg-white shadow-none mt-5">
              <div className="card-body relative">
                <div className="row">
                  <div className="col-lg-6 order-lg-3 mb-5 mb-lg-0">
                    <div className="mb-5">
                      <img className="img-fluid rounded-2" src={require('../../platform/image/v151_387.png')} alt="Image Description" />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-7 mt-10">
                        <h2 className="card-title h2 mt-5"> {i18n.t('firstPage.17')} </h2>
                        <p className="card-text text-black mt-5 font-bold opacity-70 text-justify">{i18n.t('firstPage.18')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div>
            <a className="card card-transition bg-white shadow-none mt-5">
              <div className="card-body relative">
                <div className="row">
                  <div className="col-lg-6 order-lg-3 mb-5 mb-lg-0">
                    <div className="mb-5">
                      <img className="img-fluid rounded-2" src={require('../../platform/image/image.png')} alt="Image Description" />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-7 mt-10">
                        <h2 className="card-title h2 mt-5"> {i18n.t('firstPage.19')}</h2>
                        <p className="card-text text-black mt-5 font-bold opacity-70 text-justify">{i18n.t('firstPage.20')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div className="position-relative " id="contact-section">
          <div style={{ backgroundColor: '#1E1E1E' }}>
            <div className="container content-space-t-2 content-space-t-lg-3 content-space-b-1">
              <div className="w-lg-50 text-center mx-lg-auto mb-7">
                <h2 className="text-white lh-base">
                  {i18n.t('firstPage.21')} <span className="text-warning"> {i18n.t('firstPage.22')}</span>
                </h2>
              </div>

              <div className="mx-auto" style={{ maxWidth: '35rem' }}>
                <div className="card zi-2">
                  <div className="card-body">
                    <Form layout="vertical">
                      <div className="row">
                        <div className="">
                          <label className="form-label">{i18n.t('firstPage.23')}</label>

                          <TextInput formItem={form.formItem('fullName')} formInput={form.input('fullName')} />
                        </div>
                        <div className="">
                          <label className="form-label">{i18n.t('firstPage.24')}</label>
                          <TextInput formItem={form.formItem('email')} formInput={form.input('email')} />
                        </div>
                        <div className="">
                          <label className="form-label">{i18n.t('firstPage.25')}</label>
                          <TextArea {...form.input('message')} className="form-control form-control-lg" />
                        </div>

                        <div className="d-grid  mt-4">
                          <Button type="primary" onClick={sendData}>
                            {i18n.t('firstPage.26')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto text-center py-5" style={{ maxWidth: '35rem' }}>
              <div className="flex justify-center">
                <img className="img-fluid rounded-2" src={require('../../platform/image/logo2.png')} alt="Image Description" />
              </div>

              <div className="flex justify-center mt-4">
                <div className="opacity-70 italic text-lg text-white">{i18n.t('firstPage.27')}</div>
              </div>

              <div className="flex flex-row justify-center items-center mt-3 gap-2">
                <img className="img-fluid rounded-2 w-36" src={require('../../platform/image/v149_307.png')} alt="Image Description" />
                <img className="img-fluid rounded-2 w-36" src={require('../../platform/image/v149_306.png')} alt="Image Description" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
FirstPage.path = '/';
FirstPage.title = 'FirstPage';
FirstPage.group = 'flat';
