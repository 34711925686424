import {
  CheckCircleOutlined,
  CloseCircleTwoTone,
  CloseSquareFilled,
  ExclamationCircleTwoTone,
  ExpandOutlined,
  FullscreenExitOutlined,
  LinkOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import { DraftViewDrawer } from '@platform/platform_components';
import * as API from '@shared/api';
import { ExerciseOrPageHandler } from '@shared/components';
import { CdnScope } from '@shared/components/curriculum/FileUpload';
import { ClassActivityStatus, LayoutEnums, Role } from '@shared/constants';
import { useUserContext } from '@shared/contexts';
import { useAccessableSeachParams } from '@shared/hooks';
import {
  Attendances,
  ChoiceAnswerResponse,
  ContentTopicMeta,
  ContentUnitMeta,
  ContentVersionNavigation,
  CourseTopicPageModel,
  ExerciseAnswerRequestModel,
  MediaOrderAnswerResponse,
  OrderAnswerResponse,
  TeacherInClass,
} from '@shared/models';
import { GrNext, GrPrevious } from 'react-icons/gr';
import type { TourProps } from 'antd';
import { Avatar, Button, Card, Checkbox, Divider, Modal, notification, Pagination, Popconfirm, Popover, Slider, Spin, Tooltip, Tour } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RxFontSize } from 'react-icons/rx';
import { useNavigate, useSearchParams } from 'react-router-dom';

type InClassPagination = Partial<{
  current: number;
  total: number;
  prev: {
    unit: ContentUnitMeta;
    topic: ContentTopicMeta;
  };
  next: {
    unit: ContentUnitMeta;
    topic: ContentTopicMeta;
  };
}>;

export const TeacherLessonContent = () => {
  useAccessableSeachParams();
  const [modal] = Modal.useModal();

  const [exercisePage, setExercisePage] = useState<number>(0);
  const [answerRequestModel, setAnswerRequestModel] = useState<ExerciseAnswerRequestModel>();
  const [answerResponseModel, setAnswerResponseModel] = useState<ChoiceAnswerResponse | OrderAnswerResponse | MediaOrderAnswerResponse>();

  //TODO Teacher Layout düzeltiğinde burasıda oraya eşitlenmeli MARGİN SAĞDAN SOLDANü
  const [open, setOpen] = useState<boolean>(false);
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [searchParams, setSeachParams] = useSearchParams();
  const [isStartClass, setIsStartClass] = useState<boolean>(false);
  const [selectedUnit, setSelectedUnit] = useState<ContentUnitMeta>();
  const [selectedTopic, setSelectedTopic] = useState<ContentTopicMeta>();
  const { user } = useUserContext();
  const [attendances, setAttendances] = useState<Attendances[]>();
  const [classId, setClassId] = useState<string>();
  const [activityClass, setActivityClass] = useState<TeacherInClass>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isInspection, setIsInspection] = useState<boolean>(false);
  const [icon, setIcon] = useState<any>();
  const [title, setTitle] = useState<string>('');
  const [titleColor, setTitleColor] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [contentProgress, setContentProgress] = useState<ContentVersionNavigation>();
  const [selectedPage, setSelectedPage] = useState<CourseTopicPageModel>();
  const [classActivityStatus, setClassActivityStatus] = useState<ClassActivityStatus>();
  const [isLoadingNextPage, setIsLoadingNextPage] = useState<boolean>(false);
  const [draftDrawerOpen, setDraftDrawerOpen] = useState<boolean>(false);
  const [fontSize, setFontSize] = useState<number>(1.1);

  //Mouse hareketiyle beraber Full Screenden çıkma butonunu göstermek için
  const fullscreenDivRef = useRef<HTMLDivElement>(null);
  const [showExitButton, setShowExitButton] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const layout = searchParams.get('layout');
    const brandId = searchParams.get('brandId');
    const branchId = searchParams.get('branchId');
    const class_id = searchParams.get('class_id');
    setSeachParams({ layout: layout!, brandId: brandId!, branchId: branchId!, class_id: class_id!, fontSize: fontSize.toString() }, { replace: false });
  }, [fontSize]);

  useEffect(() => {
    if (contentProgress) {
      const {
        page,
        meta: { unit, topic },
      } = contentProgress;
      setSelectedPage(page);
      setSelectedUnit(unit);
      setSelectedTopic(topic);
    }
  }, [contentProgress]);

  const inclassPagination = useMemo(() => {
    const result: InClassPagination = {};
    if (selectedUnit && selectedTopic && contentProgress && selectedPage) {
      const { tree } = contentProgress;
      if (tree.length > 0) {
        tree.forEach((unit, unitIndex) => {
          if (unit.id == selectedUnit.id) {
            unit.topics.forEach((topic, topicIndex) => {
              if (topic.id == selectedTopic.id) {
                topic.pages.forEach((page, pageIndex) => {
                  if (page.id == selectedPage.id) {
                    result.current = pageIndex + 1;
                  }
                });
                result.total = topic.pages.length;
                if (topicIndex != 0) {
                  result.prev = {
                    topic: unit.topics[topicIndex - 1],
                    unit: unit,
                  };
                } else {
                  if (unitIndex != 0) {
                    const u = tree[unitIndex - 1];
                    result.prev = {
                      topic: tree[unitIndex - 1].topics[0],
                      unit: u,
                    };
                  }
                }
                if (unit.topics.length - 1 != topicIndex) {
                  result.next = {
                    topic: unit.topics[topicIndex + 1],
                    unit: unit,
                  };
                } else {
                  if (unitIndex != tree.length - 1) {
                    const u = tree[unitIndex + 1];
                    result.next = {
                      unit: u,
                      topic: u.topics[0],
                    };
                  }
                }
              }
            });
          }
        });
      }
    }
    return result;
  }, [selectedUnit, selectedTopic, contentProgress, selectedPage]);

  useEffect(() => {
    if (classActivityStatus == ClassActivityStatus.WAITING_FOR_ATTENDANCE) {
      Modal.warning({
        title: 'Yoklama alınız',
        content: 'Derse başlayabilmek için yoklama alınız',
      });
    }
  }, [classActivityStatus]);

  useEffect(() => {
    const _classId = searchParams.get('class_id');
    if (searchParams.size > 0 && _classId && (!classId || classId != _classId)) {
      setLoading(true);
      getActivityClass(_classId);
      setClassId(_classId);
    }
  }, [searchParams]);

  const errorMapping = {
    'teacher.not.found': {
      icon: <CloseCircleTwoTone twoToneColor="#FF4D4F" style={{ fontSize: '150%' }} />,
      title: 'Yetki Hatası',
      titleColor: '#FF4D4F',
      description: 'Seansı başlatmak için yetkiniz bulunmamaktadır.',
    },
    'class.not.found': {
      icon: <ExclamationCircleTwoTone twoToneColor="#FAAD14" style={{ fontSize: '150%' }} />,
      title: 'Sınıf bulunamadı',
      titleColor: '#FAAD14',
      description: 'Seansı başlatmak istediğiniz sınıf bulunmamaktadır.',
    },
    'class.calendar.entry.not.found': {
      icon: <ExclamationCircleTwoTone twoToneColor="#FAAD14" style={{ fontSize: '150%' }} />,
      title: 'Seans başlatılamadı',
      titleColor: '#FAAD14',
      description: 'Bu tarihe ait Inclass bulunmamaktadır.',
    },
    'in.class.is.not.available.now': {
      icon: <ExclamationCircleTwoTone twoToneColor="#FAAD14" style={{ fontSize: '150%' }} />,
      title: 'Seans başlatılamadı',
      titleColor: '#FAAD14',
      description: 'Şuan sınıfa ait bir seans bulunmamaktadır.',
    },
    'class.activity.already.completed': {
      icon: <CloseCircleTwoTone twoToneColor="#FF4D4F" style={{ fontSize: '150%' }} />,
      title: 'Süresi Doldu',
      titleColor: '#FF4D4F',
      description: 'Başlatmak istediğiniz seans tamamlanmıştır.',
    },
    'content.version.not.found': {
      icon: <ExclamationCircleTwoTone twoToneColor="#FAAD14" style={{ fontSize: '150%' }} />,
      title: 'İçerik bulunamadı',
      titleColor: '#FAAD14',
      description: 'Erişmek istediğiniz Inclassa ait içerik bulunamadı',
    },
  };

  const getActivityClass = async (classId) => {
    try {
      const activityClass = await API.CLASS.startInClass(classId);

      if (!activityClass.ok) {
        setIsStartClass(false);
        const errorKey = activityClass.error.message;
        const errorData = errorMapping[errorKey];

        if (errorData) {
          setIcon(errorData.icon);
          setTitle(errorData.title);
          setTitleColor(errorData.titleColor);
          setDescription(errorData.description);
        }
      } else {
        if (activityClass.data.status == 'STARTED') {
          setIsInspection(true);
        }
        setIsStartClass(true);
        setActivityClass(activityClass.data);
        setAttendances(activityClass?.data.attendances);
        setContentProgress(activityClass.data.contentProgress);
        setClassActivityStatus(activityClass.data.status);
      }
    } catch (error) {
      notification.error({ message: 'Hata!', description: 'Aktif sınıf bulunamadı' });
    } finally {
      setLoading(false);
    }
  };

  const changeAttendanceStatus = (index: number) => {
    setAttendances((prevAttendances) => prevAttendances?.map((attendance, i) => (i === index ? { ...attendance, attended: !attendance.attended } : attendance)));
  };

  const markAllAttendances = () => {
    setAttendances((prevAttendances) => prevAttendances?.map((attendance) => ({ ...attendance, attended: true })));
  };

  const completeAttendances = async () => {
    if (classId) {
      const responseUpdateClassAttendances = await API.CLASS.updateClassAttendances(
        attendances?.map((attendance) => ({
          attended: attendance.attended,
          studentId: attendance?.student?.id,
        })),
        classId,
        activityClass?.id!
      );
      if (responseUpdateClassAttendances.ok) {
        notification.success({ message: 'Yoklama başarıyla alındı.' });
      } else {
        notification.warning({ message: 'Yoklama alma başarısız' });
      }
    }
  };

  const gotoPage = useCallback(
    async (unit: ContentUnitMeta, topic: ContentTopicMeta, pageIndex = -1) => {
      if (classId) {
        try {
          setIsLoadingNextPage(true);
          setSelectedUnit(unit);
          setSelectedTopic(topic);

          if (pageIndex == -1 && contentProgress) {
            const { tree } = contentProgress;
            const pageId = tree
              .find((t) => {
                return t.id == unit.id;
              })
              ?.topics.find((t) => {
                return t.id == topic.id;
              })?.pages[0].id;
            if (pageId) {
              const { data, ok } = await API.CLASS.gotoPage(classId, activityClass?.id!, pageId);
              if (ok) {
                setContentProgress(data);
              }
              setIsLoadingNextPage(false);
            }
          } else {
            const p = contentProgress?.tree
              .find((t) => {
                return t.id == selectedUnit!.id;
              })
              ?.topics.find((t) => {
                return t.id == selectedTopic!.id;
              })?.pages[pageIndex - 1];
            const { data, ok } = await API.CLASS.gotoPage(classId, activityClass?.id!, p?.id!);
            if (ok) {
              setContentProgress(data);
            }
            setIsLoadingNextPage(false);
          }
        } catch (error) {
          notification.error({ message: 'Hata!', description: 'Sayfa bulunamadı!' });
          setIsLoadingNextPage(false);
        }
      } else {
        setIsLoadingNextPage(false);
      }
    },
    [classId, selectedUnit, selectedTopic, contentProgress]
  );

  const onChangeGoToPage = useCallback(
    (page) => {
      if (selectedUnit && selectedTopic) {
        gotoPage(selectedUnit, selectedTopic, page);
      }
    },
    [selectedUnit, selectedTopic]
  );

  //Full Screen ise ESC tuşu ile fullscreenden çıkmak ve ok tuşlarıyla sayfa değişimleri için
  useEffect(() => {
    const handleFullscreenChange = () => {
      // Eğer tam ekran modu kapatılmışsa, isExpand'i false yap
      if (!document.fullscreenElement) {
        setIsExpand(false);
      }
    };

    // fullscreenchange olayını dinle
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      // Temizleme: fullscreenchange olayını kaldır
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    if (isExpand) {
      // isExpand true olduğunda tam ekran moduna geç
      fullscreenDivRef.current?.requestFullscreen();
    } else if (document.fullscreenElement) {
      // isExpand false olduğunda tam ekran modundan çık
      document.exitFullscreen();
    }
  }, [isExpand]);

  // Mouse hareketini algılayan fonksiyon
  const handleMouseMove = () => {
    // Butonu göster
    setShowExitButton(true);

    // Önceki timeout'u temizle, ardından yeni timeout başlat
    if (timeoutId) clearTimeout(timeoutId);

    const newTimeoutId = setTimeout(() => {
      setShowExitButton(false);
    }, 2000);

    setTimeoutId(newTimeoutId);
  };

  // Component unmount sırasında timeout'u temizleyin
  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  //Full Screen İşlemleri END

  const closeInClass = async () => {
    if (classId) {
      const responseCompeletedInClass = await API.CLASS.compeletedInClass(classId, activityClass?.id!);
      if (responseCompeletedInClass.ok) {
        navigate('/' + LayoutEnums.TEACHER + '/class' + location.search);
        notification.success({ message: 'Sınıf başarıyla tamamlandı' });
      }
    }
  };

  const onCheckAnswer = useCallback(async () => {
    try {
      const response = await API.CURRICULUM.INCLASS_EXERCISE.checkAnswer(
        classId!,
        activityClass?.id!,
        selectedPage?.exercise?.id!,
        selectedPage?.exercise?.exercises![exercisePage].id!,
        answerRequestModel!
      );
      if (response.ok) {
        setAnswerResponseModel(response.data);
      }
    } catch (error) {}
  }, [exercisePage, selectedPage, classId, activityClass?.id, answerRequestModel]);

  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const steps: TourProps['steps'] = [
    {
      title: 'Upload File',
      description: 'Put your files here.',
      className: 'w-64',
      renderPanel: () => (
        <div className="grid grid-cols-1 gap-2">
          <Card
            onClick={async () => {
              setOpen(false);
              await completeAttendances();
              await getActivityClass(classId);
              setIsInspection(true);
            }}
            className="bg-blue-600 p-2 border-1 cursor-pointer"
            styles={{ body: { padding: '0' } }}
          >
            <div className="flex item-center justify-center text-white">Yoklamayı tamamla</div>
          </Card>
          <Card onClick={() => setOpen(false)} className="bg-white p-2 border-1 border-red-500 cursor-pointer" styles={{ body: { padding: '0' } }}>
            <div className="flex item-center justify-center text-red-500">Vazgeç</div>
          </Card>
        </div>
      ),
      target: () => ref1.current,
    },
  ];

  const Message = ({ icon, title, description, onButtonClick }) => (
    <div className="flex items-center justify-center text-center" style={{ height: '60vh' }}>
      <div className="space-y-4">
        {icon && <div className="text-2xl">{icon}</div>}
        {title && (
          <div className="text-lg italic" style={{ color: titleColor }}>
            {title}
          </div>
        )}
        {description && <div className="text-base">{description}</div>}
        <div>
          <Button onClick={onButtonClick}>Sınıflarıma Dön</Button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <DraftViewDrawer
        draftDrawerOpen={draftDrawerOpen}
        levelId={activityClass?.level?.id}
        brandLevelId={activityClass?.level?.brandDefinitionId}
        draftDrawerOnClose={() => {
          setDraftDrawerOpen(false);
        }}
        onRenderColumn={() => {}}
        defaultClassId={classId}
      />
      <div style={{ overflow: 'auto', backgroundColor: '#F5F5F5' }}>
        <Spin className="" fullscreen spinning={loading} size="large" tip={'Ders oluşturuluyor...'} />
        {!loading ? (
          <div>
            {!isExpand ? (
              <div>
                {isStartClass ? (
                  <div className="grid grid-cols-5 gap-2">
                    <div className="col-span-1">
                      <Card styles={{ body: { padding: '0' } }} className="p-1">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center ml-2">
                            <Avatar style={{ backgroundColor: 'red', verticalAlign: 'middle' }} size="small">
                              {user.name?.substring(0, 1)}
                            </Avatar>
                            <span className="ml-4">
                              {user.name} {user.surname}
                            </span>
                          </div>
                        </div>
                      </Card>
                    </div>
                    <div className="col-span-3">
                      <Card styles={{ body: { padding: '0' } }} className="p-1">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center ml-3">{activityClass?.name}</div>
                        </div>
                      </Card>
                    </div>
                    <div className="col-span-1 grid grid-cols-2">
                      <Card ref={ref2} styles={{ body: { padding: '0' } }} className="p-1 text-center cursor-pointer" onClick={() => setOpen(true)}>
                        Yoklama al
                      </Card>
                      <Card styles={{ body: { padding: '0' } }} className="p-1 text-center cursor-pointer" onClick={() => setDraftDrawerOpen(true)}>
                        Ödev Ata
                      </Card>
                    </div>

                    <div className="col-span-1">
                      <Card style={{ height: '70vh' }} styles={{ body: { padding: '0' } }} className="p-2">
                        <div className="grid grid-cols-12">
                          <div className="col-span-1">
                            <LinkOutlined />
                          </div>
                          <div className="col-span-11 grid grid-cols-1">
                            <div className="opacity-45">Konuyla ilişkili ünite ve egzersizler</div>
                          </div>
                        </div>
                        <div style={{ height: '50vh' }} className="overflow-auto">
                          {activityClass?.contentProgress?.tree?.map((unit, unitIndex) => (
                            <Card key={unitIndex} styles={{ body: { padding: '0' } }} className="p-2 mb-2 mt-2">
                              <div>
                                <div
                                  style={selectedUnit && selectedUnit.id == unit.id ? { backgroundColor: '#E6F4FF' } : {}}
                                  className="flex items-center justify-between rounded-md p-1"
                                >
                                  <span style={selectedUnit && selectedUnit.id == unit.id ? { color: '#1677ff' } : {}}>{unit.name} </span>
                                </div>

                                <Divider className="m-1" orientation="left"></Divider>
                                {unit.topics.map((topic, topicIndex) => (
                                  <Card
                                    onClick={() => gotoPage(unit, topic)}
                                    style={selectedTopic && selectedTopic.id == topic.id ? { backgroundColor: '#E6F4FF' } : {}}
                                    key={topicIndex}
                                    styles={{ body: { padding: '0' } }}
                                    className="p-2 mt-3 cursor-pointer"
                                  >
                                    <span style={selectedTopic && selectedTopic.id == topic.id ? { color: '#1677ff' } : {}}>{topic.name}</span>
                                  </Card>
                                ))}
                              </div>
                            </Card>
                          ))}
                        </div>
                      </Card>
                    </div>
                    <div className="col-span-3">
                      <div className="flex flex-col relative">
                        <LessonCard
                          height="70vh"
                          imageHeight="50vh"
                          selectedPage={selectedPage}
                          isLoadingNextPage={isLoadingNextPage}
                          exercisePage={exercisePage}
                          setExercisePage={setExercisePage}
                          setAnswerResponseModel={setAnswerResponseModel}
                          setAnswerRequestModel={setAnswerRequestModel}
                          answerRequestModel={answerRequestModel}
                          answerResponseModel={answerResponseModel}
                          onCheckAnswer={onCheckAnswer}
                          isExpand={isExpand}
                        />
                        {Object.keys(inclassPagination).length > 0 && (
                          <div className="flex flex-row items-center justify-between mt-1 z-20">
                            {inclassPagination.prev && Object.keys(inclassPagination.prev).length > 0 && (
                              <div
                                className="cursor-pointer flex flex-row justify-center items-center space-x-1 border border-solid border-gray-400 p-1 rounded-md"
                                onClick={() => {
                                  gotoPage(inclassPagination.prev!.unit, inclassPagination.prev!.topic);
                                }}
                              >
                                <GrPrevious />
                                <span className="text-sm">{inclassPagination.prev.topic.name}</span>
                              </div>
                            )}
                            <Tooltip open={classActivityStatus == ClassActivityStatus.WAITING_FOR_ATTENDANCE} title={'Lütfen yoklama alınız'}>
                              <Pagination
                                disabled={classActivityStatus == ClassActivityStatus.WAITING_FOR_ATTENDANCE}
                                current={inclassPagination.current}
                                pageSize={1}
                                total={inclassPagination.total}
                                onChange={(page) => {
                                  onChangeGoToPage(page);
                                }}
                                className="mx-auto"
                                showSizeChanger={false}
                              />
                            </Tooltip>
                            {inclassPagination.next && Object.keys(inclassPagination.next).length > 0 && (
                              <div
                                className="cursor-pointer flex flex-row justify-center items-center space-x-1 border border-solid border-gray-400 p-1 rounded-md"
                                onClick={() => {
                                  gotoPage(inclassPagination.next!.unit, inclassPagination.next!.topic);
                                }}
                              >
                                <span className="text-sm">{inclassPagination.next.topic.name}</span>
                                <GrNext />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div ref={ref1}>
                        <Card className="p-3" style={{ height: '70vh', overflowY: 'auto' }} styles={{ body: { padding: '0' } }}>
                          <div>
                            <div className="mb-4 flex justify-between">
                              <span className="opacity-45">Öğrenciler</span>
                              {open && (
                                <span onClick={markAllAttendances} className="cursor-pointer">
                                  <Checkbox>
                                    <span className="opacity-45">Herkes burada</span>
                                  </Checkbox>
                                </span>
                              )}
                            </div>
                            {attendances?.map((attendance, attendanceIndex) => (
                              <div key={attendanceIndex}>
                                <div className="flex justify-between">
                                  {attendance.student?.name} {attendance.student?.surname}
                                  {open ? (
                                    <div className="cursor-pointer" onClick={() => changeAttendanceStatus(attendanceIndex)}>
                                      {attendance.attended ? <CheckCircleOutlined style={{ color: '#52C41A' }} /> : <MinusCircleOutlined style={{ color: '#F5222D' }} />}
                                    </div>
                                  ) : (
                                    isInspection && (
                                      <div className="cursor-pointer">
                                        {attendance.attended ? (
                                          <Tooltip title="Öğrenci burada">
                                            <CheckCircleOutlined style={{ color: '#52C41A' }} />
                                          </Tooltip>
                                        ) : (
                                          <Tooltip title="Öğrenci yok">
                                            <MinusCircleOutlined style={{ color: '#F5222D' }} />
                                          </Tooltip>
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                                <Divider className="m-1" orientation="left"></Divider>
                              </div>
                            ))}
                          </div>
                        </Card>

                        {open ?? (
                          <Card onClick={() => setOpen(false)} className="bg-white p-2 border-1 border-red-500 cursor-pointer" styles={{ body: { padding: '0' } }}>
                            <div className="flex item-center justify-center text-red-500">Vazgeç</div>
                          </Card>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <Message
                    icon={icon}
                    title={title}
                    description={description}
                    onButtonClick={() => {
                      const params = new URLSearchParams(location.search);
                      params.delete('class_id');
                      const newSearch = params.toString();
                      navigate(`/${LayoutEnums.TEACHER}/class${newSearch ? `?${newSearch}` : ''}`);
                    }}
                  />
                )}
              </div>
            ) : (
              <div ref={fullscreenDivRef} className="bg-white" onMouseMove={handleMouseMove}>
                <LessonCard
                  height="95vh"
                  imageHeight="55vh"
                  selectedPage={selectedPage}
                  isLoadingNextPage={isLoadingNextPage}
                  exercisePage={exercisePage}
                  setExercisePage={setExercisePage}
                  setAnswerResponseModel={setAnswerResponseModel}
                  setAnswerRequestModel={setAnswerRequestModel}
                  answerRequestModel={answerRequestModel}
                  answerResponseModel={answerResponseModel}
                  onCheckAnswer={onCheckAnswer}
                  isExpand={isExpand}
                />

                {showExitButton && (
                  <Button size="large" shape="circle" type="primary" className="fixed top-3 right-3 p-2" onClick={() => setIsExpand(false)}>
                    X
                  </Button>
                )}

                {showExitButton && Object.keys(inclassPagination).length > 0 && (
                  <div className="fixed bottom-3  left-1/2 -translate-x-1/2">
                    <div className=" flex flex-row items-center justify-between mt-1">
                      {inclassPagination.prev && Object.keys(inclassPagination.prev).length > 0 && (
                        <div
                          className="cursor-pointer flex flex-row justify-center items-center space-x-1 border border-solid border-gray-400 p-1 rounded-md"
                          onClick={() => {
                            gotoPage(inclassPagination.prev!.unit, inclassPagination.prev!.topic);
                          }}
                        >
                          <GrPrevious />
                          <span className="text-sm">{inclassPagination.prev.topic.name}</span>
                        </div>
                      )}
                      <Tooltip open={classActivityStatus == ClassActivityStatus.WAITING_FOR_ATTENDANCE} title={'Lütfen yoklama alınız'}>
                        <Pagination
                          disabled={classActivityStatus == ClassActivityStatus.WAITING_FOR_ATTENDANCE}
                          current={inclassPagination.current}
                          pageSize={1}
                          total={inclassPagination.total}
                          onChange={(page) => {
                            onChangeGoToPage(page);
                          }}
                          className="mx-auto"
                          showSizeChanger={false}
                        />
                      </Tooltip>
                      {inclassPagination.next && Object.keys(inclassPagination.next).length > 0 && (
                        <div
                          className="cursor-pointer flex flex-row justify-center items-center space-x-1 border border-solid border-gray-400 p-1 rounded-md"
                          onClick={() => {
                            gotoPage(inclassPagination.next!.unit, inclassPagination.next!.topic);
                          }}
                        >
                          <span className="text-sm">{inclassPagination.next.topic.name}</span>
                          <GrNext />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            {isStartClass ? (
              <div className="flex items-center justify-center space-x-3 mt-2">
                <div className="bg-card text-card-foreground rounded-lg flex items-center justify-center border border-border cursor-pointer">
                  <Popconfirm
                    title={
                      <span>
                        {String(activityClass?.classSeanceItem?.beginHour).padStart(2, '0')}:{String(activityClass?.classSeanceItem?.beginMinute).padStart(2, '0')} -
                        {String(activityClass?.classSeanceItem?.endHour).padStart(2, '0')}:{String(activityClass?.classSeanceItem?.endMinute).padStart(2, '0')} seans aralığındaki
                        sınıfı sonlandırmak istediğinize emin misiniz?
                      </span>
                    }
                    okText="Evet"
                    cancelText="Hayır"
                    onConfirm={() => {
                      closeInClass();
                    }}
                  >
                    <CloseSquareFilled style={{ fontSize: '300%', color: 'red' }} />
                  </Popconfirm>
                </div>
                <div
                  onClick={() => setIsExpand((prevValue) => !prevValue)}
                  className="bg-card text-card-foreground bg-white p-3 rounded-lg flex items-center justify-center border border-border cursor-pointer"
                >
                  <ExpandOutlined style={{ fontSize: '200%' }} />
                </div>
                <div className="bg-card text-card-foreground bg-white p-3 rounded-lg flex items-center justify-center border border-border cursor-pointer">
                  <Popover
                    placement="right"
                    content={
                      <div className="w-52 flex flex-col">
                        <span>Yazı boyutu</span>
                        <Slider
                          min={1}
                          max={2}
                          step={0.1}
                          value={searchParams.get('fontSize') ? Number(searchParams.get('fontSize')) : 1.1}
                          onChange={(v) => {
                            setFontSize(v);
                          }}
                        />
                      </div>
                    }
                  >
                    <RxFontSize style={{ fontSize: '200%' }} />
                  </Popover>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <Tour
              open={open}
              onClose={() => setOpen(false)}
              steps={steps}
              arrow={false}
              mask={{
                style: {
                  boxShadow: 'inset 0 0 15px #333',
                },
                color: 'rgba(0, 0, 0, .4)',
              }}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

TeacherLessonContent.path = '/' + LayoutEnums.TEACHER + '/lesson-content';
TeacherLessonContent.roles = [Role.ADMIN];
TeacherLessonContent.layouts = [LayoutEnums.TEACHER];

const LessonCard = ({
  height,
  imageHeight,
  selectedPage,
  isLoadingNextPage,
  exercisePage,
  setExercisePage,
  setAnswerResponseModel,
  setAnswerRequestModel,
  answerRequestModel,
  onCheckAnswer,
  answerResponseModel,
  isExpand,
}) => {
  return (
    <div style={{ height }} className="p-3 bg-white rounded-lg">
      {selectedPage ? (
        <div className={'h-full'}>
          {isLoadingNextPage ? (
            <div className="flex flex-col justify-center items-center h-full w-full">
              <Spin size="large" />
              <div className="mt-3">Sayfa yükleniyor...</div>
            </div>
          ) : (
            <ExerciseOrPageHandler
              exercisePage={exercisePage}
              onChangeExercisePage={setExercisePage}
              cdnScope={CdnScope.BRANCH}
              page={selectedPage}
              isInClass={true}
              isPreview={false}
              onNext={() => {
                //TODO : bunları doldur
              }}
              onPrev={() => {
                //TODO : bunları doldur
              }}
              onChangeAnswerRequestModel={(model) => {
                if (!model) {
                  setAnswerResponseModel(undefined);
                }

                setAnswerRequestModel(model);
              }}
              onSendDialogResult={onCheckAnswer}
              answerRequestModel={answerRequestModel}
              onCheckAnswer={onCheckAnswer}
              answerResponseModel={answerResponseModel}
              onResponseModelPronounciation={(success: boolean) => setAnswerResponseModel({ success })}
              isExpand={isExpand}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
