import { InboxOutlined } from "@ant-design/icons";
import { EnrollmentUserCard } from "@platform/platform_components/EnrollmentUserCard";
import { Observer } from "@shared/models";

type EnrollmentObserverListProp = {
    observers?: Observer[];
    onDelete?: (user: Observer) => void,
    onEdit?: (user: Observer) => void,
    isDelete?: boolean,
    isEdit?: boolean,
}

export const EnrollmentObserverList = ({ observers, onDelete, onEdit, isDelete = true, isEdit = true}: EnrollmentObserverListProp) => {
    return <div className='border-dashed border-gray-300 rounded-md p-4'>
        {observers && observers?.length > 0 ?
            (
                <div className='flex flex-row w-full space-x-2 overflow-y-auto'>
                    {observers?.map((observer) => (
                        <EnrollmentUserCard
                            key={observer.id}
                            user={observer}
                            onDelete={(user: Observer) => {
                                if (onDelete) {
                                    onDelete(user);
                                }
                            }}
                            onEdit={(user: Observer) => {
                                if (onEdit) {
                                    onEdit(user);
                                }
                            }}
                            isSmall={true}
                            isDelete={isDelete}
                            isEdit={isEdit}
                            className='min-w-80 p-1'
                        />
                    ))}
                </div>
            )
            :
            (
                <div className='flex flex-row'>
                    <InboxOutlined className='text-3xl text-gray-400' />
                    <div className='ml-4'><span className='text-lg font-normal text-gray-400'>Henüz gözetmen eklemediniz.</span></div>
                </div>
            )}

    </div>;
}