import { CalendarOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { ExcludedHolidayTable, HolidayTable } from '@platform/platform_components';
import * as API from '@shared/api';
import { CreateHoliday } from '@shared/components';
import { BrandOrBranch, LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { PaginationQuery } from '@shared/models';
import { Button, Modal, notification, Tabs, TabsProps } from 'antd';
import { useEffect, useState } from 'react';
import { MdOutlineEditCalendar } from 'react-icons/md';

export const BrandHolidayManagement = () => {
  useAccessableSeachParams();
  const [holidayCalendar, holidayCalendarLoading, getHolidayCalendar] = API.CALENDAR.getBrandCalendar({ init: true });
  const [excludedCalendar, excludedCalendarLoading, getExcludedCalendar] = API.CALENDAR.getBrandExcludedCalendar({ init: false });
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 10 });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (paginationQuery) {
      getExcludedCalendar(undefined, paginationQuery);
    }
  }, [paginationQuery]);

  const onDeleteHoliday = async (id: string) => {
    const response = await API.CALENDAR.deleteBrandHoliday(id);
    if (response.ok) {
      notification.success({ message: 'Tatil iptal edildi!' });
      getHolidayCalendar();
      getExcludedCalendar(undefined, paginationQuery);
    }
  };

  const onBringBackHoliday = async (id: string) => {
    const response = await API.CALENDAR.bringBackBrandHoliday(id);
    if (response.ok) {
      notification.success({ message: 'Tatil aktif edildi!' });
      getHolidayCalendar();
      setPaginationQuery((prev) => ({ ...prev, page: 1 })); //Excluded Calendarı baştan çağırıyoruz Page'i 1 olarak.
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Tatil Günlerini Yönet',
      children: <HolidayTable loading={holidayCalendarLoading} holidayCalendar={holidayCalendar!} onDeleteHoliday={onDeleteHoliday} brandOrBranch={BrandOrBranch.BRAND} />,
    },
    {
      key: '2',
      label: 'Sistemin Uygulanmayan Tatil Günleri',
      children: (
        <ExcludedHolidayTable
          loading={excludedCalendarLoading}
          excludedHolidayCalendar={excludedCalendar}
          onPaginationChange={(current, pageSize) => setPaginationQuery((prev) => ({ ...prev, page: current, limit: pageSize }))}
          onBringBackHoliday={onBringBackHoliday}
        />
      ),
    },
  ];

  const onCreateHoliday = async (data: any) => {
    const response = await API.CALENDAR.createBrandHoliday(data);
    if (response.ok) {
      getHolidayCalendar();
      setIsModalOpen(false);
      notification.success({ message: 'Tatil Oluşturuldu!' });
    }
  };

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        tabBarExtraContent={
          <Button className="w-full" type="dashed" onClick={() => setIsModalOpen(true)} icon={<PlusCircleOutlined />}>
            Yeni Tatil Günü Oluştur
          </Button>
        }
        type="line"
        items={items}
      />
      <Modal open={isModalOpen} footer={null} title="Tatil Günü Oluştur" onCancel={() => setIsModalOpen(false)}>
        <CreateHoliday onClose={() => setIsModalOpen(false)} onCreateHoliday={onCreateHoliday} />
      </Modal>
    </>
  );
};

BrandHolidayManagement.path = '/brand_manager/holiday-management';
BrandHolidayManagement.title = 'Takvimleri Yönet';
BrandHolidayManagement.group = 'flat';
BrandHolidayManagement.roles = [Role.ADMIN];
BrandHolidayManagement.layouts = [LayoutEnums.BRANDMANAGER];
BrandHolidayManagement.icon = <MdOutlineEditCalendar />;
