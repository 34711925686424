import { StudentHeader, StudentProfileTabs } from '@platform/platform_components';
import { LayoutEnums, Role } from '@shared/constants';
import { useUserContext } from '@shared/contexts';
import { useAccessableSeachParams, useStudentProfile } from '@shared/hooks';
import { useState } from 'react';
import * as API from '@shared/api';

export const StudentProfile = () => {
  useAccessableSeachParams();

  const { user } = useUserContext();
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const [demandList, demandListLoading, getDemandList] = API.STUDENT.getStudentDemands({ init: true });
  const studentProfil = useStudentProfile(LayoutEnums.STUDENT, activeKey, demandList);

  return (
    <div className="py-2 space-y-5">
      <StudentHeader {...studentProfil} layout={LayoutEnums.STUDENT} name={user.name!} surname={user.surname!} email={user.email!} />
      <StudentProfileTabs {...studentProfil} requestLoading={demandListLoading} activeKey={activeKey} onChangeKey={(key) => setActiveKey(key)} />
    </div>
  );
};

StudentProfile.path = '/' + LayoutEnums.STUDENT + '/profile';
StudentProfile.roles = [Role.ADMIN];
StudentProfile.layouts = [LayoutEnums.STUDENT];
