import { BookOutlined, CalendarOutlined, FileDoneOutlined, FileTextOutlined, PaperClipOutlined, SyncOutlined } from '@ant-design/icons';
import { DraftViewDrawer, HomeworkEditDrawer, TeacherClassFilter } from '@platform/platform_components';
import { KanbanColumn, KanbanColumnTitle } from '@platform/platform_components/kanban_view/KanbanColumn';
import * as API from '@shared/api';
import { ActionDropdown } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { ClassHomeworkProps, PaginationQuery } from '@shared/models';
import { convertDate, renderLevelsAsString } from '@shared/utils';
import { Button, notification } from 'antd';
import { useEffect, useState } from 'react';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';

const TaskCardContent = ({ homework }) => {
  return (
    <>
      {homework.class ? (
        <>
          <div>
            <small className="text-gray-500">{renderLevelsAsString(homework.class?.level)}</small>
          </div>
          <div>
            <small className="text-gray-500">{homework.class?.name}</small>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

type TeacherClassHomeWorkProps = {
  isDetail: boolean;
  classId?: string;
};

export const TeacherClassHomeWork = ({ isDetail = false, classId }: TeacherClassHomeWorkProps) => {
  useAccessableSeachParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [homeworkData, setHomeworkData] = useState<ClassHomeworkProps>();
  const [homeworkDrawerOpen, setHomeworkDrawerOpen] = useState<boolean>(false);
  const [draftDrawerOpen, setDraftDrawerOpen] = useState<boolean>(false);
  const [activePaginationQuery, setActivePaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 3, levelIds: [], status: 'ACTIVE' });
  const [waitingPaginationQuery, setWaitingPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 3, levelIds: [], status: 'WAITING' });
  const [expiredPaginationQuery, setExpiredPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 3, levelIds: [], status: 'EXPIRED' });

  const [activeHomeworks, isActiveHomeworksLoading, getActiveHomeworks] = API.HOMEWORK.paginateClassHomeworkByRequestedTeacher({ init: false });
  const [waitingHomeworks, isWaitingHomeworksLoading, getWaitingHomeworks] = API.HOMEWORK.paginateClassHomeworkByRequestedTeacher({ init: false });
  const [expiredHomeworks, isExpiredHomeworksLoading, getExpiredHomeworks] = API.HOMEWORK.paginateClassHomeworkByRequestedTeacher({ init: false });

  const [activeHomeworksDetail, isActiveHomeworksDetailLoading, getActiveHomeworksDetail] = API.HOMEWORK.paginateClassHomeworkByRequestedTeacherClass({ init: false }, classId!);
  const [waitingHomeworksDetail, isWaitingHomeworksDetailLoading, getWaitingHomeworksDetail] = API.HOMEWORK.paginateClassHomeworkByRequestedTeacherClass({ init: false }, classId!);
  const [expiredHomeworksDetail, isExpiredHomeworksDetailLoading, getExpiredHomeworksDetail] = API.HOMEWORK.paginateClassHomeworkByRequestedTeacherClass({ init: false }, classId!);

  useEffect(() => {
    if (!isDetail) {
      getActiveHomeworks(undefined, activePaginationQuery);
    } else {
      getActiveHomeworksDetail(undefined, activePaginationQuery);
    }
  }, [activePaginationQuery]);

  useEffect(() => {
    if (!isDetail) {
      getWaitingHomeworks(undefined, waitingPaginationQuery);
    } else {
      getWaitingHomeworksDetail(undefined, waitingPaginationQuery);
    }
  }, [waitingPaginationQuery]);

  useEffect(() => {
    if (!isDetail) {
      getExpiredHomeworks(undefined, expiredPaginationQuery);
    } else {
      getExpiredHomeworksDetail(undefined, expiredPaginationQuery);
    }
  }, [expiredPaginationQuery]);

  const homeworkDrawerOnClose = () => {
    onRenderColumn();
    setHomeworkDrawerOpen(false);
  };

  const draftDrawerOnClose = () => {
    setDraftDrawerOpen(false);
  };

  const onRenderColumn = () => {
    if (!isDetail) {
      getActiveHomeworks(undefined, activePaginationQuery);
      getWaitingHomeworks(undefined, waitingPaginationQuery);
      getExpiredHomeworks(undefined, expiredPaginationQuery);
    } else {
      getActiveHomeworksDetail(undefined, activePaginationQuery);
      getWaitingHomeworksDetail(undefined, waitingPaginationQuery);
      getExpiredHomeworksDetail(undefined, expiredPaginationQuery);
    }
  };

  const onFilter = (key: any, value?: any, reset: boolean = false) => {
    if (reset) {
      setActivePaginationQuery({ ...activePaginationQuery, page: 1, name: '', levelIds: [] });
      setWaitingPaginationQuery({ ...waitingPaginationQuery, page: 1, name: '', levelIds: [] });
      setExpiredPaginationQuery({ ...expiredPaginationQuery, page: 1, name: '', levelIds: [] });
      return;
    }
    setActivePaginationQuery((prev) => ({
      ...prev,
      [key]: value,
    }));
    setWaitingPaginationQuery((prev) => ({
      ...prev,
      [key]: value,
    }));
    setExpiredPaginationQuery((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onDeleteHomework = async (homework) => {
    const response = await API.HOMEWORK.deleteHomeworkTeacher(homework.id);
    if (response.ok) {
      notification.success({ message: 'Başarıyla silindi' });
      onRenderColumn();
    }
  };

  return (
    <div className="container mx-auto pt-1">
      <div className="flex justify-between">
        <div>
          <TeacherClassFilter onFilter={onFilter} filters={activePaginationQuery} />
        </div>
        <Button type="link" icon={<BookOutlined />} onClick={() => setDraftDrawerOpen(true)}>
          Taslakları görüntüle
        </Button>
      </div>

      <div className="grid grid-cols-3 gap-3 mt-2">
        <KanbanColumn
          title={
            <KanbanColumnTitle
              title="Aktif"
              count={activeHomeworks ? activeHomeworks?.count : activeHomeworksDetail?.count}
              icon={<FileTextOutlined style={{ color: '#52C41A' }} />}
            />
          }
          isLoading={isActiveHomeworksLoading || isActiveHomeworksDetailLoading}
          cardsStyle={{ height: 'calc(100vh - 210px)' }}
          loadMore={
            <div>
              {((activeHomeworks && activePaginationQuery.limit! < activeHomeworks?.count!) ||
                (activeHomeworksDetail && activePaginationQuery.limit! < activeHomeworksDetail?.count!)) && (
                <div className="flex justify-center items-center mt-2">
                  <Button onClick={() => setActivePaginationQuery((prev) => ({ ...prev, limit: prev.limit! + 3 }))} type="link" icon={<SyncOutlined />}>
                    Daha fazla yükle
                  </Button>
                </div>
              )}
            </div>
          }
          cards={(activeHomeworks?.data || activeHomeworksDetail?.data)?.map((homework) => {
            return {
              title: homework.name,
              content: <TaskCardContent homework={homework} />,
              action: (
                <ActionDropdown
                  isViewAssignment={true}
                  onViewAssignment={() => navigate('/' + LayoutEnums.TEACHER + '/homework-detail' + location.search + '&class_homework_id=' + homework.id)}
                  isEditAssignment={true}
                  onEditAssignment={() => {
                    setHomeworkData(homework);
                    setHomeworkDrawerOpen(true);
                  }}
                  isDeleteAssignment={true}
                  onDeleteAssignment={() => onDeleteHomework(homework)}
                  isDelete={false}
                  isEdit={false}
                  data={{}}
                />
              ),
              footer: (
                <div className="flex justify-between">
                  <div>
                    <CalendarOutlined />
                    <span className="ml-1">
                      {convertDate(homework.startDate)} - {convertDate(homework.dueDate)}
                    </span>
                  </div>
                  <div>
                    <PaperClipOutlined />
                    <span className="ml-2">{homework.files?.length || 0}</span>
                  </div>
                </div>
              ),
            };
          })}
        ></KanbanColumn>

        <KanbanColumn
          cardsStyle={{ height: 'calc(100vh - 210px)' }}
          title={<KanbanColumnTitle title="Planlanmış" count={(waitingHomeworks || waitingHomeworksDetail)?.count} icon={<FileDoneOutlined style={{ color: '#faad14' }} />} />}
          isLoading={isWaitingHomeworksLoading || isActiveHomeworksDetailLoading}
          loadMore={
            <div>
              {((waitingHomeworks && waitingPaginationQuery.limit! < waitingHomeworks?.count!) ||
                (waitingHomeworksDetail && waitingPaginationQuery.limit! < waitingHomeworksDetail?.count!)) && (
                <div className="flex justify-center items-center mt-2">
                  <Button onClick={() => setWaitingPaginationQuery((prev) => ({ ...prev, limit: prev.limit! + 3 }))} type="link" icon={<SyncOutlined />}>
                    Daha fazla yükle
                  </Button>
                </div>
              )}
            </div>
          }
          cards={(waitingHomeworks?.data || waitingHomeworksDetail?.data)?.map((homework) => {
            return {
              title: homework.name,
              content: <TaskCardContent homework={homework} />,
              action: (
                <ActionDropdown
                  isViewAssignment={true}
                  onViewAssignment={() => navigate('/' + LayoutEnums.TEACHER + '/homework-detail' + location.search + '&class_homework_id=' + homework.id)}
                  isEditAssignment={true}
                  onEditAssignment={() => {
                    setHomeworkData(homework);
                    setHomeworkDrawerOpen(true);
                  }}
                  isDeleteAssignment={true}
                  onDeleteAssignment={() => onDeleteHomework(homework)}
                  isDelete={false}
                  isEdit={false}
                  data={{}}
                />
              ),
              footer: (
                <div className="flex justify-between">
                  <div>
                    <CalendarOutlined />
                    <span className="ml-1">
                      {convertDate(homework.startDate)} - {convertDate(homework.dueDate)}
                    </span>
                  </div>
                  <div>
                    <PaperClipOutlined />
                    <span className="ml-2">{homework.files?.length || 0}</span>
                  </div>
                </div>
              ),
            };
          })}
        ></KanbanColumn>

        <KanbanColumn
          cardsStyle={{ height: 'calc(100vh - 210px)' }}
          title={<KanbanColumnTitle title="Tamamlandı" count={(expiredHomeworks || expiredHomeworksDetail)?.count} icon={<FileDoneOutlined style={{ color: '#1677FF' }} />} />}
          isLoading={isExpiredHomeworksLoading || isExpiredHomeworksDetailLoading}
          loadMore={
            <div>
              {((expiredHomeworks && expiredPaginationQuery.limit! < expiredHomeworks?.count!) ||
                (expiredHomeworksDetail && expiredPaginationQuery.limit! < expiredHomeworksDetail?.count!)) && (
                <div className="flex justify-center items-center mt-2">
                  <Button onClick={() => setExpiredPaginationQuery((prev) => ({ ...prev, limit: prev.limit! + 3 }))} type="link" icon={<SyncOutlined />}>
                    Daha fazla yükle
                  </Button>
                </div>
              )}
            </div>
          }
          cards={(expiredHomeworks?.data || expiredHomeworksDetail?.data)?.map((homework) => {
            return {
              title: homework.name,
              content: <TaskCardContent homework={homework} />,
              action: (
                <ActionDropdown
                  isViewAssignment={true}
                  onViewAssignment={() => navigate('/' + LayoutEnums.TEACHER + '/homework-detail' + location.search + '&class_homework_id=' + homework.id)}
                  isEditAssignment={true}
                  onEditAssignment={() => {
                    setHomeworkData(homework);
                    setHomeworkDrawerOpen(true);
                  }}
                  isDeleteAssignment={true}
                  onDeleteAssignment={() => onDeleteHomework(homework)}
                  isDelete={false}
                  isEdit={false}
                  data={{}}
                />
              ),
              footer: (
                <div className="flex justify-between">
                  <div>
                    <CalendarOutlined />
                    <span className="ml-1">
                      {convertDate(homework.startDate)} - {convertDate(homework.dueDate)}
                    </span>
                  </div>
                  <div>
                    <PaperClipOutlined />
                    <span className="ml-2">{homework.files?.length || 0}</span>
                  </div>
                </div>
              ),
            };
          })}
        ></KanbanColumn>
      </div>
      <DraftViewDrawer draftDrawerOpen={draftDrawerOpen} draftDrawerOnClose={draftDrawerOnClose} onRenderColumn={onRenderColumn} />
      <HomeworkEditDrawer homeworkDrawerOpen={homeworkDrawerOpen} homeworkDrawerOnClose={homeworkDrawerOnClose} homeworkData={homeworkData!} />
    </div>
  );
};

TeacherClassHomeWork.roles = [Role.ADMIN];
TeacherClassHomeWork.path = '/' + LayoutEnums.TEACHER + '/class-homework';
TeacherClassHomeWork.title = 'Ödev İşlemleri';
TeacherClassHomeWork.group = 'flat';
TeacherClassHomeWork.layouts = [LayoutEnums.TEACHER];
TeacherClassHomeWork.icon = <IoDocumentTextOutline size={'15'} />;
