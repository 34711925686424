import { ApartmentOutlined, CloseCircleOutlined, GlobalOutlined, PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ActionDropdown } from '@shared/components';
import { LayoutEnums, ProductPermissionValues, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import i18n from '@shared/localization/i18n';
import { Enrollment, EnrollmentStatus, EnrollmentTypes, PaginationQuery } from '@shared/models';
import { convertDate, sortFields } from '@shared/utils';
import { Button, Dropdown, Input, InputRef, MenuProps, Popconfirm, Table, TablePaginationConfig, TableProps } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { TbFilter } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom';

export const Enrollments = () => {
  useAccessableSeachParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [enrollments, loading, getAllEnrollment] = API.ENROLLMENT.getAllEnrollment({ init: false });
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 10, orders: ['-createdAt'] });
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    getAllEnrollment(undefined, paginationQuery);
  }, [paginationQuery]);

  const title = (
    <div className="flex justify-between">
      <div className="font-bold"></div>
      <Dropdown
        menu={{
          items: [
            {
              key: '1',
              icon: <UserOutlined />,
              label: 'Yeni Bireysel Kayıt Oluştur',
              onClick: () => navigate('/crm/create-individual-enrollment-request' + location.search),
            },
            { key: '2', icon: <ApartmentOutlined />, label: 'Yeni Grup Kaydı Oluştur', onClick: () => navigate('/crm/create-group-enrollment-request' + location.search) },
            {
              key: '3',
              icon: <GlobalOutlined />,
              label: 'Yeni Firma Kaydı Oluştur',
              onClick: () => navigate('/crm/create-enterprise-enrollment-request' + location.search),
            },
          ],
        }}
      >
        <Button icon={<PlusCircleOutlined />} type="dashed">
          Yeni Kayıt Oluştur
        </Button>
      </Dropdown>
    </div>
  );

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder="Filtrele"
          value={selectedKeys}
          onChange={(e) => setSelectedKeys(e.target.value)}
          onPressEnter={() => confirm()}
          className="block mb-2"
        />
        <div className="flex justify-around gap-3">
          <Button type="primary" onClick={() => confirm()} icon={<TbFilter size={15} />} size="small">
            Filtrele
          </Button>

          <Button
            size="small"
            onClick={() => {
              close();
            }}
          >
            Kapat
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => <TbFilter size={15} style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const enrollmentActionItems = (id: string, status: string) => {
    const items: MenuProps['items'] = [];
    if (status !== EnrollmentStatus.CANCELED && status !== EnrollmentStatus.INFO && status !== EnrollmentStatus.DONE) {
      items.push({
        key: 'cancel-enrollment',
        label: (
          <Popconfirm
            title="Kaydı iptal etmek istediğinize emin misiniz?"
            onConfirm={async () => {
              const result = await API.ENROLLMENT.cancelPrePlacement(id);
              result.ok && getAllEnrollment(undefined, paginationQuery);
            }}
            okText="Evet"
            cancelText="İptal"
          >
            <div className="flex items-center">
              <CloseCircleOutlined className=" mr-2" />
              Kaydı İptal Et
            </div>
          </Popconfirm>
        ),
      });
    }

    return items;
  };

  const columns: TableProps<Enrollment>['columns'] = [
    {
      title: 'Kayıt Tarihi',
      dataIndex: 'createdAt',
      align: 'center',
      sorter: { multiple: 1 },
      defaultSortOrder: 'descend',
      render: (_, { createdAt }) => <span>{convertDate(createdAt)}</span>,
    },
    {
      title: 'Kayıt No',
      dataIndex: 'no',
      align: 'center',
      ...getColumnSearchProps('no'),
    },
    {
      title: 'Kayıt Adı',
      dataIndex: 'name',
      align: 'center',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Tipi',
      dataIndex: 'type',
      align: 'center',
      filterIcon: <TbFilter size="15" />,
      filters: Object.values(EnrollmentTypes).map((type) => ({ text: i18n.t('app.enrollmentType.' + type), value: type })),
      render: (_, { type }) => <span>{i18n.t('app.enrollmentType.' + type)}</span>,
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      align: 'center',
      filterIcon: <TbFilter size="15" />,
      filters: Object.values(EnrollmentStatus).map((status) => ({ text: i18n.t('app.enrollmentStatus.' + status), value: status })),
      render: (_, { status }) => <span>{i18n.t('app.enrollmentStatus.' + status)}</span>,
    },
    {
      title: 'Aksiyon',
      align: 'left',
      render: (_, record) => (
        <div className="text-center">
          <ActionDropdown
            items={enrollmentActionItems(record.id!, record.status!)}
            // şimdilik bu şekilde. //isEdit={[EnrollmentStatus.INFO, EnrollmentStatus.CANCELED].includes(record.status!)}
            isDelete={record.status == EnrollmentStatus.INFO ? true : false}
            onDelete={async ({ id }) => {
              const result = await API.ENROLLMENT.delete(id);
              result.ok && setPaginationQuery((prev) => ({ ...prev, page: 1 }));
            }}
            data={record}
            onEdit={({ id, type }) => {
              if (type == EnrollmentTypes.INDIVIDUAL) {
                navigate('/crm/create-individual-enrollment-request' + location.search + '&enrollment=' + id);
              }
              if (type == EnrollmentTypes.GROUP) {
                navigate('/crm/create-group-enrollment-request' + location.search + '&enrollment=' + id);
              }
              if (type == EnrollmentTypes.ENTERPRISE) {
                navigate('/crm/create-enterprise-enrollment-request' + location.search + '&enrollment=' + id);
              }
            }}
          />
        </div>
      ),
    },
  ];

  const onTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter) => {
    const { current } = pagination;
    const newQuery = { ...paginationQuery, page: current, limit: pagination.pageSize, orders: sortFields(paginationQuery, sorter as SorterResult<any>), ...filters };
    Object.keys(newQuery).forEach((key) => newQuery[key] == null && delete newQuery[key]); // Null olan filtreleri çıkartıyoruz.
    setPaginationQuery(newQuery);
  };

  return (
    <>
      <Table
        loading={loading}
        rowKey="id"
        pagination={{
          showSizeChanger: true,
          showPrevNextJumpers: true,
          current: paginationQuery.page,
          pageSize: paginationQuery.limit,
          total: enrollments?.count,
          position: ['bottomCenter'],
        }}
        columns={columns}
        dataSource={enrollments?.data}
        bordered
        title={() => title}
        onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter)}
      />
    </>
  );
};

Enrollments.path = '/crm/enrollments';
Enrollments.title = 'Tanımlı Kayıtlar';
Enrollments.group = 'temp-record';
Enrollments.roles = [Role.ADMIN];
Enrollments.layouts = [LayoutEnums.BRANCHMANAGER, LayoutEnums.CRM];
Enrollments.permissions = [ProductPermissionValues.branch.LMS.Enrollment.VIEW];
