import { CheckCircleTwoTone, CloseCircleFilled } from '@ant-design/icons';
import * as API from '@shared/api';
import { RESULT } from '@shared/constants';
import { useForm } from '@shared/hooks';
import { ResetPasswordModel } from '@shared/models';
import { Button, Card, Divider, Form, Image, Input, Result } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

//TODO İki zod değeri birbirine denk mi diye refine ile kontrol etmeye çalıştığımızda useForm hata veriyor.
const ResetPasswordFormZod = z.object({
  password: z.string({ required_error: 'Parola Zorunludur' }).min(1, 'Parola Zorunludur'),
  passwordRepeat: z.string({ required_error: 'Parolayi Tekrar Giriniz' }).min(1, 'Parolayi Tekrar Giriniz'),
});

export const ResetPassword = () => {
  const { token } = useParams();
  const form = useForm<ResetPasswordModel>(ResetPasswordFormZod, {});
  const [resetPasswordResult, setResetPasswordResult] = useState<string>(RESULT.PENDING);
  const navigate = useNavigate();

  const onSubmit = async () => {
    const result = form.parse();
    if (result.success) {
      const query: ResetPasswordModel = { token, password: result.data['password'], passwordRepeat: result.data['passwordRepeat'] };
      const response = await API.AUTH.resetPassword(query);
      if (response.ok) {
        setResetPasswordResult(RESULT.SUCCESS);
        setTimeout(() => {
          navigate('/');
        }, 5000);
      } else {
        setResetPasswordResult(RESULT.FAILED);
      }
    } else {
      form.setAllTouched();
    }
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-slate-300">
      <Card className="flex justify-center items-center h-screen" style={{ height: '650px', width: '600px' }}>
        <div className="flex justify-center items-center">
          <Image preview={false} src={require('../../../statics/americanlife.png')} />
        </div>
        {resetPasswordResult === RESULT.PENDING && (
          <div
            className="flex flex-col gap-0.5 mt-4"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                onSubmit();
              }
            }}
          >
            <div className="text-center flex flex-col justify-center items-center"></div>
            <h4>Yeni Parola Oluştur</h4>
            <Form layout="vertical">
              <Form.Item {...form.formItem('password')}>
                <Input.Password placeholder="Yeni parola oluştur" {...form.input('password')} />
              </Form.Item>
              <Form.Item {...form.formItem('passwordRepeat')}>
                <Input.Password placeholder="Parolayı doğrula" {...form.input('passwordRepeat')} />
              </Form.Item>
              <Button block type="primary" className="my-3" onClick={onSubmit}>
                Parolanı değiştir
              </Button>
            </Form>
          </div>
        )}

        {resetPasswordResult === RESULT.SUCCESS && (
          <div className="h-64">
            <Result
              className="p-0"
              icon={<CheckCircleTwoTone twoToneColor="#1677FF" style={{ fontSize: '44px', color: '#1677FF' }} />}
              status="success"
              subTitle={<span className="italic text-blue-500">Parolanız oluşturuldu. Giriş sayfasına yönlendiriliyorsunuz..</span>}
            ></Result>
          </div>
        )}

        {resetPasswordResult === RESULT.FAILED && (
          <div className="h-64 mt-5">
            <Result
              className="p-0"
              icon={<CloseCircleFilled style={{ fontSize: '30px' }} />}
              status="error"
              subTitle={<span className="italic text-red-500">Token süresi dolmuştur.</span>}
              extra={[
                <Button key="button" onClick={() => navigate('/account/forgot-password')} type="default">
                  Yeni sıfırlama linki gönder
                </Button>,
              ]}
            ></Result>
            <Divider className="w-96">
              <span className="italic font-light">ya da</span>
            </Divider>
            <div className="flex justify-center items-center mb-5">
              <Button type="link" onClick={() => navigate('/')}>
                Giriş yap
              </Button>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

ResetPassword.path = 'account/reset-password/:token';
