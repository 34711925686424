import { CalendarOutlined, ExclamationCircleTwoTone, ExportOutlined, FileDoneOutlined, ReadOutlined, TeamOutlined } from '@ant-design/icons';
import { ClassFilter, ClassTableInfo } from '@platform/platform_components';
import * as API from '@shared/api';
import { Spinner } from '@shared/components';
import { ClassStatus, LayoutEnums, Role } from '@shared/constants';
import { useUserContext } from '@shared/contexts';
import { useAccessableSeachParams } from '@shared/hooks';
import { ClassModel, InClassInfo, PaginationQuery } from '@shared/models';
import { convertDate, renderLevelsAsString } from '@shared/utils';
import { Alert, Badge, Button, Card, Empty, Pagination, Select, Space, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { useCallback, useEffect, useState } from 'react';
import { BiMedal } from 'react-icons/bi';
import { PiChalkboardTeacherLight } from 'react-icons/pi';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

enum ClassOwner {
  TEACHER_CLASSES = 'teacherClass',
  ALL_CLASSES = 'allClasses',
}

export const TeacherClass = () => {
  useAccessableSeachParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [classList, classListLoading, getClassList] = API.CLASS.getClassList({ init: false });
  const [teacherClassList, teacherClassListLoading, getTeacherClassList] = API.CLASS.getTeacherClassList({ init: false });
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 6, levelIds: [], statuses: [[ClassStatus.STARTED], [ClassStatus.NOT_STARTED]] });
  const [startSeance, setStartSeance] = useState<boolean>(false);
  const [classId, setClassId] = useState<string>();
  const [inClassInfo, setInClassInfo] = useState<InClassInfo>();
  const [classOwner, setClassOwner] = useState<ClassOwner>(ClassOwner.TEACHER_CLASSES);
  const { user } = useUserContext();
  const [classInput, setClassInput] = useState<string>('');

  //Header Aktif ve Planlanmış Sınıf Sayısı için
  const [searchParams, setSeachParams] = useSearchParams();

  useEffect(() => {
    if (classOwner == ClassOwner.TEACHER_CLASSES) {
      getTeacherClassList(undefined, { ...paginationQuery });
    } else {
      getClassList(undefined, { ...paginationQuery });
    }
  }, [classOwner, paginationQuery]);

  useEffect(() => {
    getInClassInfo();
  }, [classId]);

  useEffect(() => {
    const _classId = searchParams.get('class_id');
    if (searchParams.size > 0 && _classId) {
      setClassId(_classId);
      setStartSeance(true);
    }
  }, [searchParams]);

  const getInClassInfo = async () => {
    if (classId) {
      const response = await API.CLASS.getInClassInfo(classId);
      setInClassInfo(response.data);
    }
  };

  const onFilter = (key: any, value?: any, reset: boolean = false) => {
    if (reset) {
      setPaginationQuery({ page: 1, limit: 6, levelIds: [], statuses: [] });
      setClassInput('');
      return;
    }
    setPaginationQuery((prev) => ({
      ...prev,
      [key]: value,
      page: 1,
    }));
  };

  const uniqueTeachers = useCallback(
    (c: ClassModel) => {
      const teacherList: { id: string; name: string; email: string }[] = [];
      if (c) {
        c!.classSeances?.forEach((seance) => {
          seance.classSeanceItems?.forEach((item) => {
            if (item.teacher) {
              teacherList.push({
                id: item?.teacher?.id!,
                name: `${item?.teacher?.name} ${item?.teacher?.surname}`,
                email: `${item?.teacher?.email}`,
              });
            }
          });
        });
      }
      return teacherList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
    },
    [classList]
  );

  const uniqueClassrooms = useCallback(
    (c: ClassModel) => {
      const classroomList: { id: string; name: string }[] = [];
      if (c) {
        c!.classSeances?.forEach((seance) => {
          seance.classSeanceItems?.forEach((item) => {
            if (item.classRoom) {
              classroomList.push({
                id: item?.classRoom?.id!,
                name: item?.classRoom?.name!,
              });
            }
          });
        });
      }
      return classroomList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
    },
    [classList]
  );

  const statusStyles = (status): React.CSSProperties => {
    switch (status) {
      case ClassStatus.CANCELED:
        return { backgroundColor: '#F5F5F5', color: '#00000040' };
      case ClassStatus.COMPLETED:
        return { backgroundColor: '#E6F4FF', color: '#1677FF' };
      case ClassStatus.STARTED:
        return { backgroundColor: '#F6FFED', color: '#52C41A', borderBottomColor: '#B7EB8F' };
      case ClassStatus.NOT_STARTED:
        return { backgroundColor: '#FFFBE6', color: '#FAAD14', borderBottomColor: '#FFE58F' };
      default:
        return {};
    }
  };

  //Öğretmen kendi sınıflarını görmek istiyorsa teacherClassList, yoksa tüm sınıflar olan classList
  const classData = classOwner == ClassOwner.ALL_CLASSES ? classList : teacherClassList;

  return (
    <div>
      {!startSeance ? (
        <>
          <Spinner loading={classListLoading || teacherClassListLoading} />

          <div className="flex flex-row items-center space-x-2 mt-2">
            <Select
              style={{ width: '220px' }}
              defaultValue={'Öğretmeni Olduğum Sınıflar'}
              onChange={(value) => setClassOwner(value as ClassOwner)}
              options={[
                {
                  value: ClassOwner.TEACHER_CLASSES,
                  label: 'Öğretmeni Olduğum Sınıflar',
                },
                {
                  value: ClassOwner.ALL_CLASSES,
                  label: 'Şubedeki Tüm Sınıflar',
                },
              ]}
            />
            <div className="flex flex-1">
              <Search
                value={classInput}
                onChange={(e) => setClassInput(e.target.value)}
                placeholder="Sınıf Adı ya da Öğretmen Adı ile ara..."
                onSearch={(e) => {
                  setPaginationQuery({ ...paginationQuery, page: 1, limit: 6, name: e });
                }}
                enterButton
              />
            </div>
            <div className="ml-2 flex-2">
              <ClassFilter onFilter={onFilter} filters={paginationQuery} />
            </div>
          </div>

          <ClassTableInfo />
          {classData?.data?.length === 0 ? (
            <Empty className="mt-10" description="Sınıf mevcut değil" />
          ) : (
            <>
              <div className="grid grid-cols-3 gap-3 mt-5">
                {classData?.data?.map((c) => {
                  const teacherIds = uniqueTeachers(c).map((teacher) => teacher.email);
                  const isDifferentTeacher = !teacherIds.includes(user?.email!);

                  return (
                    <Space key={c.id} direction="vertical" size="middle" style={{ width: '100%' }}>
                      {isDifferentTeacher && (
                        <Badge.Ribbon className="mt-[100px]" text={'Size ait bir sınıf değil'}>
                          <Card
                            title={
                              <Tooltip title={` ${c.name}`}>
                                <span>{` ${c.name}`}</span>
                              </Tooltip>
                            }
                            extra={
                              <>
                                {(c.status === 'STARTED' || c.status === 'NOT_STARTED') && (
                                  <Tooltip className="mr-2" placement="top" title={'Seansı başlat'}>
                                    <ReadOutlined
                                      onClick={() => {
                                        setStartSeance(true);
                                        setClassId(c.id);
                                      }}
                                      style={{ color: statusStyles(c.status).color }}
                                      className="text-base"
                                    />
                                  </Tooltip>
                                )}
                                <Tooltip className="mr-2" placement="top" title={'Sınıf detayına git'}>
                                  <ExportOutlined
                                    className="cursor-pointer ml-1"
                                    style={{ color: statusStyles(c.status).color }}
                                    onClick={() => navigate('/' + LayoutEnums.TEACHER + '/class_detail' + location.search + '&class_id=' + c.id)}
                                  />
                                </Tooltip>
                              </>
                            }
                            styles={{ header: { ...statusStyles(c.status), fontWeight: 'normal' } }}
                            size="small"
                            className="rounded-xl"
                          >
                            <div className="flex flex-col gap-y-2">
                              <div className="font-bold flex items-center">
                                <BiMedal className="text-gray-500 mr-2" />
                                {renderLevelsAsString(c.level)}
                              </div>
                              <div>
                                <CalendarOutlined className="text-gray-500 mr-2" />
                                {convertDate(c.calculatedStartDate) + ' - ' + convertDate(c.calculatedEndDate)}
                              </div>
                              <div>
                                <TeamOutlined className="text-gray-500 mr-1" /> {c.studentCount}
                              </div>
                              <div>
                                <ReadOutlined className="text-gray-500 mr-2" />
                                {uniqueClassrooms(c)
                                  .map((classroom) => classroom.name)
                                  .join(', ')}
                              </div>
                              <div className="flex items-center">
                                <PiChalkboardTeacherLight className="text-gray-500 mr-2" />
                                {uniqueTeachers(c)
                                  .map((teacher) => teacher.name)
                                  .join(', ')}
                              </div>
                            </div>
                          </Card>
                        </Badge.Ribbon>
                      )}
                      {!isDifferentTeacher && (
                        <Card
                          title={`${c?.name} `}
                          extra={
                            <>
                              {(c.status === 'STARTED' || c.status === 'NOT_STARTED') && (
                                <Tooltip className="mr-2" placement="top" title={'Seansı başlat'}>
                                  <ReadOutlined
                                    onClick={() => {
                                      setStartSeance(true);
                                      setClassId(c.id);
                                    }}
                                    style={{ color: statusStyles(c.status).color }}
                                    className="text-base"
                                  />
                                </Tooltip>
                              )}
                              <Tooltip className="mr-2" placement="top" title={'Sınıf detayına git'}>
                                <ExportOutlined
                                  className="cursor-pointer ml-1"
                                  style={{ color: statusStyles(c.status).color }}
                                  onClick={() => navigate('/' + LayoutEnums.TEACHER + '/class_detail' + location.search + '&class_id=' + c.id)}
                                />
                              </Tooltip>
                            </>
                          }
                          styles={{ header: { ...statusStyles(c.status), fontWeight: 'normal' } }}
                          size="small"
                          className="rounded-xl"
                        >
                          <div className="flex flex-col gap-y-2">
                            <div className="font-bold flex items-center">
                              <BiMedal className="text-gray-500 mr-2" />
                              {renderLevelsAsString(c.level)}
                            </div>
                            <div>
                              <CalendarOutlined className="text-gray-500 mr-2" />
                              {convertDate(c.calculatedStartDate) + ' - ' + convertDate(c.calculatedEndDate)}
                            </div>
                            <div>
                              <TeamOutlined className="text-gray-500 mr-2" /> <span>{c.studentCount} </span>
                            </div>
                            <div>
                              <ReadOutlined className="text-gray-500 mr-2" />
                              {uniqueClassrooms(c)
                                .map((classroom) => classroom.name)
                                .join(', ')}
                            </div>
                            <div className="flex items-center">
                              <PiChalkboardTeacherLight className="text-gray-500 mr-2" />
                              {uniqueTeachers(c)
                                .map((teacher) => teacher.name)
                                .join(', ')}
                            </div>
                          </div>
                        </Card>
                      )}
                    </Space>
                  );
                })}
              </div>
              <Pagination
                current={paginationQuery.page}
                pageSize={paginationQuery.limit}
                total={classData?.count ?? 0}
                className="mt-5 text-center"
                onChange={(page) => setPaginationQuery((prev) => ({ ...prev, page }))}
              />
            </>
          )}
        </>
      ) : (
        <div>
          <div className="flex items-center justify-center text-center" style={{ height: '55vh' }}>
            <div className="space-y-4">
              <div className="text-2xl">
                <ExclamationCircleTwoTone twoToneColor="#FAAD14" style={{ fontSize: '150%' }} />
              </div>
              <div className="text-lg italic" style={{ color: '#FAAD14' }}>
                Seansı başlat
              </div>

              {inClassInfo?.classSeanceItem ? (
                <div className="text-base">
                  <b>{inClassInfo?.className}</b> sınıfına ait <b>{inClassInfo?.classSeanceItem?.title}</b> seansını başlatmak istiyor musunuz?
                </div>
              ) : (
                <div className="text-base">
                  Şuan <b> {inClassInfo?.className}</b> sınıfına ait seans bulunmuyor
                </div>
              )}

              {inClassInfo?.classSeanceItem &&
                (inClassInfo?.classSeanceItem?.teacher?.email === user.email ? (
                  <></>
                ) : (
                  <Alert
                    type="warning"
                    showIcon
                    message={
                      <div>
                        Bu seans bir başka öğretmene ait (<b>{inClassInfo?.classSeanceItem?.teacher?.name + ' ' + inClassInfo?.classSeanceItem?.teacher?.surname}</b>). Vekil olarak
                        bu seansa girmek için <b>Seansı Başlat</b> butonuna tıklayınız
                      </div>
                    }
                  />
                ))}

              <div className="space-x-3">
                {inClassInfo?.classSeanceItem && (
                  <Button type="primary" onClick={() => navigate('/' + LayoutEnums.TEACHER + '/lesson-content' + location.search + '&class_id=' + classId)}>
                    Seansı başlat
                  </Button>
                )}

                <Button onClick={() => setStartSeance(false)}>Vazgeç</Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

TeacherClass.path = '/' + LayoutEnums.TEACHER + '/class';
TeacherClass.title = 'Sınıflarım';
TeacherClass.group = 'flat';
TeacherClass.roles = [Role.ADMIN];
TeacherClass.layouts = [LayoutEnums.TEACHER];
TeacherClass.icon = <FileDoneOutlined size={20} />;
