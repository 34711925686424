import { PlusCircleOutlined, TeamOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { BrandAndBranchUserCard, BrandAndBranchUserForm, Spinner } from '@shared/components';
import { BrandOrBranch, LayoutEnums, ProductPermissionValues, Role } from '@shared/constants';
import { useAccessableContext } from '@shared/contexts';
import { useAccessableSeachParams } from '@shared/hooks';
import { PaginationQuery } from '@shared/models';
import { Button, Drawer, notification, Pagination } from 'antd';
import { useEffect, useState } from 'react';
import { FiUsers } from 'react-icons/fi';
import Search from 'antd/lib/input/Search';

export const BranchUsers = () => {
  useAccessableSeachParams();
  const { branch } = useAccessableContext();
  const [users, usersLoading, getBranchUsers] = API.BRANCH.getBranchUsers({ init: false });
  const [enableBrandProduct, brandProductLoading, getEnableBrandProduct] = API.PRODUCT.getEnableBranchProduct({ init: true });
  const [open, setOpen] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [enableProductTypes, setEnableProductTypes] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 8 });
  const [input, setInput] = useState<string>('');

  useEffect(() => {
    if (enableBrandProduct) {
      const list = enableBrandProduct!.map((product) => {
        return product.type;
      }) as string[];
      setEnableProductTypes(list);
    }
  }, [enableBrandProduct]);

  useEffect(() => {
    getBranchUsers(undefined, paginationQuery);
  }, [paginationQuery]);

  const showDrawer = () => {
    setOpen(true);
    setIsUpdateMode(false);
  };
  const closeDrawer = () => {
    setOpen(false);
    setSelectedUser({});
    setIsUpdateMode(false);
  };

  return (
    <div>
      <Spinner loading={usersLoading && brandProductLoading} />
      <div className="flex justify-between">
        <div>
          <Search
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Şube kullanıcısı ara..."
            onSearch={(e) => {
              setPaginationQuery({ ...paginationQuery, page: 1, name: e });
            }}
            enterButton
          />
        </div>
        <Button type="dashed" icon={<PlusCircleOutlined />} onClick={showDrawer}>
          Şube kullanıcısı ekle
        </Button>
      </div>
      <Drawer title={isUpdateMode ? 'Şube Kullanıcısı Güncelle' : 'Şube Kullanıcısı Ekle'} onClose={closeDrawer} open={open}>
        <BrandAndBranchUserForm
          user={selectedUser}
          enableProductTypes={enableProductTypes}
          brandOrBranch={BrandOrBranch.BRANCH}
          onSave={async (data) => {
            if (isUpdateMode) {
              const result = await API.BRANCH.updateBranchUser(data);
              if (result.ok) {
                notification.success({ message: 'Şube kullanıcısı güncellendi.' });
              }
            } else {
              const result = await API.BRANCH.createBranchUser(data);
              if (result.ok) {
                notification.success({ message: 'Şube kullanıcısı oluşturuldu.' });
              }
            }
            closeDrawer();
            getBranchUsers(undefined, paginationQuery);
            getEnableBrandProduct();
          }}
        />
      </Drawer>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 gap-x-4 gap-y-2 mt-2">
        {users?.data?.map((user) => (
          <BrandAndBranchUserCard
            key={user.id}
            user={user}
            onDelete={async ({ id }) => {
              await API.BRANCH.deleteBranchUser(id!);
              notification.success({ message: 'Şube kullanıcısı başarıyla silinmiştir!' });
              getBranchUsers(undefined, { ...paginationQuery, page: 1 });
            }}
            onEdit={(user) => {
              setOpen(true);
              setIsUpdateMode(true);
              setSelectedUser(user);
            }}
          />
        ))}
      </div>
      <Pagination pageSize={users?.limit} total={users?.count} className="mt-5 text-center" onChange={(page) => setPaginationQuery((prev) => ({ ...prev, page }))} />
    </div>
  );
};

BranchUsers.path = '/branch_manager/users';
BranchUsers.title = 'Şube Kullanıcıları';
BranchUsers.group = 'flat';
BranchUsers.roles = [Role.ADMIN];
BranchUsers.layouts = [LayoutEnums.BRANCHMANAGER];
BranchUsers.icon = <FiUsers />;
BranchUsers.permissions = [ProductPermissionValues.branch.User.VIEW];
