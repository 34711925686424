import { CheckCircleOutlined, CloseCircleOutlined, CopyOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { CreateBranchCreditDrawer } from '@platform/platform_components';
import * as API from '@shared/api';
import { ActionDropdown, Spinner } from '@shared/components';
import { LayoutEnums, ProductPermissionValues, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import i18n from '@shared/localization/i18n';
import { BranchLmsCreditOrder, LmsCreditOrderStatus, PaginationQuery } from '@shared/models';
import { convertDate, sortFields } from '@shared/utils';
import { Button, notification, Table, Tag, Tooltip } from 'antd';
import { TableProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import { TbFilter } from 'react-icons/tb';

export const BranchCredit = () => {
  useAccessableSeachParams();
  const [open, setOpen] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [seledtedData, setSeledtedData] = useState<BranchLmsCreditOrder>();
  const [selectedBranchLmsCreditOrder, setSelectedBranchLmsCreditOrder] = useState<BranchLmsCreditOrder | undefined>();
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 10, withDeleted: false, orders: ['-createdAt'] });
  const [currentBalance, isCurrentBalanceLoading, getCurrentBalance] = API.LMS_CREDIT.retriveCurrentBranchLmsCreditBalance({ init: true });

  const [branchCreditOrders, loading, getBranchCreditOrders] = API.LMS_CREDIT.paginateBranchLmsCreditOrder({ init: false });

  const showDrawer = () => {
    setOpen(true);
    setIsUpdateMode(false);
  };
  const closeDrawer = () => {
    setOpen(false);
    setSelectedBranchLmsCreditOrder(undefined);
    setIsUpdateMode(false);
  };

  useEffect(() => {
    getBranchCreditOrders(undefined, paginationQuery);
  }, [paginationQuery]);

  const saveBranchLmsCreditOrder = async (formValue) => {
    if (isUpdateMode) {
      const response = await API.LMS_CREDIT.updateBranchLmsCreditOrder(selectedBranchLmsCreditOrder?.id!, formValue as any);
      if (response.ok) {
        notification.success({ message: 'Kredi Talebi Güncellendi' });
        getBranchCreditOrders(undefined, paginationQuery);
        closeDrawer();
      }
    } else {
      const response = await API.LMS_CREDIT.createBranchLmsCreditOrder(formValue as any);
      if (response.ok) {
        notification.success({ message: 'Kredi Talebi Oluşturuldu' });
        getBranchCreditOrders(undefined, paginationQuery);
        closeDrawer();
      }
    }
  };

  const title = (
    <div className="flex justify-between">
      <div className="font-bold">
        <h3>Mevcut Bakiyeniz: {currentBalance}</h3>
      </div>
      <Button icon={<PlusCircleOutlined />} onClick={() => showDrawer()} type="dashed">
        Yeni Lms Kredi Talebi Oluştur
      </Button>
    </div>
  );

  const statusMap = {
    [LmsCreditOrderStatus.APPROVED]: <Tag color="green">Onaylandı</Tag>,
    [LmsCreditOrderStatus.REJECTED]: <Tag color="red">Reddedildi</Tag>,
    [LmsCreditOrderStatus.ORDERED]: <Tag color="orange">Siparişte</Tag>,
  };

  const columns: TableProps<BranchLmsCreditOrder>['columns'] = [
    {
      title: 'Durum',
      dataIndex: 'status',
      align: 'center',
      sorter: { multiple: 1 },
      filterIcon: <TbFilter size="15" />,
      filters: Object.values(LmsCreditOrderStatus).map((type) => ({ text: i18n.t('app.lmsCreditOrderStatus.' + type), value: type })),
      render: (_, { status }) => statusMap[status],
    },

    {
      title: 'Ödendi mi?',
      key: 'paid',
      align: 'center',
      dataIndex: 'paid',
      filterMultiple: false,
      sorter: { multiple: 2 },
      filterIcon: <TbFilter size="15" />,
      filters: [
        { text: 'Ödendi', value: true },
        { text: 'Ödenmedi', value: false },
      ],
      onFilter: (value, record) => record.paid === value,
      render: (_, record) =>
        record.paid ? (
          <Tag color="green">
            <CheckCircleOutlined className="mr-1" />
            Ödendi
          </Tag>
        ) : (
          <Tag color="red">
            <CloseCircleOutlined className="mr-1" /> Ödenmedi
          </Tag>
        ),
    },
    {
      title: 'No',
      dataIndex: 'no',
      align: 'center',
      render: (_, { no }) => (
        <Tooltip title="Kopyala">
          <Tag
            className="cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(no);
              notification.info({ message: 'Talep Numarası Kopyalandı' });
            }}
          >
            <CopyOutlined className="mr-1" />
            {no}
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: 'Kredi Sayısı',
      dataIndex: 'count',
      align: 'center',
      sorter: true,
    },

    {
      title: 'Talep Tarihi',
      key: 'createdAt',
      align: 'center',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: { multiple: 3 },
      render: (_, { createdAt }) => convertDate(createdAt, 'DD.MM.YYYY HH:mm'),
    },

    {
      title: 'Talep Oluşturan Kişi',
      key: 'createdBy',
      align: 'center',
      render: (_, { createdBy }) => `${createdBy.name}`,
    },

    {
      title: 'Not',
      key: 'note',
      align: 'center',
      render: (_, record) => record.note,
    },

    {
      title: 'Ödendi İşaretleyen Kişi',
      key: 'markedPaidBy',
      align: 'center',
      render: (_, { markedPaidBy }) => (markedPaidBy?.name ? `${markedPaidBy.name} ` : ''),
    },

    {
      title: 'Güncellenme Tarihi',
      key: 'updatedAt',
      align: 'center',
      dataIndex: 'updatedAt',
      render: (_, { updatedAt }) => convertDate(updatedAt, 'DD.MM.YYYY HH:mm'),
    },

    {
      title: 'Onaylama İşlemi Yapan Kişi',
      key: 'proceedBy',
      align: 'center',
      render: (_, { proceedBy }) => (proceedBy?.name ? `${proceedBy.name}` : ''),
    },

    {
      title: 'Aksiyon',
      key: 'action',
      fixed: 'right',
      className: 'text-center',
      render: (_, record) => (
        <div>
          {record.status === LmsCreditOrderStatus.ORDERED && (
            <ActionDropdown
              data={record}
              onEdit={({ id }) => {
                setOpen(true);
                setIsUpdateMode(true);
                setSelectedBranchLmsCreditOrder(record);
                setSeledtedData(record);
              }}
              onDelete={async ({ id }) => {
                const result = await API.LMS_CREDIT.deleteBranchLmsCreditOrder(id);
                if (result.ok) {
                  notification.success({ message: 'Kredi Talebiniz Başarıyla Kaldırıldı' });
                }
                setPaginationQuery((prev) => ({ ...prev, page: 1 }));
              }}
            />
          )}
        </div>
      ),
    },
  ];
  return (
    <div>
      <CreateBranchCreditDrawer
        open={open}
        isUpdateMode={isUpdateMode}
        seledtedData={seledtedData!}
        saveBranchLmsCreditOrder={saveBranchLmsCreditOrder}
        closeDrawer={closeDrawer}
      />
      {!loading ? (
        <Table
          loading={loading}
          rowKey="id"
          scroll={{ x: 'max-content' }}
          pagination={{
            showSizeChanger: true,
            showPrevNextJumpers: true,
            current: paginationQuery.page,
            total: branchCreditOrders?.count,
            pageSize: paginationQuery.limit,
            position: ['bottomCenter'],
          }}
          columns={columns}
          dataSource={branchCreditOrders?.data}
          bordered
          title={() => title}
          onChange={(pagination, filters, sorter) => {
            const { current, pageSize } = pagination;
            let newQuery = {
              ...paginationQuery,
              page: current,
              limit: pageSize,
              orders: sortFields(paginationQuery, sorter as SorterResult<any>),
              ...filters,
            };
            Object.keys(newQuery).forEach((key) => {
              if (key === 'orders') {
                return;
              }
              if (key === 'status') {
                return;
              }
              if (Array.isArray(newQuery[key]) && newQuery[key].length === 1) {
                newQuery[key] = newQuery[key][0];
              }
            });

            setPaginationQuery(newQuery);
          }}
        />
      ) : (
        <Spinner loading={true} />
      )}
    </div>
  );
};

BranchCredit.path = '/lms/branch-credit-order';
BranchCredit.title = 'Kredi Talepleri';
BranchCredit.group = 'branch.lms.credit';
BranchCredit.roles = [Role.ADMIN];
BranchCredit.layouts = [LayoutEnums.BRANCHMANAGER, LayoutEnums.LMS];
BranchCredit.permissions = [ProductPermissionValues.branch.LMS.Credit.VIEW, ProductPermissionValues.branch.LMS.Credit.MANAGE];
