import { CreateBranchUsers } from '@platform/platform_components';
import * as API from '@shared/api';
import { Role } from '@shared/constants';
import { BranchUserModel, PaginationQuery } from '@shared/models';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';

type BrandAndBranchUserFormType = {
  branchId?: string;
  open: boolean;
  onCancelModal: () => void;
};

export const BranchCreateUser = ({ branchId, open, onCancelModal }: BrandAndBranchUserFormType) => {
  const [brandProductResult, brandProductLoading, getBrandProduct] = API.PRODUCT.getEnableBrandProduct({ init: false });
  const [enableProductTypes, setEnableProductTypes] = useState<string[]>([]);
  const [branchUsers, usersLoading, getBranchUsers] = API.BRANCH.getBranchUsersWithBranchId({ init: false });
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 4 });

  useEffect(() => {
    if (brandProductResult) {
      const productTypes = brandProductResult.map((p) => {
        return p.type;
      }) as string[];
      setEnableProductTypes(productTypes);
    }
  }, [brandProductResult]);

  useEffect(() => {
    if (branchId && branchId != '') {
      getBranchUsers(undefined, { ...paginationQuery, branchId: branchId });
      getBrandProduct();
    }
  }, [paginationQuery, branchId]);

  const addBranchUser = async (branchUser: BranchUserModel) => {
    if (branchId) {
      if (branchUser.id) {
        await API.BRANCH.updateBrandBranchUser({ ...branchUser, branchId: branchId });
      } else {
        await API.BRANCH.createBrandBranchUser({ ...branchUser, branchId: branchId });
      }

      getBranchUsers(undefined, { ...paginationQuery, branchId: branchId });
    }
  };

  const onDeleteUser = async (id: string) => {
    await API.BRANCH.deleteBrandBranchUser(branchId!, id);
    getBranchUsers(undefined, { ...paginationQuery, branchId: branchId, page: 1 });
  };

  return (
    <Modal
      centered
      open={open}
      width={1200}
      cancelText="Vazgeç"
      footer={<></>}
      onCancel={() => {
        onCancelModal();
      }}
    >
      <CreateBranchUsers
        branchUsers={branchUsers}
        enableProductTypes={enableProductTypes}
        addBranchUser={addBranchUser}
        onDelete={onDeleteUser}
        isBranchSearch={false}
        onPaginationChange={(page) => setPaginationQuery((prev) => ({ ...paginationQuery, page }))}
      />
    </Modal>
  );
};

BranchCreateUser.roles = [Role.ADMIN];
