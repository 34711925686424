import { DemandTableFilter, DemandTableInfo } from '@platform/platform_components';
import * as API from '@shared/api';
import { Gantt, Spinner } from '@shared/components';
import { LayoutEnums, ProductPermissionValues, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import dayjs, { Dayjs } from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { useEffect, useState } from 'react';
import { MdOutlineCalendarMonth } from 'react-icons/md';

dayjs.extend(dayOfYear);

export const GanttPage = () => {
  useAccessableSeachParams();
  const [startPoint, setStartPoint] = useState<Dayjs>(dayjs().startOf('month'));
  const [params, setParams] = useState<any>();

  const [demandList, demandListLoading, getDemandList] = API.ENROLLMENT.fetchDemandTable({
    init: false,
  });
  
  const [levels, levelsLoading, getLevel] = API.EDUCATION_LEVEL.BRANCH.useFetchLevel({ init: true, initParams: { active: true, limit: 1000 } });
  const [teachers, teachersLoading, getTeachers] = API.TEACHER.useFetch({ init: true, initParams: { withDeleted: false } });
  const [classrooms, classroomsLoading, getClassrooms] = API.CLASS_ROOM.getClassroomList({ init: true });
  const [seances, seancesLoading, getSeances] = API.SESSION_DEFINITIONS.getSessionData({ init: true });

  //Filtreler değiştiğinde öğrenci listesini seçilmiş filtrelerle tekrar çağırıyoruz
  useEffect(() => {
    if (params) {
      getDemandList(undefined, params);
    }
  }, [params]);

  //Takvimde öğrenci ile ilgili işlem yapıldığında tabloyu tekrar çağırıyoruz güncel filtrelerle
  const callDemandList = () => {
    getDemandList(undefined, params);
  };

  const data = demandList?.items.map((item) => {
    return {
      accountId: item.accountId,
      name: `${item.name} ${item.surname} ` + (item.waitingInDays >= 0 ? `(${item.waitingInDays} Gündür)` : `(${Math.abs(item.waitingInDays)} Gün Sonra)`),
      enrollmentName: `${item.name} ${item.surname} `,
      email: item.email,
      phone: item.phone,
      gender: item.gender,
      birthDate: item.birthDate,
      dates: item.demands.map((i) => {
        return {
          ...i,
          text: (i.waitingInDays >= 0 ? `(${i.waitingInDays} Gündür)` : `(${Math.abs(i.waitingInDays)} Gün Sonra)`) + ` ${i.level.name}`,
          startDate: new Date(i.startDate),
          endDate: new Date(i.endDate),
          levelName: ` ${i.level.name}`,
        };
      }),
    };
  });

  return (
    <div className="mt-3">
      <Spinner loading={demandListLoading || levelsLoading || teachersLoading || classroomsLoading || seancesLoading} />
      <DemandTableFilter
        onFilter={(filter) => setParams(filter)}
        setStartPoint={setStartPoint}
        levels={levels!}
        teachers={teachers!}
        classrooms={classrooms!}
        seances={seances!}
      />
      <DemandTableInfo />
      <Gantt data={data ?? []} startPoint={startPoint} callDemandList={callDemandList} />
    </div>
  );
};

GanttPage.path = '/branch_manager/gantt_page';
GanttPage.title = 'Talep Takvimi';
GanttPage.group = 'flat';
GanttPage.roles = [Role.ADMIN];
GanttPage.layouts = [LayoutEnums.BRANCHMANAGER];
GanttPage.icon = <MdOutlineCalendarMonth />;
GanttPage.permissions = [ProductPermissionValues.branch.LMS.Enrollment.VIEW, ProductPermissionValues.branch.LMS.Class.VIEW, ProductPermissionValues.branch.LMS.ClassPlanning.VIEW];
