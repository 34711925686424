import * as API from '@shared/api';
import { LearningContentVersion } from '@shared/components';
import { BrandContentVersionUpdatePackageStatus, ContentVersionStatus, LayoutEnums, ProductPermissionValues, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { Alert, Button, Popconfirm, Space, notification } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

export const BrandLearningContentVersions = () => {
  useAccessableSeachParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [draft, draftLoading, getDraft] = API.CURRICULUM.BRAND_COURSE_VERSION.fetchWithStatus(ContentVersionStatus.DRAFT, { init: true });
  const [published, publishedLoading, getPublished] = API.CURRICULUM.BRAND_COURSE_VERSION.fetchWithStatus(ContentVersionStatus.PUBLISHED, { init: true });
  const [versionPackage, versionPackageLoading, getVersionPackage] = API.CURRICULUM.BRAND_COURSE_VERSION.fetchVersionPackage({ init: true });
  const [levels, levelsLoading, getLevels] = API.EDUCATION_LEVEL.BRAND.useFetchLevel({ init: true, initParams: { active: true, limit: 1000 } });

  const getVersions = () => {
    getDraft();
    getPublished();
  };

  const onForkVersion = async (id: string) => {
    if (id) {
      await API.CURRICULUM.BRAND_COURSE_VERSION.fork(id);
      getVersions();
    }
  };

  const onDeleteVersion = async (id: string) => {
    try {
      await API.CURRICULUM.BRAND_COURSE_VERSION.delete(id);
      getVersions();
    } catch (error) {
      notification.error({ message: 'Error', description: 'Version delete error' });
    }
  };

  const onSetAsDefaultVersion = async (id: string) => {
    try {
      await API.CURRICULUM.BRAND_COURSE_VERSION.setAsDefault(id);
      getVersions();
    } catch (error) {
      notification.error({ message: 'Error', description: 'Version set as default error' });
    }
  };

  return (
    <div>
      {versionPackage && versionPackage.filter(({ status }) => status === BrandContentVersionUpdatePackageStatus.NONE).length > 0 && (
        <div className="flex flex-row">
          {versionPackage
            .filter(({ status }) => status === BrandContentVersionUpdatePackageStatus.NONE)
            .map(({ id, brand, systemContentVersion }) => {
              return (
                <Alert
                  message={
                    <span className="text-sm font-semibold">
                      {systemContentVersion?.level?.theme?.type?.field?.name} / {systemContentVersion?.level?.theme?.type?.name} / {systemContentVersion?.level?.theme?.name} /
                      {systemContentVersion?.level?.name}
                    </span>
                  }
                  description={
                    <div className="flex flex-col">
                      <span className="text-xs">Adı: {systemContentVersion?.name}</span>
                      <span className="text-xs">Açıklama: {systemContentVersion?.description}</span>
                      <span className="text-xs">Version: V{systemContentVersion?.version}</span>
                    </div>
                  }
                  type="warning"
                  action={
                    <Space direction="vertical" className="ml-2">
                      <Popconfirm
                        title="Applying the version"
                        description="Are you sure to apply this version?"
                        onConfirm={async () => {
                          await API.CURRICULUM.BRAND_COURSE_VERSION.acceptUpdateVersion(id!);
                          getVersionPackage();
                        }}
                        okText="Evet"
                        cancelText="İptal"
                      >
                        <Button size="small" type="primary">
                          Kabul Et
                        </Button>
                      </Popconfirm>
                      <Popconfirm
                        title="Rejecting the version"
                        description="Are you sure to reject this version?"
                        onConfirm={async () => {
                          await API.CURRICULUM.BRAND_COURSE_VERSION.rejectUpdateVersion(id!);
                          getVersionPackage();
                        }}
                        okText="Evet"
                        cancelText="İptal"
                      >
                        <Button size="small" danger ghost>
                          Reddet
                        </Button>
                      </Popconfirm>
                    </Space>
                  }
                  closable
                />
              );
            })}
        </div>
      )}
      <LearningContentVersion
        draft={draft ?? []}
        published={published ?? []}
        levels={levels?.data ?? []}
        brandContentVersionPackage={versionPackage ?? []}
        newVersionRequest={() => {
          navigate('/brand-learning-content-versions/draft-version' + location.search);
        }}
        onDeleteVersion={onDeleteVersion}
        onEditVersion={(id) => {
          const params = new URLSearchParams({
            versionId: id,
          });
          navigate('/brand-learning-content-versions/draft-version' + location.search + '&versionId=' + id);
        }}
        onForkVersion={onForkVersion}
        onPublishVersion={(id) => {
          navigate('/brand-review-learning-content-version' + location.search + '&versionId=' + id);
        }}
        onDefaultVersion={onSetAsDefaultVersion}
      />
    </div>
  );
};

BrandLearningContentVersions.path = '/brand-learning-content-versions';
BrandLearningContentVersions.title = 'Ders İçeriği Versiyonları';
BrandLearningContentVersions.group = 'curriculum';
BrandLearningContentVersions.roles = [Role.ADMIN];
BrandLearningContentVersions.layouts = [LayoutEnums.BRANDMANAGER, LayoutEnums.LMS];
BrandLearningContentVersions.permissions = [ProductPermissionValues.brand.LMS.ContentVersion.VIEW];
