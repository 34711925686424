import { BookOutlined, CaretRightOutlined, CheckOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ExerciseOrPageHandler } from '@shared/components';
import { CdnScope } from '@shared/components/curriculum/FileUpload';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams, useWindowSize } from '@shared/hooks';
import {
  ChoiceAnswerResponse,
  ContentTopicMeta,
  ContentTopicTree,
  ContentUnitMeta,
  ContentUnitTree,
  ContentVersionNavigation,
  ExerciseAnswerRequestModel,
  ExerciseGroupModel,
  MediaOrderAnswerResponse,
  OrderAnswerResponse,
} from '@shared/models';
import * as rocketLottie from '@shared/styles/lotties/rocket.json';
import * as tickLottie from '@shared/styles/lotties/tick.json';
import { Button, Card, Empty, Progress, Select, Spin, Tag } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import Lottie from 'lottie-react';

import { useCallback, useEffect, useState } from 'react';
import { IoReturnDownBack } from 'react-icons/io5';
import { number } from 'zod';

export const StudentCourseContent = () => {
  useAccessableSeachParams();
  const { width, height, sm, md, lg } = useWindowSize();
  const [levels, levelsLoading, getStudentLevels] = API.CURRICULUM.STUDENT_LEARNING_CONTENT.studentLevels({ init: true });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [levelOptions, setLevelOptions] = useState<DefaultOptionType[]>([]);
  const [selectedLevelId, setSelectedLevelId] = useState<string>();
  const [units, setUnits] = useState<ContentUnitTree[]>();
  const [selectedUnit, setSelectedUnit] = useState<ContentUnitMeta>();
  const [unitsLoading, setUnitsLoading] = useState<boolean>(false);
  const [topics, setTopics] = useState<ContentTopicTree[]>([]);
  const [selectedTopic, setSelectedTopic] = useState<ContentTopicMeta>();
  const [topicsLoading, setTopicsLoading] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<ContentVersionNavigation>();
  const [isExerciseMode, setIsExerciseMode] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [selectedPageLoading, setSelectedPageLoading] = useState<boolean>(false);

  const [exercisePage, setExercisePage] = useState<number>(0);

  const [answerRequestModel, setAnswerRequestModel] = useState<ExerciseAnswerRequestModel>();
  const [answerResponseModel, setAnswerResponseModel] = useState<ChoiceAnswerResponse | OrderAnswerResponse | MediaOrderAnswerResponse>();

  //Mobil
  const [isTopicListOpen, setIsTopicListOpen] = useState<boolean>(false);
  const [isLessonOpen, setIsLessonOpen] = useState<boolean>(false);

  useEffect(() => {
    if (levels) {
      const options = levels.map((l) => ({
        label: l.theme?.type?.field?.name + '/' + l.theme?.type?.name + '/' + l.theme?.name + '/' + l.name,
        value: l.id,
      }));

      setLevelOptions(options);
      setIsLoading(false);
      if (options.length == 1) {
        setSelectedLevelId(options[0].value);
      }
    }
  }, [levels]);

  useEffect(() => {
    if (selectedLevelId) {
      getContentUnits();
    }
  }, [selectedLevelId]);

  useEffect(() => {
    if (selectedUnit) {
      getContentTopics();
    }
  }, [selectedUnit?.id]);

  useEffect(() => {
    if (selectedTopic) {
      getContentPages();
    }
  }, [selectedTopic?.id]);

  const getContentUnits = async () => {
    if (selectedLevelId) {
      setUnitsLoading(true);
      const { ok, data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.getContentUnits(selectedLevelId);
      if (ok) {
        setUnits(data);
        if (!selectedUnit) {
          setSelectedUnit(data[0]);
        }
        setUnitsLoading(false);
      }
    }
  };

  const getContentTopics = async () => {
    if (selectedUnit && selectedLevelId) {
      setTopicsLoading(true);
      const { ok, data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.getContentTopics(selectedLevelId, selectedUnit.id);
      if (ok) {
        setTopics(data);
        setTopicsLoading(false);
      }
    }
  };

  const getContentPages = async () => {
    if (selectedLevelId && selectedUnit && selectedTopic) {
      setIsExerciseMode(true);
      setSelectedPageLoading(true);
      const { ok, data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.getContentPages(selectedLevelId, selectedUnit.id, selectedTopic.id);
      if (ok) {
        setSelectedPage(data);
        setSelectedPageLoading(false);
      }
    }
  };

  const goToNextPage = useCallback(async () => {
    if (selectedLevelId && selectedPage && selectedUnit && selectedTopic) {
      const { data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.goToNextPage(selectedLevelId, selectedUnit.id, selectedTopic.id, selectedPage.page.id!);
      setExercisePage(0);
      setSelectedPage(data);
    }
  }, [exercisePage, selectedPage, selectedLevelId, selectedPage, selectedUnit, selectedTopic]);

  /*const goToNextPage = async () => {
    if (selectedLevelId && selectedPage && selectedUnit && selectedTopic) {
      const { data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.goToNextPage(selectedLevelId, selectedUnit.id, selectedTopic.id, selectedPage.page.id!);
      
      setSelectedPage(data);
    }
  };*/

  const goToPrevPage = async () => {
    if (selectedLevelId && selectedPage && selectedUnit && selectedTopic) {
      const { data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.goToPrevPage(selectedLevelId, selectedUnit.id, selectedTopic.id, selectedPage.page.id!);
      setSelectedPage(data);
    }
  };

  const progressCircle = (percent: number) => {
    return (
      <Progress
        status="success"
        type="circle"
        size={25}
        percent={percent}
        showInfo={true}
        format={(v, s) => {
          if (v == 100) {
            return <CheckOutlined className="text-[#722ED1] text-base font-extrabold" />;
          } else {
            return <CaretRightOutlined className="text-[#722ED1] text-lg font-extrabold" />;
          }
        }}
        trailColor="#e9d7fa"
        strokeColor={'#722ED1'}
      />
    );
  };

  const onCheckAnswer = useCallback(async () => {
    try {
      if (!selectedLevelId) {
        return;
      }
      const response = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.studentExerciseAnswer(
        selectedLevelId!,
        selectedPage?.page.exercise?.id!,
        selectedPage?.page.exercise?.exercises![exercisePage].id!,
        answerRequestModel!
      );
      if (response.ok) {
        setAnswerResponseModel(response.data);
      }
    } catch (error) {
      console.error('Student Course Content Error: ' + error);
    }
  }, [selectedLevelId, selectedPage, selectedPage?.page, answerRequestModel, exercisePage]);

  const onCheckPronounciation = useCallback(
    async (success: boolean, rate: number) => {
      try {
        if (!selectedLevelId) {
          return;
        }
        const response = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.studentExerciseAnswer(
          selectedLevelId!,
          selectedPage?.page.exercise?.id!,
          selectedPage?.page.exercise?.exercises![exercisePage].id!,
          { success, rate }
        );
      } catch (error) {
        console.error('Student Course Content Error: ' + error);
      }
    },
    [selectedLevelId, selectedPage, selectedPage?.page, answerRequestModel, exercisePage]
  );

  const onSendListiningBaseRate = useCallback(async (success: boolean, rate: number) => {
    try {
      if (!selectedLevelId) {
        return;
      }
      const response = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.studentExerciseAnswer(
        selectedLevelId!,
        selectedPage?.page.exercise?.id!,
        selectedPage?.page.exercise?.exercises![exercisePage].id!,
        { success, rate }
      );
    } catch (error) {
      console.error('Student Course Content Error: ' + error);
    }
  }, [selectedLevelId, selectedPage, selectedPage?.page, answerRequestModel, exercisePage]);

  const onCheckDialogPronounciation = useCallback(
    async (conversationItemId: string, success: boolean, rate: number) => {
      try {
        if (!selectedLevelId) {
          return;
        }
        const response = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.studentExerciseAnswer(
          selectedLevelId!,
          selectedPage?.page.exercise?.id!,
          selectedPage?.page.exercise?.exercises![exercisePage].id!,
          { conversationItemId, success, rate }
        );
      } catch (error) {
        console.error('Student Course Content Error: ' + error);
      }
    },
    [selectedLevelId, selectedPage, selectedPage?.page, exercisePage, answerRequestModel]
  );

  //Webten açıldıysa
  if (lg) {
    return (
      <>
        {isLoading ? (
          <div className="flex flex-col h-96 w-full text-center items-center justify-center content-center">
            <Spin size="default" />
            <span className="text-purple-400">Öğrenci İçerikleri Getiriliyor...</span>
          </div>
        ) : (
          <div className="grid grid-cols-12">
            <div className="flex flex-col items-start col-span-3">
              {levelOptions.length == 1 ? (
                <Select
                  size="large"
                  value={selectedLevelId}
                  placeholder={'Lütfen bir seçim yapınız'}
                  loading={levelsLoading}
                  className="w-full"
                  onChange={(value) => {
                    setSelectedLevelId(value);
                  }}
                  options={levelOptions}
                  disabled
                />
              ) : (
                <Select
                  size="large"
                  value={selectedLevelId}
                  placeholder={'Lütfen bir seçim yapınız'}
                  loading={levelsLoading}
                  className="w-full"
                  onChange={(value) => {
                    setSelectedLevelId(value);
                  }}
                  options={levelOptions}
                />
              )}

              {/*Seçilen kurda ders içeriği yoksa içerik gösterilmeyecek */}
              {units && units.length > 0 && (
                <>
                  <span className="text-lg font-sans p-2">Üniteler</span>
                  <div className="w-full p-1 overflow-y-auto scrollbar-hide " style={{ height: 'calc(100vh - 185px)' }}>
                    {unitsLoading ? (
                      <div className="flex flex-col justify-center items-center h-full w-full">
                        <Spin />
                        <span className="text-purple-400">Üniteler Listeleniyor...</span>
                      </div>
                    ) : (
                      <>
                        {units?.map((unit) => {
                          return (
                            <Card
                              size="small"
                              key={unit.id}
                              className={
                                selectedUnit?.id == unit.id ? 'bg-[#f3e2ff] border-[#F0DBFF] border-2 mb-2 shadow-md rounded-lg' : 'bg-[#FFFEFE] mb-2 shadow-md rounded-lg'
                              }
                              hoverable
                              onClick={() => {
                                if (selectedPage) {
                                  setSelectedPage(undefined);
                                  setIsExerciseMode(false);
                                  setAnswerRequestModel(undefined);
                                  setAnswerResponseModel(undefined);
                                }
                                setSelectedUnit(unit);
                                setExercisePage(0);
                                getContentTopics();
                              }}
                            >
                              <div className="flex flex-row justify-between  ">
                                <div className="flex flex-col">
                                  <span className={selectedUnit && selectedUnit.id == unit.id ? 'text-[#722ED1]' : ''}>{unit.description}</span>
                                  <span className={selectedUnit && selectedUnit.id == unit.id ? 'text-[#D3ADF7] text-xs line-clamp-1' : 'text-xs line-clamp-1'}>{unit.name}</span>
                                </div>

                                {progressCircle(unit.percent)}
                              </div>
                            </Card>
                          );
                        })}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>

            <div
              className={
                'w-full flex col-span-9 content-center justify-center items-center scrollbar-hide ' +
                (selectedPage && isExerciseMode ? 'h-[calc(100svh-100px)]' : 'overflow-y-auto h-[calc(100svh-110px)]')
              }
            >
              {units && units.length > 0 ? (
                <div className={selectedPage ? 'h-full w-[30rem]' : 'w-[30rem] h-full'}>
                  {isExerciseMode ? (
                    <div className="w-full" style={{ height: 'calc(100vh - 110px)' }}>
                      {selectedPageLoading ? (
                        <div className="flex flex-col justify-center items-center h-full w-full">
                          <Spin />
                          <span className="text-purple-400">Egzersiz Getiriliyor...</span>
                        </div>
                      ) : (
                        <>
                          {!isSuccess && selectedPage ? (
                            <ExerciseOrPageHandler
                              exercisePage={exercisePage}
                              page={selectedPage.page}
                              cdnScope={CdnScope.BRANCH}
                              isInClass={false}
                              isPreview={false}
                              selectedTopic={selectedPage.meta.topic}
                              onNext={() => {
                                if (selectedPage && selectedPage.meta.hasNext) {
                                  //goToNextPage();
                                  setIsSuccess(true);
                                } else {
                                  setSelectedPage(undefined);
                                  setExercisePage(0);
                                }
                              }}
                              onPrev={() => {
                                if (selectedPage && selectedPage.meta.hasPrev) {
                                  goToPrevPage();
                                } else {
                                  setSelectedPage(undefined);
                                  setExercisePage(0);
                                }
                              }}
                              onChangeAnswerRequestModel={(model) => {
                                if (!model) {
                                  setAnswerResponseModel(undefined);
                                }

                                setAnswerRequestModel(model);
                              }}
                              onChangeExercisePage={setExercisePage}
                              onSendDialogResult={onCheckAnswer}
                              answerRequestModel={answerRequestModel}
                              onCheckAnswer={onCheckAnswer}
                              answerResponseModel={answerResponseModel}
                              onResponseModelPronounciation={async (success: boolean, rate: number) => {
                                onCheckPronounciation(success, rate);
                                setAnswerResponseModel({ success });
                              }}
                              onResponseModelListiningBase={
                                async (success: boolean, rate: number) => {
                                  await onSendListiningBaseRate(success, rate);
                                }
                              }
                              onResponseModelDialogPronounciation={async (conversationItemId: string, success: boolean, rate: number) => {
                                onCheckDialogPronounciation(conversationItemId, success, rate);
                              }}
                            />
                          ) : (
                            <>
                              {isSuccess && selectedPage ? (
                                <SuccessPage
                                  level={levelOptions.find((l) => selectedLevelId == l.value)?.label?.toString()!}
                                  exerciseInfo={selectedPage.page.exercise!}
                                  onGoBack={() => {
                                    setSelectedTopic(undefined);
                                    setExercisePage(0);
                                    setIsExerciseMode(false);
                                    setSelectedPage(undefined);
                                    setIsSuccess(false);
                                    getContentTopics();
                                    getContentUnits();
                                  }}
                                  onGoNextExercise={async () => {
                                    await goToNextPage();
                                    setIsSuccess(false);
                                    getContentUnits();
                                  }}
                                />
                              ) : (
                                <div>Egzersiz bulunumadı</div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="h-full w-full">
                      {topicsLoading ? (
                        <div className="flex flex-col justify-center items-center h-full w-full">
                          <Spin />
                          <span className="text-purple-400">Konular Listeleniyor...</span>
                        </div>
                      ) : (
                        topics.map((topic) => {
                          return (
                            <Card
                              key={topic.id}
                              className="mb-2 bg-[#F9F0FF] p-0 border-[#F0DBFF] hover:border-[#bb76ec]"
                              hoverable
                              size="small"
                              onClick={() => {
                                if (selectedTopic && selectedTopic.id == topic.id) {
                                  getContentPages();
                                } else {
                                  setSelectedTopic(topic);
                                }
                                setExercisePage(0);
                              }}
                              title={
                                <div className="flex flex-row justify-between pt-1">
                                  <div className="flex flex-col">
                                    <span className="text-[#722ED1] text-sm font-normal">{topic.description}</span>
                                    <span className="text-[#D3ADF7] text-xs font-normal">{topic.name}</span>
                                  </div>
                                  {progressCircle(topic.percent)}
                                </div>
                              }
                            >
                              <div className="bg-[#F4E5FF] p-2 rounded-md">
                                <Tag color="#F9F0FF">
                                  <span className="text-[#722ED1] text-xs">{topic.type}</span>
                                </Tag>
                                <div className="flex flex-row justify-between content-center items-center space-x-2 mt-1">
                                  <Progress
                                    percent={topic.percent}
                                    showInfo={false}
                                    className="w-full"
                                    strokeColor={{
                                      '0%': '#F9E681',
                                      '48%': '#F69D35',
                                      '100%': '#F60000',
                                    }}
                                  />
                                  <span className="text-sm text-[#722ED1]">{'%' + topic.percent}</span>
                                </div>
                              </div>
                            </Card>
                          );
                        })
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <Empty className="" image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Erişebildiğiniz ders içeriği bulunamadı'} />
              )}
            </div>
          </div>
        )}
      </>
    );
  }

  //Mobil ise
  return (
    <>
      <div className=" h-full">
        {!isTopicListOpen ? (
          <div className="flex flex-col mt-1 w-11/12 mx-auto">
            {levelOptions.length == 1 ? (
              <Select
                size="large"
                value={selectedLevelId}
                placeholder={'Lütfen bir seçim yapınız'}
                loading={levelsLoading}
                className="w-full"
                onChange={(value) => {
                  setSelectedLevelId(value);
                }}
                options={levelOptions}
                disabled
              />
            ) : (
              <Select
                size="large"
                value={selectedLevelId}
                placeholder={'Lütfen kurunuzu seçiniz'}
                loading={levelsLoading}
                style={{ width: '100%' }}
                onChange={(value) => {
                  setSelectedLevelId(value);
                }}
                options={levelOptions}
              />
            )}
            {units && units.length > 0 ? (
              <>
                <span className="text-lg font-sans p-2 mt-1">Üniteler</span>
                <div className="w-full p-1 overflow-y-auto scrollbar-hide " style={{ height: 'calc(100vh - 185px)' }}>
                  {units?.map((unit) => {
                    return (
                      <Card
                        size="small"
                        key={unit.id}
                        className={selectedUnit?.id == unit.id ? 'bg-[#f3e2ff] border-[#F0DBFF] border-2 mb-2 shadow-md rounded-lg' : 'bg-[#FFFEFE] mb-2 shadow-md rounded-lg'}
                        hoverable
                        onClick={() => {
                          setSelectedUnit(unit);
                          setIsTopicListOpen(true);
                          setExercisePage(0);
                          getContentTopics();
                        }}
                      >
                        <div className="flex flex-row justify-between  ">
                          <div className="flex flex-col">
                            <span className={selectedUnit && selectedUnit.id == unit.id ? 'text-[#722ED1]' : ''}>{unit.description}</span>
                            <span className={selectedUnit && selectedUnit.id == unit.id ? 'text-[#D3ADF7] text-xs' : 'text-xs'}>{unit.name}</span>
                          </div>
                          {progressCircle(unit.percent)}
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </>
            ) : (
              <Empty style={{ marginBlock: '50px' }} image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Erişebildiğiniz ders içeriği bulunamadı!'} />
            )}
          </div>
        ) : !isLessonOpen || !selectedPage ? (
          <div className="mt-2 w-11/12 mx-auto">
            <Card
              size="small"
              className={'bg-[#f3e2ff] border-[#F0DBFF] border-2 mb-2 shadow-md rounded-lg'}
              onClick={() => {
                setIsTopicListOpen(false);
              }}
            >
              <div className="flex flex-row items-center">
                <Button type="link" icon={<IoReturnDownBack style={{ color: '#3F0A70' }} />} />
                <span className="text-[#3F0A70]">{selectedUnit?.name}</span>
              </div>
            </Card>
            <div className="mt-5">
              {topics.map((topic) => {
                return (
                  <Card
                    key={topic.id}
                    className="mb-2 bg-[#F9F0FF] p-0 border-[#F0DBFF] hover:border-[#bb76ec]"
                    hoverable
                    size="small"
                    onClick={() => {
                      setIsLessonOpen(true);
                      setSelectedTopic(topic);
                      setExercisePage(0);
                    }}
                    title={
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-col">
                          <span className="text-[#722ED1] text-sm font-normal">{topic.description}</span>
                          <span className="text-[#D3ADF7] text-xs font-normal line-clamp-1">{topic.name}</span>
                        </div>
                        {progressCircle(topic.percent)}
                      </div>
                    }
                  >
                    <div className="bg-[#F4E5FF] p-2 rounded-md">
                      <Tag color="#F9F0FF">
                        <span className="text-[#722ED1] text-xs">{topic.type}</span>
                      </Tag>
                      <div className="flex flex-row justify-between content-center items-center space-x-2 mt-1">
                        <Progress
                          percent={topic.percent}
                          showInfo={false}
                          className="w-full"
                          strokeColor={{
                            '0%': '#F9E681',
                            '48%': '#F69D35',
                            '100%': '#F60000',
                          }}
                        />
                        <span className="text-sm text-[#722ED1]">{'%' + topic.percent}</span>
                      </div>
                    </div>
                  </Card>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="w-full h-full items-center mx-auto mt-1">
            {isSuccess && selectedPage ? (
              <SuccessPage
                level={levelOptions.find((l) => selectedLevelId == l.value)?.label?.toString()!}
                exerciseInfo={selectedPage.page.exercise!}
                onGoBack={() => {
                  setSelectedTopic(undefined);
                  setExercisePage(0);
                  setIsExerciseMode(false);
                  setSelectedPage(undefined);
                  setIsSuccess(false);
                  getContentTopics();
                  getContentUnits();
                }}
                onGoNextExercise={async () => {
                  await goToNextPage();
                  setIsSuccess(false);
                  getContentUnits();
                }}
              />
            ) : (
              <ExerciseOrPageHandler
                exercisePage={exercisePage}
                page={selectedPage.page}
                cdnScope={CdnScope.BRANCH}
                isInClass={false}
                isPreview={false}
                selectedTopic={selectedPage.meta.topic}
                onNext={() => {
                  if (selectedPage && selectedPage.meta.hasNext) {
                    //goToNextPage();
                    setIsSuccess(true);
                  } else {
                    setSelectedPage(undefined);
                  }
                }}
                onPrev={() => {
                  if (selectedPage && selectedPage.meta.hasPrev) {
                    goToPrevPage();
                  } else {
                    setSelectedPage(undefined);
                  }
                }}
                onMobileGoBack={() => {
                  setSelectedPage(undefined);
                  setIsLessonOpen(false);
                }}
                onChangeAnswerRequestModel={(model) => {
                  if (!model) {
                    setAnswerResponseModel(undefined);
                  }

                  setAnswerRequestModel(model);
                }}
                onChangeExercisePage={setExercisePage}
                onSendDialogResult={onCheckAnswer}
                answerRequestModel={answerRequestModel}
                onCheckAnswer={onCheckAnswer}
                answerResponseModel={answerResponseModel}
                onResponseModelPronounciation={async (success: boolean, rate: number) => {
                  onCheckPronounciation(success, rate);
                  setAnswerResponseModel({ success });
                }}
                onResponseModelListiningBase={
                  async (success: boolean, rate: number) => {
                    await onSendListiningBaseRate(success, rate);
                  }
                }
                onResponseModelDialogPronounciation={(conversationItemId: string, success: boolean, rate: number) => {
                  onCheckDialogPronounciation(conversationItemId, success, rate);
                }}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

type SuccessPageProps = {
  onGoBack: () => void;
  onGoNextExercise: () => void;
  exerciseInfo: ExerciseGroupModel;
  level: string;
};

const SuccessPage = ({ level, exerciseInfo, onGoBack, onGoNextExercise }: SuccessPageProps) => {
  return (
    <div className="flex flex-col h-full w-full justify-between bg-white p-5 rounded-md">
      <div className="flex flex-row w-full justify-between items-center p-5 bg-[#F9F0FF] rounded-xl border-[#F0DBFF] relative">
        <div className="flex flex-col justify-start items-start content-start">
          <span className="text-lg text-[#722ED1] font-medium">{exerciseInfo?.name}</span>
          <span className="text-sm text-[#D3ADF7] font-normal">{level}</span>
        </div>
        <div className="h-12 w-12">
          {' '}
          <Lottie height={70} width={70} animationData={tickLottie} />
        </div>
      </div>

      <div className="h-[50%] w-full">
        <Lottie animationData={rocketLottie} className="w-full h-full" />
      </div>
      <div className="flex flex-col justify-around items-center content-center h-full w-full space-y-2">
        <div className="flex flex-col w-full justify-center items-center">
          <span className="text-sm text-[#722ED1] font-medium">Tebrikler!</span>
          <span className="text-sm text-[#D3ADF7] font-normal">Egzersizi başarıyla tamamladın.</span>
        </div>

        <div className="flex flex-col w-full justify-between">
          <div className="flex w-full justify-center items-center content-center bg-[#722ED1] rounded-xl p-3 m-1 cursor-pointer" onClick={onGoNextExercise}>
            <span className="text-base text-white font-normal text-center">Sonraki egzersize başla</span>
          </div>

          <div className="flex w-full justify-center items-center content-center bg-white border-error rounded-xl p-3 cursor-pointer" onClick={onGoBack}>
            <span className="text-base text-error font-normal text-center">Egzersizden çık</span>
          </div>
        </div>
      </div>
    </div>
  );
};

StudentCourseContent.path = '/' + LayoutEnums.STUDENT + '/course_content';
StudentCourseContent.title = 'Ders İçerikleri';
StudentCourseContent.group = 'flat';
StudentCourseContent.roles = [Role.ADMIN];
StudentCourseContent.layouts = [LayoutEnums.STUDENT];
StudentCourseContent.icon = <BookOutlined size={20} />;
