import {
  ArrowUpOutlined,
  CloseCircleOutlined,
  FileDoneOutlined,
  FileUnknownOutlined,
  LineChartOutlined,
  PaperClipOutlined,
  PlusCircleOutlined,
  RiseOutlined,
  SettingOutlined,
  StopOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { CategoryFilter } from '@platform/platform_components';
import { KanbanColumn, KanbanColumnTitle } from '@platform/platform_components/kanban_view/KanbanColumn';
import * as API from '@shared/api';
import { ActionDropdown, QuickReportIssue } from '@shared/components';
import { Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import i18n from '@shared/localization/i18n';
import { PaginationQuery } from '@shared/models';
import { AccountIssueCategory, AccountIssueProps, AccountIssueStatus } from '@shared/models/account_issue';
import { Badge, Button, notification, Tag } from 'antd';
import Search from 'antd/lib/input/Search';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const AccountIssueKanban = () => {
  useAccessableSeachParams();
  const [open, setOpen] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [opennedPaginationQuery, setOpennedPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 4, orders: ['-createdAt'], categories: [], types: [] });
  const [inProgressPaginationQuery, setInProgressPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 4, orders: ['-createdAt'], categories: [], types: [] });
  const [donePaginationQuery, setDonePaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 4, orders: ['-createdAt'], categories: [], types: [] });
  const [rejectedPaginationQuery, setRejectedPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 4, orders: ['-createdAt'], categories: [], types: [] });
  const [selectedData, setSelectedData] = useState<AccountIssueProps>();
  const [input, setInput] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();

  const [accountIssueOpen, loadingOpen, getAccountIssueOpen] = API.ACCOUNT_ISSUE.paginate({ init: false });
  const [accountIssueInProgress, loadingInProgress, getAccountIssueInProgress] = API.ACCOUNT_ISSUE.paginate({ init: false });
  const [accountIssueDone, loadingDone, getAccountIssueDone] = API.ACCOUNT_ISSUE.paginate({ init: false });
  const [accountIssueRejected, loadingRejected, getAccountIssueRejected] = API.ACCOUNT_ISSUE.paginate({ init: false });

  const onRenderIssues = () => {
    getAccountIssueOpen(undefined, { ...opennedPaginationQuery, statuses: [AccountIssueStatus.OPEN] });
    getAccountIssueInProgress(undefined, { ...inProgressPaginationQuery, statuses: [AccountIssueStatus.IN_PROGRESS] });
    getAccountIssueDone(undefined, { ...donePaginationQuery, statuses: [AccountIssueStatus.NOT_RESOLVED, AccountIssueStatus.RESOLVED] });
    getAccountIssueRejected(undefined, { ...rejectedPaginationQuery, statuses: [AccountIssueStatus.REJECTED] });
  };

  useEffect(() => {
    getAccountIssueOpen(undefined, { ...opennedPaginationQuery, statuses: [AccountIssueStatus.OPEN] });
  }, [opennedPaginationQuery]);

  useEffect(() => {
    getAccountIssueInProgress(undefined, { ...inProgressPaginationQuery, statuses: [AccountIssueStatus.IN_PROGRESS] });
  }, [inProgressPaginationQuery]);

  useEffect(() => {
    getAccountIssueDone(undefined, { ...donePaginationQuery, statuses: [AccountIssueStatus.NOT_RESOLVED, AccountIssueStatus.RESOLVED] });
  }, [donePaginationQuery]);

  useEffect(() => {
    getAccountIssueRejected(undefined, { ...rejectedPaginationQuery, statuses: [AccountIssueStatus.REJECTED] });
  }, [rejectedPaginationQuery]);

  const showDrawer = () => {
    setOpen(true);
    setIsUpdateMode(false);
  };

  const categoryMap = {
    [AccountIssueCategory.BUG]: (
      <Tag color="red">
        <CloseCircleOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.BUG)}
      </Tag>
    ),
    [AccountIssueCategory.FEATURE_REQUEST]: (
      <Tag color="pink">
        <RiseOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.FEATURE_REQUEST)}
      </Tag>
    ),
    [AccountIssueCategory.IMPROVEMENT]: (
      <Tag color="green">
        <ArrowUpOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.IMPROVEMENT)}
      </Tag>
    ),
    [AccountIssueCategory.OTHER]: (
      <Tag>
        <FileUnknownOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.OTHER)}
      </Tag>
    ),
    [AccountIssueCategory.PERFORMANCE]: (
      <Tag color="blue">
        <LineChartOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.PERFORMANCE)}
      </Tag>
    ),
  };

  const onFilter = (key: any, value?: any, reset: boolean = false) => {
    if (reset) {
      setOpennedPaginationQuery({ ...opennedPaginationQuery, keyword: undefined, categories: [], types: [] });
      setRejectedPaginationQuery({ ...rejectedPaginationQuery, keyword: undefined, categories: [], types: [] });
      setDonePaginationQuery({ ...donePaginationQuery, keyword: undefined, categories: [], types: [] });
      setInProgressPaginationQuery({ ...inProgressPaginationQuery, keyword: undefined, categories: [], types: [] });
      setInput('');
      return;
    }
    setDonePaginationQuery((prev) => ({
      ...prev,
      [key]: value,
    }));
    setInProgressPaginationQuery((prev) => ({
      ...prev,
      [key]: value,
    }));
    setRejectedPaginationQuery((prev) => ({
      ...prev,
      [key]: value,
    }));
    setOpennedPaginationQuery((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const TaskCardContent = ({ issue }) => {
    return (
      <>
        {issue && (
          <span onClick={goToDetail(issue.id)} className="font-bold cursor-pointer">
            {issue.title}
          </span>
        )}
      </>
    );
  };

  const goToDetail = (id) => {
    return () => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('issue_id', id);
      navigate('/lms/issue-detail?' + searchParams.toString());
    };
  };

  const searchKeyword = (keyword: string) => {
    setDonePaginationQuery({ ...donePaginationQuery, keyword });
    setInProgressPaginationQuery({ ...inProgressPaginationQuery, keyword });
    setOpennedPaginationQuery({ ...opennedPaginationQuery, keyword });
    setRejectedPaginationQuery({ ...rejectedPaginationQuery, keyword });
  };

  //TODO filtreler tekrar gözden geçirilmeli. Backend hatırlatılmalı
  //TODO files yok.
  return (
    <>
      <div className="container mx-auto pt-1">
        <div className="flex justify-between items-center">
          <div className="flex-1 mr-2">
            <Search
              value={input}
              width="100%"
              onChange={(e) => setInput(e.target.value)}
              placeholder="Ara"
              onSearch={(e) => {
                searchKeyword(e);
              }}
              enterButton
              className="w-full"
            />
          </div>
          <div className="flex-2 ml-2">
            <CategoryFilter onFilter={onFilter} filters={opennedPaginationQuery} />
          </div>
          <Button icon={<PlusCircleOutlined />} onClick={() => showDrawer()} type="dashed">
            Yeni Destek Talebi Oluştur
          </Button>
        </div>

        <div className="grid grid-cols-4 gap-3 mt-2">
          <KanbanColumn
            title={<KanbanColumnTitle title="İncelenecek" count={accountIssueOpen?.count} icon={<Badge color="orange" />} />}
            isLoading={loadingOpen}
            cardsStyle={{ height: 'calc(100vh - 205px)' }}
            loadMore={
              <div>
                {opennedPaginationQuery.limit! <= accountIssueOpen?.count! && (
                  <div className="flex justify-center items-center mt-auto">
                    <Button onClick={() => setOpennedPaginationQuery((prev) => ({ ...prev, limit: prev.limit! + 3 }))} type="link" icon={<SyncOutlined />}>
                      Daha fazla yükle
                    </Button>
                  </div>
                )}
              </div>
            }
            cards={accountIssueOpen?.data?.map((issue) => {
              return {
                title: (
                  <span onClick={goToDetail(issue.id)} className="font-normal opacity-45">
                    #{issue.no}
                  </span>
                ),
                content: <TaskCardContent issue={issue} />,
                action: (
                  <div>
                    <ActionDropdown
                      data={issue}
                      onDelete={async ({ id }) => {
                        const result = await API.ACCOUNT_ISSUE.deleteIssue(id);
                        if (result.ok) {
                          notification.success({ message: 'Talebiniz Kaydınız Başarıyla Silindi' });
                        }
                        setOpennedPaginationQuery((prev) => ({ ...prev, page: 1 }));
                      }}
                      onEdit={() => {
                        setOpen(true);
                        setIsUpdateMode(true);
                        setSelectedData(issue);
                      }}
                      onView={async ({ id }) => {
                        const searchParams = new URLSearchParams(location.search);
                        searchParams.set('issue_id', id);
                        await navigate('/lms/issue-detail?' + searchParams.toString());
                      }}
                    />
                  </div>
                ),
                footer: (
                  <div onClick={goToDetail(issue.id)} className="cursor-pointer">
                    <div>
                      <div>
                        <FileDoneOutlined /> {i18n.t('app.accountIssueType.' + issue.type)}
                      </div>
                      <div className="flex justify-between mt-2 mb-1">
                        <div>
                          {issue.files?.length! > 0 && (
                            <>
                              <PaperClipOutlined /> {issue.files?.length}
                            </>
                          )}
                        </div>
                        <div>{categoryMap[issue.category]}</div>
                      </div>
                    </div>
                  </div>
                ),
              };
            })}
          ></KanbanColumn>
          <KanbanColumn
            title={<KanbanColumnTitle title="İnceleniyor" count={accountIssueInProgress?.count} icon={<Badge color="blue" />} />}
            isLoading={loadingInProgress}
            cardsStyle={{ height: 'calc(100vh - 205px)' }}
            loadMore={
              <div>
                {inProgressPaginationQuery.limit! <= accountIssueInProgress?.count! && (
                  <div className="flex justify-center items-center mt-auto">
                    <Button onClick={() => setInProgressPaginationQuery((prev) => ({ ...prev, limit: prev.limit! + 3 }))} type="link" icon={<SyncOutlined />}>
                      Daha fazla yükle
                    </Button>
                  </div>
                )}
              </div>
            }
            cards={accountIssueInProgress?.data?.map((issue) => {
              return {
                title: <span className="font-normal opacity-45">#{issue.no}</span>,
                content: <TaskCardContent issue={issue} />,
                action: (
                  <ActionDropdown
                    data={issue}
                    onDelete={async ({ id }) => {
                      const result = await API.ACCOUNT_ISSUE.deleteIssue(id);
                      if (result.ok) {
                        notification.success({ message: 'Kredi Talebiniz Başarıyla Kaldırıldı' });
                      }
                      setInProgressPaginationQuery((prev) => ({ ...prev, page: 1 }));
                    }}
                    onEdit={() => {
                      setOpen(true);
                      setIsUpdateMode(true);
                      setSelectedData(issue);
                    }}
                  />
                ),
                footer: (
                  <div>
                    <div>
                      <div>
                        <FileDoneOutlined /> {i18n.t('app.accountIssueType.' + issue.type)}
                      </div>
                      <div className="flex justify-between">
                        <div>
                          {issue.files?.length! > 0 && (
                            <>
                              <PaperClipOutlined /> {issue.files?.length}
                            </>
                          )}
                        </div>
                        <div>{categoryMap[issue.category]}</div>
                      </div>
                    </div>
                  </div>
                ),
              };
            })}
          ></KanbanColumn>
          <KanbanColumn
            title={<KanbanColumnTitle title="Sonuçlandı" count={accountIssueDone?.count} icon={<Badge color="green" />} />}
            isLoading={loadingDone}
            cardsStyle={{ height: 'calc(100vh - 205px)' }}
            loadMore={
              <div>
                {donePaginationQuery.limit! <= accountIssueDone?.count! && (
                  <div className="flex justify-center items-center mt-auto">
                    <Button onClick={() => setDonePaginationQuery((prev) => ({ ...prev, limit: prev.limit! + 3 }))} type="link" icon={<SyncOutlined />}>
                      Daha fazla yükle
                    </Button>
                  </div>
                )}
              </div>
            }
            cards={accountIssueDone?.data?.map((issue) => {
              return {
                title: <span className="font-normal opacity-45">#{issue.no}</span>,
                content: <TaskCardContent issue={issue} />,
                action: (
                  <>
                    {issue.status === 'NOT_RESOLVED' && (
                      <span className="p-1 mr-1 bg-red-100 rounded-lg">
                        <StopOutlined className="text-red-600" />
                      </span>
                    )}
                    <ActionDropdown
                      data={issue}
                      onDelete={async ({ id }) => {
                        const result = await API.ACCOUNT_ISSUE.deleteIssue(id);
                        if (result.ok) {
                          notification.success({ message: 'Kredi Talebiniz Başarıyla Kaldırıldı' });
                        }
                        setDonePaginationQuery((prev) => ({ ...prev, page: 1 }));
                      }}
                      onEdit={() => {
                        setOpen(true);
                        setIsUpdateMode(true);
                        setSelectedData(issue);
                      }}
                      onView={async ({ id }) => {
                        const searchParams = new URLSearchParams(location.search);
                        searchParams.set('issue_id', id);
                        await navigate('/lms/issue-detail?' + searchParams.toString());
                      }}
                    />
                  </>
                ),
                footer: (
                  <div>
                    <div>
                      <div>
                        <FileDoneOutlined /> {i18n.t('app.accountIssueType.' + issue.type)}
                      </div>
                      <div className="flex justify-between">
                        <div>
                          {issue.files?.length! > 0 && (
                            <>
                              <PaperClipOutlined /> {issue.files?.length}
                            </>
                          )}
                        </div>
                        <div>{categoryMap[issue.category]}</div>
                      </div>
                    </div>
                  </div>
                ),
              };
            })}
          ></KanbanColumn>
          <KanbanColumn
            title={<KanbanColumnTitle title="Reddedildi" count={accountIssueRejected?.count} icon={<Badge color="red" />} />}
            isLoading={loadingRejected}
            cardsStyle={{ height: 'calc(100vh - 205px)' }}
            loadMore={
              <div>
                {rejectedPaginationQuery.limit! <= accountIssueRejected?.count! && (
                  <div className="flex justify-center items-center mt-auto">
                    <Button onClick={() => setRejectedPaginationQuery((prev) => ({ ...prev, limit: prev.limit! + 3 }))} type="link" icon={<SyncOutlined />}>
                      Daha fazla yükle
                    </Button>
                  </div>
                )}
              </div>
            }
            cards={accountIssueRejected?.data?.map((issue) => {
              return {
                title: <span className="font-normal opacity-45">#{issue.no}</span>,
                content: <TaskCardContent issue={issue} />,
                action: (
                  <ActionDropdown
                    data={issue}
                    onDelete={async ({ id }) => {
                      const result = await API.ACCOUNT_ISSUE.deleteIssue(id);
                      if (result.ok) {
                        notification.success({ message: 'Kredi Talebiniz Başarıyla Kaldırıldı' });
                      }
                      setRejectedPaginationQuery((prev) => ({ ...prev, page: 1 }));
                    }}
                    onEdit={() => {
                      setOpen(true);
                      setIsUpdateMode(true);
                      setSelectedData(issue);
                    }}
                    onView={async ({ id }) => {
                      const searchParams = new URLSearchParams(location.search);
                      searchParams.set('issue_id', id);
                      await navigate('/lms/issue-detail?' + searchParams.toString());
                    }}
                  />
                ),
                footer: (
                  <div>
                    <div>
                      <div>
                        <FileDoneOutlined /> {i18n.t('app.accountIssueType.' + issue.type)}
                      </div>
                      <div className="flex justify-between">
                        <div>
                          {issue.files?.length! > 0 && (
                            <>
                              <PaperClipOutlined /> {issue.files?.length}
                            </>
                          )}
                        </div>
                        <div>{categoryMap[issue.category]}</div>
                      </div>
                    </div>
                  </div>
                ),
              };
            })}
          ></KanbanColumn>
        </div>
      </div>

      <QuickReportIssue
        open={open}
        isUpdateMode={isUpdateMode}
        closeDrawer={() => {
          setOpen(false);
        }}
        onReflesh={() => onRenderIssues()}
        selectedData={selectedData}
      />
    </>
  );
};

AccountIssueKanban.path = '/lms/issue-kanban';
AccountIssueKanban.title = 'Ayarlar';
AccountIssueKanban.group = '';
AccountIssueKanban.roles = [Role.ADMIN];
AccountIssueKanban.icon = <SettingOutlined size={20} />;
