import { InfoCircleOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { LayoutEnums, ProductPermissionValues, Role } from '@shared/constants';
import { useAccessableSeachParams, useForm } from '@shared/hooks';
import { CascaderOption, ConditionCourse, EducationLevel } from '@shared/models';
import { Button, Cascader, Divider, Form, InputNumber, Tooltip, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

const BrandAndBranchUserFormZod = z.object({
  teacherRate: z.number({ required_error: 'Zorunlu alandır' }).max(100, '100den fazla olamaz'),
  exerciseRate: z.number({ required_error: 'Zorunlu alandır' }).max(100, '100den fazla olamaz'),
  homeworkRate: z.number({ required_error: 'Zorunlu alandır' }).max(100, '100den fazla olamaz'),
  attendanceRateThreshold: z.number({ required_error: 'Zorunlu alandır' }).max(100, '100den fazla olamaz'),
  failThreshold: z.number({ required_error: 'Zorunlu alandır' }).max(100, '100den fazla olamaz'),
  successThreshold: z.number({ required_error: 'Zorunlu alandır' }).max(100, '100den fazla olamaz'),
});
export const ConditionsCompletedCourseCreate = () => {
  useAccessableSeachParams();
  const [fields, fieldLoading, getFields] = API.EDUCATION_LEVEL.BRAND.useFetchField({ init: true });
  const [types, typeLoading, getTypes] = API.EDUCATION_LEVEL.BRAND.useFetchType({ init: true });
  const [themes, themesLoading, getTheme] = API.EDUCATION_LEVEL.BRAND.useFetchTheme({ init: true });
  const [levels, levelsLoading, getLevel] = API.EDUCATION_LEVEL.BRAND.useFetchLevel({ init: true, initParams: { active: true, limit: 1000 } });
  const [abilityLevelOptions, setAbilityLevelOptions] = useState<CascaderOption[]>([]);
  const [abilityLevelCascaderValue, setAbilityLevelCascaderValue] = useState<(string | number)[][]>([]);
  const [searchParams] = useSearchParams();
  const [isEditMod, setIsEditMod] = useState<boolean>(false);
  const [totalRate, setTotalRate] = useState<number>();

  const form = useForm<
    Partial<{
      levelId: string;
      teacherRate: number;
      exerciseRate: number;
      homeworkRate: number;
      attendanceRateThreshold: number;
      failThreshold: number;
      successThreshold: number;
      abilityLevels: EducationLevel[];
    }>,
    ConditionCourse
  >(BrandAndBranchUserFormZod, {});

  useEffect(() => {
    if (searchParams.size > 0) {
      const id = searchParams.get('levelId');
      if (id) {
        switchToUpdateMod(id);
      }
    }
  }, [searchParams]);

  const switchToUpdateMod = (id: string) => {
    setIsEditMod(true);
    getRetrive(id);
  };

  const onClear = () => {
    setIsEditMod(false);
    form.reset();
    setAbilityLevelCascaderValue([]);
  };

  const getRetrive = async (id: string) => {
    const response = await API.EDUCATION_LEVEL.BRAND.getLevelSuccessRequirement(id);
    form.setValue({ ...response.data, levelId: response.data.level.id! });
    const model: string[][] = [];

    const r: string[] = [response.data.level.theme?.type?.field?.id!];
    r.push(response.data.level.theme?.type?.id!);
    r.push(response.data.level.theme?.id!);
    r.push(response.data.level.id!);
    model.push(r);

    setAbilityLevelCascaderValue(model);
  };

  useEffect(() => {
    if (fields && types && themes && levels) {
      const _items: CascaderOption[] = [];
      fields.data?.map((f) => {
        _items.push({
          value: f.id!,
          label: f.name!,
          children: types.data
            ?.filter((t) => t.field?.id == f.id)
            .map((t) => {
              return {
                value: t.id!,
                label: t.name,
                children: themes
                  .data!.filter((m) => m.type?.id == t.id)
                  .map((m) => {
                    return {
                      value: m.id,
                      label: m.name,
                      children: levels?.data
                        ?.filter((l) => l.theme?.id == m.id)
                        .map((l) => {
                          return {
                            value: l.id,
                            label: l.name,
                          } as CascaderOption;
                        }),
                    };
                  }) as CascaderOption[],
              };
            }) as CascaderOption[],
        });
      });
      setAbilityLevelOptions(_items);
    }
  }, [fields, types, themes, levels]);

  const onChangeAbilityLevel = (value) => {
    setAbilityLevelCascaderValue(value);
    if (value && value.length > 3) {
      form.setValue({
        ...form.value,
        levelId: value[3],
      });
    } else {
      form.setValue({
        ...form.value,
        levelId: undefined,
      });
    }
  };

  const getTotalRate = (teacherRate, exerciseRate, homeworkRate) => {
    return teacherRate + exerciseRate + homeworkRate;
  };

  const onSubmit = async () => {
    const { teacherRate, exerciseRate, homeworkRate } = form.value;
    const totalRate = (teacherRate ?? 0) + (exerciseRate ?? 0) + (homeworkRate ?? 0);
    if (abilityLevelCascaderValue && abilityLevelCascaderValue.length == 0) {
      notification.error({
        message: 'Hata',
        description: 'Kur seçimi yapılmalıdır.',
      });
    } else if (totalRate !== 100) {
      notification.error({
        message: 'Hata',
        description: 'Öğretmen notu, sınav notu ve ödev notunun toplamı 100 olmalıdır.',
      });
    } else {
      const response = await API.EDUCATION_LEVEL.BRAND.upsertlevelSuccessRequirement(form.value);

      if (response.ok) {
        notification.success({ message: 'Kur geçme şartları oluşturuldu' });
        form.reset();
        setAbilityLevelCascaderValue([]);
        if (isEditMod) {
          const trimmedUrl = location.search.split('&levelId')[0];
          location.replace('/brand/conditions-completed-course' + trimmedUrl);
        }
      }
    }
  };

  return (
    <Form className=" gap-4 gap-y-2" layout="vertical">
      <div className="mx-7">
        <div className="opacity-45">Kur Geçme Şartları</div>
        <div className="mt-3">
          <Cascader
            className="w-72"
            placeholder={'Kur seçiniz'}
            options={abilityLevelOptions}
            value={abilityLevelCascaderValue}
            onChange={onChangeAbilityLevel}
            expandTrigger="hover"
          />
        </div>
        <div className="grid grid-cols-7 gap-10 mt-5">
          <div className="col-span-2">
            <Form.Item {...form.formItem('teacherRate')} label="Öğretmen notunun yüzdesi">
              <InputNumber {...form.inputNumber('teacherRate')} className="text-right w-full" addonBefore="%" min={0} max={100} />
            </Form.Item>
          </div>
          <div className="col-span-2">
            <Form.Item {...form.formItem('exerciseRate')} label="ART performans notunun yüzdesi">
              <InputNumber {...form.inputNumber('exerciseRate')} className="text-right w-full" addonBefore="%" min={0} max={100} />
            </Form.Item>
          </div>
          <div className="col-span-2">
            <Form.Item {...form.formItem('homeworkRate')} label="Ödev notu">
              <InputNumber {...form.inputNumber('homeworkRate')} className="text-right w-full" addonBefore="%" min={0} max={100} />
            </Form.Item>
          </div>
          <div className="flex items-center">
            <Tooltip placement="top" title="Bu alan öğrencinin sınıf sonu ortalama puanı hesaplanırken kullanılacak parametrelerin ağırlıklarını belirlemek için kullanılır.">
              <InfoCircleOutlined style={{ fontSize: '130%' }} />
            </Tooltip>
          </div>
        </div>

        {getTotalRate(form.value.teacherRate, form.value.exerciseRate, form.value.homeworkRate) !== 100 &&
          form.value.teacherRate !== undefined &&
          form.value.teacherRate !== 0 &&
          form.value.teacherRate !== null &&
          form.value.exerciseRate !== undefined &&
          form.value.exerciseRate !== 0 &&
          form.value.exerciseRate !== null &&
          form.value.homeworkRate !== undefined &&
          form.value.homeworkRate !== 0 &&
          form.value.homeworkRate !== null && (
            <div className="mt-2 text-sm" style={{ color: '#FF4D4F' }}>
              Girilen değerlerin toplamı 100 olmalıdır. Şu anki toplam: {getTotalRate(form.value.teacherRate, form.value.exerciseRate, form.value.homeworkRate)}
            </div>
          )}
        <Divider className="m-2"></Divider>
        <div className="grid grid-cols-7 gap-10 mt-10">
          <div className="col-span-2">
            <Form.Item {...form.formItem('attendanceRateThreshold')} label="Devamsızlık yüzdesi">
              <InputNumber defaultValue={0} {...form.inputNumber('attendanceRateThreshold')} className="text-right w-full" addonBefore="%" min={0} max={100} />
            </Form.Item>
          </div>
          <div className="flex items-center">
            <Tooltip placement="top" title="Bu alan öğrencinin devam zorunluluğunu belirler.">
              <InfoCircleOutlined style={{ fontSize: '130%' }} />
            </Tooltip>
          </div>
        </div>
        {form.value.attendanceRateThreshold !== undefined && form.value.attendanceRateThreshold !== 0 && form.value.attendanceRateThreshold !== null && (
          <div className="col-span-2 text-sm" style={{ color: '#1677FF' }}>
            Öğrenci 100 yoklamadan en az {form.value.attendanceRateThreshold} tanesine katılmaz ise <b>başarısız</b> olarak işaretlenir.
          </div>
        )}
        <Divider className="m-2"></Divider>
        <div className="grid grid-cols-7 gap-10 mt-10">
          <div className="col-span-2">
            <Form.Item {...form.formItem('failThreshold')} label="Kalma şartı">
              <InputNumber {...form.inputNumber('failThreshold')} className="text-right w-full" addonBefore="%" min={0} max={100} />
            </Form.Item>
          </div>
          <div className="col-span-2">
            <Form.Item {...form.formItem('successThreshold')} label="Geçme şartı">
              <InputNumber {...form.inputNumber('successThreshold')} className="text-right w-full" addonBefore="%" min={0} max={100} />
            </Form.Item>
          </div>
          <div className="flex items-center">
            <Tooltip placement="top" title="Bu alan öğrencinin ortalama puanının; geçme, kalma ya da şartlı geçme sınırlarını belirler.">
              <InfoCircleOutlined style={{ fontSize: '130%' }} />
            </Tooltip>
          </div>
        </div>
        <div>
          {form.value.failThreshold !== undefined &&
            form.value.failThreshold !== 0 &&
            form.value.failThreshold !== null &&
            form.value.successThreshold !== undefined &&
            form.value.successThreshold !== 0 &&
            form.value.successThreshold !== null && (
              <div className="col-span-2 text-sm" style={{ color: '#1677FF' }}>
                <div>
                  Öğrencinin ortalama puanı {form.value.failThreshold}’den düşük ise öğrenci <b> başarısız </b>kabul edilir.
                </div>
                <div>
                  Öğrencinin ortalama puanı {form.value.successThreshold} ve daha yüksek ise öğrenci <b>başarılı</b> kabul edilir.
                </div>
                <div>
                  Öğrencinin ortalama puanı {form.value.failThreshold} ile {form.value.successThreshold} arasında ise <b>şartlı geçme</b> durumunda kabul edilir
                </div>
              </div>
            )}
        </div>
        <div>
          <Button className="mr-3 mt-5" type="primary" onClick={onSubmit}>
            {!isEditMod ? <span> Oluştur</span> : <span>Güncelle</span>}
          </Button>
          <Button type="default" onClick={onClear}>
            Sıfırla
          </Button>
        </div>
      </div>
    </Form>
  );
};

ConditionsCompletedCourseCreate.path = 'brand/conditions-completed-course-create';
ConditionsCompletedCourseCreate.roles = [Role.USER];
ConditionsCompletedCourseCreate.layouts = [LayoutEnums.BRANDMANAGER];
ConditionsCompletedCourseCreate.permissions = [ProductPermissionValues.brand.LMS.Definition.CREATE];
