import { AuditOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { LayoutEnums, ProductPermissionValues, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { BranchLmsCreditBalance, CreditBalanceType, PaginationQuery } from '@shared/models';
import { convertDate, sortFields } from '@shared/utils';
import { Button, Input, InputRef, Table, TableColumnType, Tag } from 'antd';
import { TableProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useEffect, useRef, useState } from 'react';
import { TbFilter } from 'react-icons/tb';
import i18n from '@shared/localization/i18n';

export const BranchBalance = () => {
  useAccessableSeachParams();
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 10, withDeleted: false, orders: ['-createdAt'] });
  const searchInput = useRef<InputRef>(null);
  const [branchCreditBalances, loading, getBranchCreditBalances] = API.LMS_CREDIT.paginateBranchLmsCreditBalance({ init: false });
  const [currentBalance, isCurrentBalanceLoading, getCurrentBalance] = API.LMS_CREDIT.retriveCurrentBranchLmsCreditBalance({ init: true });

  useEffect(() => {
    getBranchCreditBalances(undefined, paginationQuery);
  }, [paginationQuery]);

  const columns: TableProps<BranchLmsCreditBalance>['columns'] = [
    {
      title: 'Tür',
      dataIndex: 'type',
      align: 'center',
      sorter: { multiple: 1 },
      filterMultiple: false,
      filterIcon: <TbFilter size="15" />,
      filters: Object.values(CreditBalanceType).map((type) => ({ text: i18n.t('app.creditBalanceType.' + type), value: type })),
      render: (_, { type }) => (type == CreditBalanceType.CREDIT ? <Tag color="blue">Giriş</Tag> : <Tag color="red">Çıkış</Tag>),
    },
    {
      title: 'Kredi Sayısı',
      dataIndex: 'count',
      align: 'center',
      sorter: { multiple: 3 },
    },

    {
      title: 'İşlem Tarihi',
      key: 'createdAt',
      align: 'center',
      dataIndex: 'createdAt',
      sorter: { multiple: 2 },
      defaultSortOrder: 'descend',
      render: (_, { createdAt }) => convertDate(createdAt, 'DD.MM.YYYY HH:mm'),
    },

    {
      title: 'Açıklama',
      key: 'description',
      render: (_, { description }) => description,
    },

    {
      title: 'İşlemi Yapan Kişi',
      key: 'proceedBy',
      align: 'center',
      render: (_, { proceedBy }) => (proceedBy ? `${proceedBy.name}` : ''),
    },
  ];

  return (
    <div>
      <h3>Mevcut Bakiyeniz: {currentBalance}</h3>
      <Table
        loading={loading}
        rowKey="id"
        scroll={{ x: 'max-content' }}
        pagination={{
          showSizeChanger: true,
          showPrevNextJumpers: true,
          current: paginationQuery.page,
          total: branchCreditBalances?.count,
          pageSize: paginationQuery.limit,
          position: ['bottomCenter'],
        }}
        columns={columns}
        dataSource={branchCreditBalances?.data}
        bordered
        onChange={(pagination, filters, sorter) => {
          const { current, pageSize } = pagination;
          let newQuery = {
            ...paginationQuery,
            limit: pageSize,
            page: current,
            orders: sortFields(paginationQuery, sorter as SorterResult<any>),
            ...filters,
          };
          Object.keys(newQuery).forEach((key) => {
            if (key === 'orders') {
              return;
            }
            if (Array.isArray(newQuery[key]) && newQuery[key].length === 1) {
              newQuery[key] = newQuery[key][0];
            }
          });

          setPaginationQuery(newQuery);
        }}
      />
    </div>
  );
};

BranchBalance.path = '/lms/branch-credit-balance';
BranchBalance.title = 'Kredi Bakiye Hareketleri';
BranchBalance.group = 'branch.lms.credit';
BranchBalance.roles = [Role.ADMIN];
BranchBalance.layouts = [LayoutEnums.BRANCHMANAGER, LayoutEnums.LMS];
BranchBalance.permissions = [ProductPermissionValues.branch.LMS.Credit.VIEW, ProductPermissionValues.branch.LMS.Credit.MANAGE];
