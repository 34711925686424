import { NationalIdInput, PhoneInput } from "@shared/components";
import { useForm } from "@shared/hooks";
import { Enrollment } from "@shared/models";
import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { z } from "zod";


const CreateEnterpriseEnrollmentFormZod = z.object({
    name: z.string({ required_error: 'Adı zorunlu alandır' }).min(1, 'Adı 1 karekterden fazla olmalı'),
    email: z.string({ required_error: 'E-posta zorunlu alandır' }).min(1, 'TODO Bu değişecek ama şimdilik en az 1 karakter').email('Geçersiz e-mail adresi'),
    'phone.number': z
        .string({ required_error: 'Telefon numarası zorunlu alandır' })
        .min(10, 'Lütfen telefon numarası giriniz')
        .refine((p) => {
            //TODO phone validasyon için kullanabilir
            return true;
        }),
    nationalId: z.string({ required_error: 'TC/VKN zorunlu alandır' }).min(1),
});

type CreateEnterpriseEnrollmentFormProps = {
    enrollment?: Enrollment;
    onSave: (enrollment: Enrollment) => Promise<boolean>;
    onUpdate: (enrollment: Enrollment) => Promise<boolean>;
};

export const CreateEnterpriseEnrollmentForm = ({ enrollment, onSave, onUpdate }: CreateEnterpriseEnrollmentFormProps) => {
    const form = useForm<
        Partial<{
            id: string;
            name: string;
            email: string;
            nationalId: string;
            'phone.number': string;
            'phone.code': string;
        }>,
        Enrollment
    >(CreateEnterpriseEnrollmentFormZod, {});

    const [isEditMod, setIsEditMod] = useState<boolean>(false);

    useEffect(() => {
        if (enrollment?.id) {
            setIsEditMod(true);
            form.setInitialValue({ ...enrollment });
        } else {
            setIsEditMod(false);
            form.setInitialValue({});
        }
    }, [enrollment?.id]);

    const onSaveEnrollment = async () => {
        const result = form.parse();
        if (result.success) {
            const newEnrollment = result.data as Enrollment;
            if (newEnrollment.id) {
                await onUpdate(newEnrollment);
            } else {
                await onSave(newEnrollment);
            }
        } else {
            form.setAllTouched();
        }

    }

    return (
        <div className="flex flex-col w-full">
            <Form layout="vertical">
                <Form.Item {...form.formItem('name')} label={<span className="truncate">Şirket Adı</span>}>
                    <Input {...form.input('name')} />
                </Form.Item>
                <Form.Item {...form.formItem('email')} label={<span className="truncate">Şirket Email</span>}>
                    <Input {...form.input('email')} />
                </Form.Item>
                <PhoneInput form={form} label="Şirket Telefonu" />
                <NationalIdInput form={form} />
            </Form>

            <div className='flex flex-col space-y-2'>
                <Button type='primary' onClick={() => {
                    onSaveEnrollment();
                }}>{isEditMod ? "Kaydı Güncelle" : "Kaydı Oluştur"}</Button>
            </div>
        </div>
    );
}