import { ReviewLearningContentVersion } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as API from '@shared/api';
import { notification } from 'antd';
import { ContentVersionNavigation } from '@shared/models';
import { CdnScope } from '@shared/components/curriculum/FileUpload';

export const BrandReviewLearningContentVersion = () => {
  useAccessableSeachParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [contentNavigation, setContentNavigation] = useState<ContentVersionNavigation>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [versionId, setVersionId] = useState<string>();


  useEffect(() => {
    if (searchParams && searchParams.size > 0) {
      const versionId = searchParams.get('versionId');
      if (versionId) {
        getFirstPage(versionId);
      }
    }
  }, [searchParams]);

  const getFirstPage = async (versionId: string) => {
    setVersionId(versionId);
    try {
      const result = await API.CURRICULUM.BRAND_COURSE_VERSION.startFirstPage(versionId);
      const navigationData = result.data;
      setContentNavigation(navigationData);
    } catch (error) {
      notification.error({
        message: 'Hata',
        description: 'Hata olustu. Lütfen tekrar deneyiniz.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onPrevPage = async () => {
    if (versionId && contentNavigation?.meta.hasPrev) {
      const result = await API.CURRICULUM.BRAND_COURSE_VERSION.prevPage(versionId, contentNavigation?.meta.pageId!);
      setContentNavigation(result.data);
    }
  };

  const onNextPage = async () => {
    if (versionId) {
      try {
        const result = await API.CURRICULUM.BRAND_COURSE_VERSION.nextPage(versionId, contentNavigation?.meta.pageId!);
        if (result.ok) {
          setContentNavigation(result.data);
        }
      } catch (error) {
        notification.warning({
          message: 'Bilgilendirme',
          description: 'Sonuncu sayfaya ulaştınız.',
        });
      }
    }
  };

  const onGoToPage = async (pageId: string) => {
    if (versionId) {
      const result = await API.CURRICULUM.BRAND_COURSE_VERSION.goToPage(versionId, pageId);
      setContentNavigation(result.data);
    }
  };

  const onRejectPage = async () => {
    if (versionId) {
      try {
        await API.CURRICULUM.COURSE_PAGE.brandReject(versionId, contentNavigation?.meta.unit.id!, contentNavigation?.meta.topic.id!, contentNavigation?.meta.pageId!, {
          reviewedNote: 'Olmamış babus!',
        });
        await onGoToPage(contentNavigation?.meta.pageId!);
      } catch (error) {
        notification.error({
          message: 'Hata',
          description: 'Hata olustu. Lütfen tekrar deneyiniz.',
        });
      }
    }
  };

  const onApprovePageAndNext = async () => {
    if (versionId) {
      try {
        await API.CURRICULUM.COURSE_PAGE.brandApprove(versionId, contentNavigation?.meta.unit.id!, contentNavigation?.meta.topic.id!, contentNavigation?.meta.pageId!);
        await onGoToPage(contentNavigation?.meta.pageId!);
        onNextPage();
      } catch (error) {
        notification.error({
          message: 'Hata',
          description: 'Hata olustu. Lütfen tekrar deneyiniz.',
        });
      }
    }
  };

  const onPublish = async () => {
    if (versionId) {
      try {
        await API.CURRICULUM.BRAND_COURSE_VERSION.publish(versionId);
        goBack();
      } catch (error) {
        notification.error({
          message: 'Hata',
          description: 'Hata olustu. Lütfen tekrar deneyiniz.',
        });
      }
    }
  };


  const goBack = () => {
    const layout = searchParams.get("layout");
    const brandId = searchParams.get('brandId');
    const branchId = searchParams.get('branchId');
    const params = new URLSearchParams({
      layout: layout!,
    });
    if (brandId) {
      params.append('brandId', brandId);
    }
    if (branchId) {
      params.append('branchId', branchId);
    }
    navigate('/brand-learning-content-versions/?' + params);
  }

  return (
    <ReviewLearningContentVersion
      loading={isLoading}
      onApprovePageAndNext={onApprovePageAndNext}
      onGoToPage={onGoToPage}
      onNextPage={onNextPage}
      onPrevPage={onPrevPage}
      onPublish={onPublish}
      onRejectPage={onRejectPage}
      contentNavigation={contentNavigation}
      onBack={goBack}
      cdnScope={CdnScope.BRAND}
    />
  );
};

BrandReviewLearningContentVersion.path = 'brand-review-learning-content-version';
BrandReviewLearningContentVersion.roles = [Role.ADMIN];
BrandReviewLearningContentVersion.layouts = [LayoutEnums.BRANDMANAGER, LayoutEnums.LMS];
