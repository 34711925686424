import * as API from '@shared/api';
import { AvailabilityWeekCalendar, BirthdayCalendar, EmailSearch, MultipleCascader, NationalIdInput, PhoneInput, Spinner, TextInput } from '@shared/components';
import { AgreementType, Gender, LayoutEnums, ProductPermissionValues, Role } from '@shared/constants';
import { useAccessableSeachParams, useForm } from '@shared/hooks';
import { AvailabilityCalendar, CascaderOption, EducationLevel, TeacherModel } from '@shared/models';
import { Button, Form, notification, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

const CreateTeacherForm = z.object({
  name: z.string({ required_error: 'Öğretmen adı zorunlu alandır' }).min(1, 'Öğretmen adı 1 karekterden fazla olmalı'),
  surname: z.string({ required_error: 'Öğretmen soyadı zorunlu alandır' }).min(1, 'Öğretmen soyadı 1 karekterden fazla olmalı'),
  email: z.string({ required_error: 'Email zorunlu alandır' }).min(1, 'TODO Bu değişecek ama şimdilik en az 1 karakter').email('Geçersiz e-mail adresi'),
  abilityLevels: z.array(z.object({ id: z.string(), name: z.string() }), { required_error: 'Lütfen en az bir eğitim türü seçiniz' }),
  'phone.number': z
    .string({ required_error: 'Telefon numarası zorunlu alandır' })
    .min(10, 'Lütfen telefon numarası giriniz')
    .refine((p) => {
      //TODO phone validasyon için kullanabilir
      return true;
    }),
  gender: z.string({ required_error: 'Lütfen cinsiyet seçimi yapınız' }),
  birthDate: z.string({ required_error: 'Lütfen doğum tarihi seçiniz' }),
  agreementType: z.string({ required_error: 'Lütfen çalışma tipi seçiniz' }),
  nationalId: z.string({ required_error: 'Lütfen tc no alanını doldurunuz' }),
});

export const CreateTeacher = () => {
  useAccessableSeachParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const form = useForm<
    Partial<{
      name: string;
      surname: string;
      email: string;
      abilityLevels: EducationLevel[];
      'phone.number': string;
      'phone.code': string;
      gender: string;
      birthDate: string;
      agreementType: AgreementType;
      nationalId: string;
    }>,
    TeacherModel
  >(CreateTeacherForm, {});

  const [fields, fieldLoading, getFields] = API.EDUCATION_LEVEL.BRANCH.useFetchField({ init: true });
  const [types, typeLoading, getTypes] = API.EDUCATION_LEVEL.BRANCH.useFetchType({ init: true });
  const [themes, themesLoading, getTheme] = API.EDUCATION_LEVEL.BRANCH.useFetchTheme({ init: true });
  const [levels, levelsLoading, getLevel] = API.EDUCATION_LEVEL.BRANCH.useFetchLevel({ init: true, initParams: { active: true, limit: 1000 } });
  const [abilityLevelOptions, setAbilityLevelOptions] = useState<CascaderOption[]>([]);
  const [abilityLevelCascaderValue, setAbilityLevelCascaderValue] = useState<(string | number)[][]>([]);
  const [availabilityCalendar, setAvailabilityCalendar] = useState<AvailabilityCalendar>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditMod, setIsEditMod] = useState<boolean>(false);
  const [isEmailSearch, setIsEmailSearch] = useState(false);
  const [isEmailSearchFound, setIsEmailSearchFound] = useState(false);

  useEffect(() => {
    if (searchParams.size > 0) {
      const id = searchParams.get('teacher');
      if (id) {
        switchToUpdateMod(id);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (Object.keys(form.value).length > 0 && abilityLevelCascaderValue.length > 0) {
      setIsLoading(false);
    }
  }, [form.value, abilityLevelCascaderValue]);

  const switchToUpdateMod = (id: string) => {
    setIsLoading(true);
    setIsEditMod(true);
    getTeacherById(id);
    getAvailabilityCalendarById(id);
  };

  const getTeacherById = async (id: string) => {
    try {
      const teacherResult = await API.TEACHER.getById(id);
      const teacher = teacherResult.data as TeacherModel;
      form.setInitialValue(teacher);
      const model: string[][] = [];
      teacher.abilityLevels?.forEach((l) => {
        const r: string[] = [l.theme?.type?.field?.id!];
        r.push(l.theme?.type?.id!);
        r.push(l.theme?.id!);
        r.push(l.id!);
        model.push(r);
      });
      setAbilityLevelCascaderValue(model);
      setIsEmailSearch(true);
      setIsEmailSearchFound(true);
    } catch (error) {}
  };

  const getAvailabilityCalendarById = async (id: string) => {
    try {
      const availability = await API.TEACHER.getAvailableCalendar(id);
      setAvailabilityCalendar(availability.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (fields && types && themes && levels) {
      const filterItems = (items: CascaderOption[]): CascaderOption[] => {
        return items.reduce((acc: CascaderOption[], item) => {
          if (item.children && item.children.length > 0) {
            const filteredChildren = filterItems(item.children);
            if (filteredChildren.length > 0) {
              acc.push({ ...item, children: filteredChildren });
            }
          } else if (item.active == true) {
            acc.push(item);
          }
          return acc;
        }, []);
      };

      const _items: CascaderOption[] = fields.data?.map((f) => ({
        value: f.id!,
        label: f.name!,
        children: types.data
          ?.filter((t) => t.field?.id == f.id)
          .map((t) => ({
            value: t.id!,
            label: t.name,
            children: themes.data
              ?.filter((m) => m.type?.id == t.id)
              .map((m) => ({
                value: m.id,
                label: m.name,
                children: levels.data
                  ?.filter((l) => l.theme?.id == m.id)
                  .map((l) => ({
                    value: l.id,
                    label: l.name,
                    active: l.active,
                  })) as CascaderOption[],
              })) as CascaderOption[],
          })) as CascaderOption[],
      })) as CascaderOption[];

      const filteredItems = filterItems(_items);
      setAbilityLevelOptions(filteredItems);
    }
  }, [fields, types, themes, levels]);

  const onChangeAbilityLevel = (value) => {
    setAbilityLevelCascaderValue(value);
    let selectedLevels: EducationLevel[] = [];
    value.forEach((v) => {
      if (v.length == 1) {
        const _selectedLevels = levels?.data?.filter((level) => level.theme?.type?.field?.id == v[0]);
        selectedLevels = [...selectedLevels, ..._selectedLevels!];
      }
      if (v.length == 2) {
        const _selectedLevels = levels?.data?.filter((level) => level.theme?.type?.id == v[1]);
        selectedLevels = [...selectedLevels, ..._selectedLevels!];
      }
      if (v.length == 3) {
        const _selectedLevels = levels?.data?.filter((level) => level.theme?.id == v[2]);
        selectedLevels = [...selectedLevels, ..._selectedLevels!];
      }
      if (v.length == 4) {
        const _selectedLevels = levels?.data?.filter((level) => level?.id == v[3]);
        selectedLevels = [...selectedLevels, ..._selectedLevels!];
      }
      form.setValue({
        ...form.value,
        abilityLevels: selectedLevels,
      });
    });
  };

  const saveTeacher = async () => {
    const result = form.parse();
    if (result.success) {
      if (Object.keys(availabilityCalendar).length > 0) {
        try {
          const { data } = await API.TEACHER.create({
            ...result.data,
          });
          await API.TEACHER.updateAvailableCalendar({ id: data.id, availabilityCalendar: availabilityCalendar });
          form.reset();
          setAbilityLevelCascaderValue([]);
          setAvailabilityCalendar({});
          notification.success({ message: 'Öğretmen oluşturuldu' });
          navigate('/lms/teachers' + location.search);
        } catch (error) {}
      } else {
        notification.warning({ message: 'Lütfen öğretmenin müsaitlik takvimini oluşturunuz' });
      }
    } else {
      form.setAllTouched();
    }
  };

  const updateTeacher = async () => {
    const result = form.parse();
    if (result.success) {
      if (Object.keys(availabilityCalendar).length > 0) {
        await API.TEACHER.update(result.data);
        await API.TEACHER.updateAvailableCalendar({ id: result.data.id, availabilityCalendar: availabilityCalendar });
        form.reset();
        switchToUpdateMod(result.data.id!);
        notification.success({ message: 'Öğretmen güncellendi' });
      } else {
        notification.warning({ message: 'Lütfen öğretmenin müsaitlik takvimini oluştuırunuz' });
      }
    } else {
      form.setAllTouched();
    }
  };

  const onSearchResult = (data?: TeacherModel) => {
    if (data) {
      form.setInitialValue({
        email: data.email,
        name: data.name,
        surname: data.surname,
        phone: data.phone,
        gender: data.gender,
        nationalId: data.nationalId,
        birthDate: data.birthDate,
      });
      setIsEmailSearchFound(true);
    } else {
      setIsEmailSearchFound(false);
      form.setInitialValue({
        ...form.value,
        name: undefined,
        surname: undefined,
        phone: {
          code: undefined,
          number: undefined,
        },
        gender: undefined,
        nationalId: undefined,
        birthDate: undefined,
      });
    }
    setIsEmailSearch(true);
  };

  return (
    <>
      <Spinner message="Öğretmen bilgileri getiriliyor" loading={isLoading} />
      <div className="grid grid-cols-3 gap-x-2">
        <Form layout="vertical">
          <EmailSearch
            disabled={isEmailSearchFound}
            form={form}
            isSearchOtherUser={!isEditMod}
            onSearchResult={onSearchResult}
            onClear={() => {
              form.setInitialValue({
                email: undefined,
                name: undefined,
                surname: undefined,
                phone: {
                  code: undefined,
                  number: undefined,
                },
                gender: undefined,
                nationalId: undefined,
                birthDate: undefined,
                agreementType: form.value.agreementType,
              });
              setIsEmailSearchFound(false);
              setIsEmailSearch(false);
            }}
          />

          <div className="grid grid-cols-2 gap-x-4">
            <TextInput formItem={form.formItem('name')} formInput={form.input('name')} label={'Ad:'} disabled={!isEmailSearch || isEmailSearchFound} />
            <TextInput formItem={form.formItem('surname')} formInput={form.input('surname')} label={'Soyad:'} disabled={!isEmailSearch || isEmailSearchFound} />
          </div>
          <PhoneInput form={form} disabled={!isEmailSearch || isEmailSearchFound} />

          <div className="grid grid-cols-2 gap-x-4">
            <Form.Item {...form.formItem('gender')} label={'Cinsiyet:'}>
              <Radio.Group {...form.input('gender')} disabled={!isEmailSearch || isEmailSearchFound}>
                <Radio value={Gender.FEMALE}>Kadın</Radio>
                <Radio value={Gender.MALE}>Erkek</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item {...form.formItem('birthDate')} label={'Doğum tarihi:'}>
              <BirthdayCalendar form={form} disabled={!isEmailSearch || isEmailSearchFound} />
            </Form.Item>
          </div>

          <NationalIdInput form={form} label={'TC No:'} disabled={!isEmailSearch || isEmailSearchFound} />

          <Form.Item {...form.formItem('abilityLevels')} label="Eğitim türü ve seviye:">
            <MultipleCascader items={abilityLevelOptions} value={abilityLevelCascaderValue} onChange={onChangeAbilityLevel} isShowChild={true} />
          </Form.Item>

          <Form.Item {...form.formItem('agreementType')} label="Çalışma tipi:">
            <Radio.Group {...form.input('agreementType')}>
              <Radio value={AgreementType.FULL_TIME}>Tam Zamanlı</Radio>
              <Radio value={AgreementType.PART_TIME}>Yarı Zamanlı</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item className="mt-5">
            <Button type="primary" onClick={isEditMod ? updateTeacher : saveTeacher}>
              {isEditMod ? ' Kayıt Güncelle' : ' Kayıt Oluştur'}
            </Button>
          </Form.Item>
        </Form>
        <div className="col-span-2">
          <label className="text-black/45 font-normal">Öğretmenin Müsaitlik Takvimi</label>
          <AvailabilityWeekCalendar
            value={availabilityCalendar}
            onChange={(availability) => {
              setAvailabilityCalendar(availability);
            }}
          />
        </div>
      </div>
    </>
  );
};

CreateTeacher.path = '/lms/create-teacher';
CreateTeacher.title = 'Yeni Öğretmen Tanımla';
CreateTeacher.group = 'teacher';
CreateTeacher.roles = [Role.ADMIN];
CreateTeacher.layouts = [LayoutEnums.BRANCHMANAGER, LayoutEnums.LMS];
CreateTeacher.permissions = [ProductPermissionValues.branch.LMS.Teacher.CREATE];
